import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Check, ChevronLeft, ArrowRight, CreditCard, Shield, Zap } from 'lucide-react';

const PricingPage = () => {
  const [billingCycle, setBillingCycle] = useState('monthly');
  const navigate = useNavigate();

  const handleSubscribe = (planType) => {
    const subscriptionType = `${planType}_${billingCycle}`;
    navigate(`/dashboard/cart/checkout/subscription?subscription_type=${subscriptionType}`);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-gray-50" style={{background: "linear-gradient(to bottom, white, #f9fafb, #f3f4f6, #f9fafb)"}}>
      {/* Ultra-sleek navbar */}
      <header className="bg-white sticky top-0 z-10 border-b border-gray-100" style={{boxShadow: "0 2px 10px rgba(0,0,0,0.02)"}}>
        <div className="max-w-6xl mx-auto px-6">
          <div className="h-16 flex items-center justify-between">
            <button 
              onClick={() => navigate('/dashboard')}
              className="text-sm text-gray-500 hover:text-[#0D4029] transition-colors flex items-center gap-1.5 group"
            >
              <ChevronLeft className="w-4 h-4 group-hover:-translate-x-0.5 transition-transform" />
              <span>Dashboard</span>
            </button>
            <div className="text-[#0D4029] font-semibold text-lg">Clavaa</div>
            <div className="w-20"></div> {/* Spacer for balance */}
          </div>
        </div>
      </header>

      <main>
        {/* Hero section with gradient accent */}
        <div className="pt-12 pb-24 bg-gradient-to-b from-[#0D4029]/5 to-transparent" style={{background: "linear-gradient(135deg, rgba(13,64,41,0.03) 0%, rgba(62,180,137,0.05) 100%)"}}>
          <div className="max-w-3xl mx-auto text-center px-6">
            <h1 className="text-3xl font-bold text-gray-900 mb-3">Choose your Clavaa loyalty platform plan</h1>
            <p className="text-gray-600">The loyalty app that gives your business Starbucks-like superpowers</p>
          </div>
          
          {/* Floating toggle with animations */}
          <div className="max-w-sm mx-auto mt-10 bg-white rounded-full shadow-md border border-gray-200 p-1.5 flex relative">
            <button
              onClick={() => setBillingCycle('monthly')}
              className="relative z-10 flex-1 text-sm py-2 rounded-full text-center transition-colors duration-200"
              style={{ color: billingCycle === 'monthly' ? 'white' : '#6B7280' }}
            >
              Monthly
            </button>
            <button
              onClick={() => setBillingCycle('yearly')}
              className="relative z-10 flex-1 text-sm py-2 rounded-full text-center transition-colors duration-200 flex items-center justify-center"
              style={{ color: billingCycle === 'yearly' ? 'white' : '#6B7280' }}
            >
              <span>Annual</span>
              <span className="ml-1.5 bg-[#3EB489] text-white text-xs px-2 py-0.5 rounded-full">
                25% off
              </span>
            </button>
            <div 
              className="absolute top-1.5 bottom-1.5 w-1/2 rounded-full bg-[#0D4029] transition-all duration-200"
              style={{ left: billingCycle === 'monthly' ? '0.375rem' : '50%', transform: billingCycle === 'yearly' ? 'translateX(-0.375rem)' : 'none' }}
            ></div>
          </div>
        </div>

        {/* Modern pricing cards in container */}
        <div className="max-w-6xl mx-auto px-6 -mt-16">
          <div className="grid md:grid-cols-2 gap-6">
            {/* Starter Plan */}
            <div className="bg-white rounded-2xl overflow-hidden shadow-lg transition-all hover:shadow-xl border border-gray-200 relative flex flex-col h-full transform hover:-translate-y-1 duration-200" style={{borderTop: "4px solid #3EB489"}}>
              <div className="absolute top-0 right-0 w-24 h-24 overflow-hidden">
                <div className="absolute top-0 right-0 transform translate-y-12 -translate-x-12 rotate-45 w-28 h-28 bg-gradient-to-r from-[#3EB489]/20 to-[#3EB489]/30 -z-10"></div>
              </div>
              
              <div className="p-8">
                <h3 className="text-xl font-bold text-gray-900 mb-1">Starter</h3>
                <p className="text-gray-500 text-sm">Essential loyalty platform for your growing business</p>
                
                <div className="mt-6 flex items-baseline">
                  <span className="text-5xl font-bold text-gray-900">
                    ${billingCycle === 'monthly' ? '19' : '179'}
                  </span>
                  <span className="text-gray-500 ml-2 text-lg">
                    /{billingCycle === 'monthly' ? 'mo' : 'yr'}
                  </span>
                </div>
                
                <div className="mt-3 flex items-center">
                  <span className="text-sm text-gray-500 flex items-center">
                    <CreditCard className="w-4 h-4 mr-2 text-[#3EB489]" />
                    0.49% payment fee
                  </span>
                  <span className="ml-2 bg-[#3EB489]/10 text-[#3EB489] text-xs font-medium px-2 py-0.5 rounded-full">
                    Save 83%
                  </span>
                </div>
                
                {billingCycle === 'yearly' && (
                  <div className="mt-2 text-[#3EB489] text-sm font-medium">
                    Save $60 with annual billing
                  </div>
                )}
              </div>
              
              <div className="p-8 pt-4 border-t border-gray-100 flex-grow">
                <ul className="space-y-4">
                  <li className="flex gap-3">
                    <Check className="w-5 h-5 text-[#3EB489] flex-shrink-0" />
                    <span className="text-gray-700 text-sm">Full Clavaa loyalty platform with customer profiles</span>
                  </li>
                  <li className="flex gap-3">
                    <Check className="w-5 h-5 text-[#3EB489] flex-shrink-0" />
                    <span className="text-gray-700 text-sm">In-app payments with just 0.49% fee (vs typical 2.9%)</span>
                  </li>
                  <li className="flex gap-3">
                    <Check className="w-5 h-5 text-[#3EB489] flex-shrink-0" />
                    <span className="text-gray-700 text-sm">Digital loyalty cards and point tracking</span>
                  </li>
                  <li className="flex gap-3">
                    <Check className="w-5 h-5 text-[#3EB489] flex-shrink-0" />
                    <span className="text-gray-700 text-sm">Digital gift card management</span>
                  </li>
                  <li className="flex gap-3">
                    <Check className="w-5 h-5 text-[#3EB489] flex-shrink-0" />
                    <span className="text-gray-700 text-sm">Basic customer analytics</span>
                  </li>
                </ul>
              </div>
              
              <div className="p-8 pt-4">
                <button
                  onClick={() => handleSubscribe('basic')}
                  className="w-full bg-white border-2 border-[#0D4029] text-[#0D4029] hover:bg-[#0D4029]/5 font-medium py-3 rounded-lg transition-colors flex items-center justify-center"
                >
                  <span>Get started</span>
                  <ArrowRight className="w-4 h-4 ml-2" />
                </button>
              </div>
            </div>

            {/* Growth Plan */}
            <div className="bg-white rounded-2xl overflow-hidden shadow-lg transition-all hover:shadow-xl border-2 border-[#0D4029] relative flex flex-col h-full transform hover:-translate-y-1 duration-200" style={{background: "linear-gradient(to bottom, white, white, rgba(13,64,41,0.01))"}}>
              <div className="absolute top-0 right-0">
                <div className="bg-[#3EB489] text-white text-xs px-4 py-1.5 font-bold rounded-bl-lg shadow-sm">
                  MOST POPULAR
                </div>
              </div>
              
              <div className="p-8">
                <h3 className="text-xl font-bold text-gray-900 mb-1">Growth</h3>
                <p className="text-gray-500 text-sm">Advanced loyalty features to maximize customer retention</p>
                
                <div className="mt-6 flex items-baseline">
                  <span className="text-5xl font-bold text-gray-900">
                    ${billingCycle === 'monthly' ? '34' : '299'}
                  </span>
                  <span className="text-gray-500 ml-2 text-lg">
                    /{billingCycle === 'monthly' ? 'mo' : 'yr'}
                  </span>
                </div>
                
                <div className="mt-3 flex items-center">
                  <span className="text-sm text-gray-500 flex items-center">
                    <CreditCard className="w-4 h-4 mr-2 text-[#3EB489]" />
                    0% payment fee
                  </span>
                  <span className="ml-2 bg-[#3EB489]/10 text-[#3EB489] text-xs font-medium px-2 py-0.5 rounded-full">
                    Save 100%
                  </span>
                </div>
                
                {billingCycle === 'yearly' && (
                  <div className="mt-2 text-[#3EB489] text-sm font-medium">
                    Save $120 with annual billing
                  </div>
                )}
              </div>
              
              <div className="p-8 pt-4 border-t border-gray-100 flex-grow">
                <ul className="space-y-4">
                  <li className="flex gap-3">
                    <Check className="w-5 h-5 text-[#3EB489] flex-shrink-0" />
                    <span className="text-gray-700 text-sm">Full Clavaa loyalty platform with customer profiles</span>
                  </li>
                  <li className="flex gap-3">
                    <Check className="w-5 h-5 text-[#3EB489] flex-shrink-0" />
                    <span className="text-gray-700 text-sm font-medium">Completely free in-app payments (0% fee)</span>
                  </li>
                  <li className="flex gap-3">
                    <Check className="w-5 h-5 text-[#3EB489] flex-shrink-0" />
                    <span className="text-gray-700 text-sm">Advanced loyalty programs and rewards</span>
                  </li>
                  <li className="flex gap-3">
                    <Check className="w-5 h-5 text-[#3EB489] flex-shrink-0" />
                    <span className="text-gray-700 text-sm">Enhanced gift card management</span>
                  </li>
                  <li className="flex gap-3">
                    <Check className="w-5 h-5 text-[#3EB489] flex-shrink-0" />
                    <span className="text-gray-700 text-sm">Advanced customer analytics and insights</span>
                  </li>
                  <li className="flex gap-3">
                    <Check className="w-5 h-5 text-[#3EB489] flex-shrink-0" />
                    <span className="text-gray-700 text-sm">Priority customer support</span>
                  </li>
                </ul>
              </div>
              
              <div className="p-8 pt-4">
                <button
                  onClick={() => handleSubscribe('pro')}
                  className="w-full bg-[#0D4029] text-white hover:bg-[#0D4029]/90 font-medium py-3 rounded-lg transition-colors flex items-center justify-center"
                >
                  <span>Get started</span>
                  <ArrowRight className="w-4 h-4 ml-2" />
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Savings visualization */}
        <div className="max-w-6xl mx-auto px-6 mt-20 mb-24">
          <div className="bg-white rounded-2xl shadow-lg overflow-hidden border border-gray-200">
            <div className="bg-gradient-to-r from-[#0D4029]/5 to-[#3EB489]/10 p-8 border-b border-gray-200" style={{background: "linear-gradient(120deg, rgba(13,64,41,0.05), rgba(62,180,137,0.07))"}}>
              <h3 className="text-2xl font-bold text-center mb-3">See your payment savings</h3>
              <p className="text-gray-500 mt-1">Just like Starbucks, your customers pay through your Clavaa loyalty app</p>
            </div>
            
            <div className="grid md:grid-cols-3 divide-x divide-gray-200">
              <div className="p-8">
                <div className="text-sm font-medium text-gray-500 uppercase tracking-wide mb-6">Small Business</div>
                
                <div className="flex items-center mb-6">
                  <CreditCard className="w-10 h-10 text-[#3EB489] bg-[#3EB489]/10 p-2 rounded-lg" />
                  <div className="ml-4">
                    <div className="text-sm text-gray-500">Monthly transactions</div>
                    <div className="text-xl font-bold text-gray-900">$10,000</div>
                  </div>
                </div>
                
                <div className="space-y-4">
                  <div>
                    <div className="flex justify-between text-sm">
                      <span className="text-gray-500">Credit cards (2.9%)</span>
                      <span className="text-gray-900 font-medium">$290/mo</span>
                    </div>
                    <div className="mt-1 h-2 bg-gray-100 rounded-full overflow-hidden">
                      <div className="h-full bg-gray-400 rounded-full" style={{width: '100%'}}></div>
                    </div>
                  </div>
                  
                  <div>
                    <div className="flex justify-between text-sm">
                      <span className="text-gray-500">Clavaa Growth</span>
                      <span className="text-[#0D4029] font-medium">$0/mo</span>
                    </div>
                    <div className="mt-1 h-2 bg-gray-100 rounded-full overflow-hidden">
                      <div className="h-full bg-[#3EB489] rounded-full" style={{width: '0%'}}></div>
                    </div>
                  </div>
                </div>
                
                <div className="mt-6 text-[#3EB489] font-medium flex items-baseline">
                  <span className="text-2xl">$3,480</span>
                  <span className="ml-1 text-sm">saved per year</span>
                </div>
              </div>
              
              <div className="p-8 bg-[#0D4029]/5">
                <div className="text-sm font-medium text-gray-500 uppercase tracking-wide mb-6">Medium Business</div>
                
                <div className="flex items-center mb-6">
                  <CreditCard className="w-10 h-10 text-[#3EB489] bg-[#3EB489]/10 p-2 rounded-lg" />
                  <div className="ml-4">
                    <div className="text-sm text-gray-500">Monthly transactions</div>
                    <div className="text-xl font-bold text-gray-900">$50,000</div>
                  </div>
                </div>
                
                <div className="space-y-4">
                  <div>
                    <div className="flex justify-between text-sm">
                      <span className="text-gray-500">Credit cards (2.9%)</span>
                      <span className="text-gray-900 font-medium">$1,450/mo</span>
                    </div>
                    <div className="mt-1 h-2 bg-gray-100 rounded-full overflow-hidden">
                      <div className="h-full bg-gray-400 rounded-full" style={{width: '100%'}}></div>
                    </div>
                  </div>
                  
                  <div>
                    <div className="flex justify-between text-sm">
                      <span className="text-gray-500">Clavaa Growth</span>
                      <span className="text-[#0D4029] font-medium">$0/mo</span>
                    </div>
                    <div className="mt-1 h-2 bg-gray-100 rounded-full overflow-hidden">
                      <div className="h-full bg-[#3EB489] rounded-full" style={{width: '0%'}}></div>
                    </div>
                  </div>
                </div>
                
                <div className="mt-6 text-[#3EB489] font-medium flex items-baseline">
                  <span className="text-2xl">$17,400</span>
                  <span className="ml-1 text-sm">saved per year</span>
                </div>
              </div>
              
              <div className="p-8">
                <div className="text-sm font-medium text-gray-500 uppercase tracking-wide mb-6">Large Business</div>
                
                <div className="flex items-center mb-6">
                  <CreditCard className="w-10 h-10 text-[#3EB489] bg-[#3EB489]/10 p-2 rounded-lg" />
                  <div className="ml-4">
                    <div className="text-sm text-gray-500">Monthly transactions</div>
                    <div className="text-xl font-bold text-gray-900">$100,000</div>
                  </div>
                </div>
                
                <div className="space-y-4">
                  <div>
                    <div className="flex justify-between text-sm">
                      <span className="text-gray-500">Credit cards (2.9%)</span>
                      <span className="text-gray-900 font-medium">$2,900/mo</span>
                    </div>
                    <div className="mt-1 h-2 bg-gray-100 rounded-full overflow-hidden">
                      <div className="h-full bg-gray-400 rounded-full" style={{width: '100%'}}></div>
                    </div>
                  </div>
                  
                  <div>
                    <div className="flex justify-between text-sm">
                      <span className="text-gray-500">Clavaa Growth</span>
                      <span className="text-[#0D4029] font-medium">$0/mo</span>
                    </div>
                    <div className="mt-1 h-2 bg-gray-100 rounded-full overflow-hidden">
                      <div className="h-full bg-[#3EB489] rounded-full" style={{width: '0%'}}></div>
                    </div>
                  </div>
                </div>
                
                <div className="mt-6 text-[#3EB489] font-medium flex items-baseline">
                  <span className="text-2xl">$34,800</span>
                  <span className="ml-1 text-sm">saved per year</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Key benefits */}
        <div className="bg-gradient-to-b from-white to-[#0D4029]/5 py-20" style={{background: "linear-gradient(to bottom, white 0%, rgba(62,180,137,0.03) 50%, rgba(13,64,41,0.05) 100%)"}}>
          <div className="max-w-6xl mx-auto px-6">
            <h3 className="text-2xl font-bold text-center text-gray-900 mb-12">The Clavaa advantage</h3>
            
            <div className="grid md:grid-cols-3 gap-6">
              <div className="bg-white rounded-2xl p-8 shadow-lg border border-gray-100 flex flex-col items-center text-center">
                <div className="w-16 h-16 flex items-center justify-center rounded-full bg-gradient-to-br from-[#0D4029] to-[#3EB489] text-white mb-6" style={{boxShadow: "0 4px 14px rgba(62,180,137,0.3)"}}>
                  <CreditCard className="w-8 h-8" />
                </div>
                <h4 className="text-xl font-bold text-gray-900 mb-3">Loyalty app with payments</h4>
                <p className="text-gray-600">Just like Starbucks, your customers pay through your loyalty app – eliminating credit card fees</p>
              </div>
              
              <div className="bg-white rounded-2xl p-8 shadow-lg border border-gray-100 flex flex-col items-center text-center">
                <div className="w-16 h-16 flex items-center justify-center rounded-full bg-gradient-to-br from-[#0D4029] to-[#3EB489] text-white mb-6" style={{boxShadow: "0 4px 14px rgba(62,180,137,0.3)"}}>
                  <Zap className="w-8 h-8" />
                </div>
                <h4 className="text-xl font-bold text-gray-900 mb-3">Increase customer loyalty</h4>
                <p className="text-gray-600">Keep customers coming back with digital loyalty cards, rewards, and personalized offers</p>
              </div>
              
              <div className="bg-white rounded-2xl p-8 shadow-lg border border-gray-100 flex flex-col items-center text-center">
                <div className="w-16 h-16 flex items-center justify-center rounded-full bg-gradient-to-br from-[#0D4029] to-[#3EB489] text-white mb-6" style={{boxShadow: "0 4px 14px rgba(62,180,137,0.3)"}}>
                  <Shield className="w-8 h-8" />
                </div>
                <h4 className="text-xl font-bold text-gray-900 mb-3">Valuable customer data</h4>
                <p className="text-gray-600">Gain insights into customer behavior to improve your offerings and marketing</p>
              </div>
            </div>
          </div>
        </div>

        {/* FAQs with subtle animation */}
        <div className="max-w-3xl mx-auto px-6 py-24">
          <h3 className="text-2xl font-bold text-center text-gray-900 mb-12">Frequently asked questions</h3>
          
          <div className="space-y-4">
            <div className="bg-white rounded-xl p-6 shadow-lg border border-gray-100 hover:border-[#3EB489]/30 transition-colors" style={{background: "linear-gradient(to right, white, rgba(62,180,137,0.02))"}}>
              <h4 className="text-lg font-bold text-gray-900 mb-2">What makes Clavaa different from other loyalty programs?</h4>
              <p className="text-gray-600">Clavaa combines a full-featured loyalty program with built-in payment processing – just like the Starbucks app. Your customers can earn rewards and pay all in one place, while you save on payment processing fees.</p>
            </div>
            
            <div className="bg-white rounded-xl p-6 shadow-lg border border-gray-100 hover:border-[#3EB489]/30 transition-colors" style={{background: "linear-gradient(to right, white, rgba(62,180,137,0.02))"}}>
              <h4 className="text-lg font-bold text-gray-900 mb-2">How do the payment savings work?</h4>
              <p className="text-gray-600">When customers pay through your Clavaa loyalty app, you bypass traditional credit card fees (2.9-3.5%). Growth plan users pay absolutely nothing on transactions, while Starter plan users pay just 0.49%.</p>
            </div>
            
            <div className="bg-white rounded-xl p-6 shadow-lg border border-gray-100 hover:border-[#3EB489]/30 transition-colors" style={{background: "linear-gradient(to right, white, rgba(62,180,137,0.02))"}}>
              <h4 className="text-lg font-bold text-gray-900 mb-2">Can I switch plans later?</h4>
              <p className="text-gray-600">Yes, you can upgrade or downgrade your plan at any time. When upgrading, you'll only pay the prorated difference for your current billing period.</p>
            </div>
            
            <div className="bg-white rounded-xl p-6 shadow-lg border border-gray-100 hover:border-[#3EB489]/30 transition-colors" style={{background: "linear-gradient(to right, white, rgba(62,180,137,0.02))"}}>
              <h4 className="text-lg font-bold text-gray-900 mb-2">Are there any transaction limits?</h4>
              <p className="text-gray-600">No, both plans support unlimited transactions and customers. Your loyalty program can grow as large as you want without incurring additional fees.</p>
            </div>
          </div>

          <div className="mt-12 flex justify-center">
            <a 
              href="https://www.clavaa.com/business/contact" 
              className="inline-flex items-center gap-2 bg-white hover:bg-[#0D4029] text-[#0D4029] hover:text-white border border-[#0D4029] font-medium px-6 py-3 rounded-lg transition-all shadow-sm"
            >
              <span>Still have questions?</span>
              <ArrowRight className="w-4 h-4" />
            </a>
          </div>
        </div>
      </main>
      
      {/* Sleek footer with accent */}
      <footer className="bg-[#0D4029]/5 border-t border-gray-200 py-8">
        <div className="max-w-6xl mx-auto px-6 flex flex-col md:flex-row justify-between items-center gap-4">
          <div>
            <div className="text-[#0D4029] font-bold mb-1">Clavaa</div>
            <p className="text-gray-500 text-sm">© {new Date().getFullYear()} Clavaa Loyalty App. All rights reserved.</p>
          </div>
          <a 
            href="mailto:help@clavaa.com" 
            className="text-[#0D4029] hover:text-[#0D4029]/80 transition-colors font-medium flex items-center gap-2"
          >
            <span>help@clavaa.com</span>
            <ArrowRight className="w-4 h-4" />
          </a>
        </div>
      </footer>
    </div>
  );
};

export default PricingPage;