import { createSlice } from '@reduxjs/toolkit';

const photo = require('../../images/fullPOS.png');
const photo2 = require('../../images/scanner.png');
const photo3 = require('../../images/bigSign.png');
const photo4 = require('../../images/smallSign.png');

const initialState = {
  items: {
    "price_1R0yd8P1SGiwmmH3D2bFw3GG": {
      id: "price_1R0yd8P1SGiwmmH3D2bFw3GG",
      name: "Complete POS Package",
      description: "Everything you need to get started: Tablet, secure holder, and QR scanner",
      price: "99.99",
      image: photo,
      features: [
        "10.2-inch tablet optimized for Clavaa",
        "Adjustable tablet holder with 360° rotation",
        "Professional-grade QR scanner",
        "Quick setup guide",
        "24/7 technical support"
      ],
      highlights: "Most Popular"
    },
    "price_1R0ye9P1SGiwmmH3C1qDeVLc": {
      id: "price_1R0ye9P1SGiwmmH3C1qDeVLc",
      name: "Scanner & Holder Bundle",
      description: "Perfect if you already have a compatible tablet",
      price: "38.99",
      image: photo2,
      features: [
        "Adjustable tablet holder",
        "Professional-grade QR scanner",
        "Compatibility guide",
        "Installation manual"
      ]
    },
    "price_1R0yelP1SGiwmmH3KAegLJ9F": {
      id: "price_1R0yelP1SGiwmmH3KAegLJ9F",
      name: "Premium Store Sign",
      description: "Large format, illuminated sign for storefront display",
      price: "18.00",
      image: photo3,
      features: [
        "LED backlit display",
        "Weather-resistant",
        "Easy installation kit included",
        "2-year warranty"
      ]
    },
    "price_1R0yfFP1SGiwmmH3fZjwTm9R": {
      id: "price_1R0yfFP1SGiwmmH3fZjwTm9R",
      name: "Countertop Display",
      description: "Elegant countertop sign with QR code display",
      price: "4.00",
      image: photo4,
      features: [
        "Sleek acrylic design",
        "Built-in QR code display",
        "Customizable insert",
        "Non-slip base"
      ]
    }
  }
};

const itemsSlice = createSlice({
  name: 'items',
  initialState,
  reducers: {} // No reducers needed if items are static
});
export default itemsSlice.reducer;
