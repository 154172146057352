import React, { useState, useEffect } from 'react';
import { Shield, ArrowRight, Zap, ChartBar, Lock, Wallet, Check, X } from 'lucide-react';
import useAccountInfo from 'pages/signInTwo/combined/organisms/Functionality';
import ForgotPasswordDialog from 'pages/signInTwo/ui/molecules/ForgotPasswordDialog';
import AlreadySignedInModal from 'pages/signUpNew/signedInModal';

const SignInPage = () => {
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [validation, setValidation] = useState({
    email: { isValid: false, message: '', touched: false },
    password: { isValid: false, message: '', touched: false }
  });
  const [isFormValid, setIsFormValid] = useState(false);

  const { 
    isLoadingAccount, 
    handleGoogleSignIn, 
    handleSubmit 
  } = useAccountInfo({ 
    setError: (errors) => {
      if (errors.email) {
        setValidation(prev => ({
          ...prev,
          email: { ...prev.email, isValid: false, message: errors.email }
        }));
      }
      if (errors.password) {
        setValidation(prev => ({
          ...prev,
          password: { ...prev.password, isValid: false, message: errors.password }
        }));
      }
    }
  });

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      return { isValid: false, message: 'Email is required' };
    }
    if (!emailRegex.test(email)) {
      return { isValid: false, message: 'Please enter a valid email address' };
    }
    return { isValid: true, message: 'Valid email' };
  };

  const validatePassword = (password) => {
    if (!password) {
      return { isValid: false, message: 'Password is required' };
    }
    if (password.length < 6) {
      return { isValid: false, message: 'Password must be at least 6 characters' };
    }
    return { isValid: true, message: 'Valid password' };
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    const validationResult = name === 'email' 
      ? validateEmail(value)
      : validatePassword(value);

    setValidation(prev => ({
      ...prev,
      [name]: {
        ...validationResult,
        touched: true
      }
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (isFormValid) {
      await handleSubmit(formData.email, formData.password);
    }
  };

  useEffect(() => {
    const isValid = validation.email.isValid && validation.password.isValid;
    setIsFormValid(isValid);
  }, [validation]);

  const ValidationIcon = ({ field }) => {
    if (!validation[field].touched) return null;
    
    return validation[field].isValid ? (
      <Check className="w-4 h-4 text-green-500" />
    ) : (
      <X className="w-4 h-4 text-red-500" />
    );
  };

  return (
    <div className="min-h-screen bg-white flex">
      {/* Left side with background patterns */}
      <div className="w-full md:w-1/2 relative flex items-center justify-center">
        {/* Background Patterns */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute w-[500px] h-[500px] -right-48 -top-48 bg-[#3EB489]/10 rounded-full mix-blend-multiply blur-2xl animate-blob" />
          <div className="absolute w-[500px] h-[500px] -left-48 -bottom-48 bg-[#0D4029]/10 rounded-full mix-blend-multiply blur-2xl animate-blob animation-delay-2000" />
          
          <div 
            className="absolute inset-0 opacity-[0.15]"
            style={{
              backgroundImage: `
                radial-gradient(circle at center, #3EB489 1px, transparent 1px),
                radial-gradient(circle at center, #3EB489 1px, transparent 1px)
              `,
              backgroundSize: '40px 40px',
              backgroundPosition: '0 0, 20px 20px'
            }}
          />
        </div>

        {/* Form Container */}
        <div className="max-w-lg w-full m-8">
          <div className="bg-white rounded-xl  border border-gray-100 px-10 pt-10 pb-8 relative z-10">
            {/* Logo and Headers */}
            <div className="text-center space-y-6 mb-8">
              <h1 className="text-3xl font-semibold text-[#3EB489]">Clavaa</h1>
              <div>
                <h2 className="text-2xl font-semibold tracking-tight text-gray-900">
                  Welcome back
                </h2>
                <p className="mt-2 text-sm text-gray-600">
                  Sign in to your Clavaa account to manage your loyalty program
                </p>
              </div>
            </div>

            <form className="space-y-5" onSubmit={onSubmit}>
              {/* Email Field */}
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                  Email address
                </label>
                <div className="relative">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    onBlur={() => {
                      if (!validation.email.touched) {
                        setValidation(prev => ({
                          ...prev,
                          email: { ...prev.email, touched: true }
                        }));
                      }
                    }}
                    placeholder="you@company.com"
                    className={`w-full pl-3 pr-10 py-2 text-sm border ${
                      validation.email.touched
                        ? validation.email.isValid
                          ? 'border-green-500 focus:ring-green-500'
                          : 'border-red-500 focus:ring-red-500'
                        : 'border-gray-300 focus:ring-[#3EB489]'
                    } rounded-lg focus:ring-2 focus:ring-opacity-20 outline-none transition-all`}
                  />
                  <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                    <ValidationIcon field="email" />
                  </div>
                </div>
                {validation.email.touched && (
                  <p className={`mt-1 text-xs ${
                    validation.email.isValid ? 'text-green-500' : 'text-red-500'
                  }`}>
                    {validation.email.message}
                  </p>
                )}
              </div>

              {/* Password Field */}
              <div>
                <div className="flex items-center justify-between mb-1">
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                    Password
                  </label>
                  <button
                    type="button"
                    onClick={() => setShowForgotPassword(true)}
                    className="text-sm font-medium text-[#3EB489] hover:text-[#0D4029] transition-colors"
                  >
                    Forgot password?
                  </button>
                </div>
                <div className="relative">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    onBlur={() => {
                      if (!validation.password.touched) {
                        setValidation(prev => ({
                          ...prev,
                          password: { ...prev.password, touched: true }
                        }));
                      }
                    }}
                    className={`w-full pl-3 pr-10 py-2 text-sm border ${
                      validation.password.touched
                        ? validation.password.isValid
                          ? 'border-green-500 focus:ring-green-500'
                          : 'border-red-500 focus:ring-red-500'
                        : 'border-gray-300 focus:ring-[#3EB489]'
                    } rounded-lg focus:ring-2 focus:ring-opacity-20 outline-none transition-all`}
                  />
                  <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                    <ValidationIcon field="password" />
                  </div>
                </div>
                {validation.password.touched && (
                  <p className={`mt-1 text-xs ${
                    validation.password.isValid ? 'text-green-500' : 'text-red-500'
                  }`}>
                    {validation.password.message}
                  </p>
                )}
              </div>

              {/* Submit Button */}
              <button 
                type="submit" 
                disabled={!isFormValid || isLoadingAccount}
                className={`w-full text-white text-sm font-medium py-2 px-4 rounded-lg focus:ring-2 focus:ring-[#3EB489]/20 focus:outline-none transition-all flex items-center justify-center gap-2
                  ${isFormValid 
                    ? 'bg-[#0D4029] hover:bg-black cursor-pointer' 
                    : 'bg-gray-300 cursor-not-allowed'
                  }`}
              >
                {isLoadingAccount ? 'Signing in...' : 'Sign in to dashboard'}
                <ArrowRight className="w-4 h-4" />
              </button>

              {/* Or continue with Google */}
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-200"></div>
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">or</span>
                </div>
              </div>

              <button 
                type="button"
                onClick={handleGoogleSignIn}
                className="w-full flex items-center justify-center gap-2 px-3 py-2 border border-gray-300 rounded-lg text-sm font-medium text-gray-700 hover:bg-gray-50 transition-colors"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-4 h-4">
                  <path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4"/>
                  <path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853"/>
                  <path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05"/>
                  <path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335"/>
                </svg>
                Continue with Google
              </button>

              {/* Sign up link */}
              <div className="text-center pt-2">
                <div className="text-sm">
                  Don't have an account?{' '}
                  <a 
                    href="/sign-up" 
                    className="font-medium text-[#3EB489] hover:text-[#0D4029] transition-colors"
                  >
                    Sign up
                  </a>
                </div>
              </div>
            </form>

            {/* Stripe Security Footer */}
            <div className="mt-6 pt-6 border-t border-gray-100">
              <div className="flex items-center justify-center gap-2 text-gray-500">
                <Shield className="w-4 h-4" />
                <span className="text-xs">Protected by Stripe</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Right side - Enhanced value prop */}
      <div className="hidden md:flex md:w-1/2 bg-gradient-to-br from-[#3EB489] to-[#0D4029] relative overflow-hidden items-center justify-start">
        <div className="absolute inset-0" style={{
          backgroundImage: `radial-gradient(circle at 2px 2px, rgba(255,255,255,0.1) 1px, transparent 0)`,
          backgroundSize: '16px 16px'
        }}>
          <div className="absolute inset-0 bg-gradient-to-br from-transparent to-black/20" />
        </div>

        <div className="relative w-full max-w-2xl p-16">
          {/* Main heading */}
          <h2 className="text-4xl font-bold text-white mb-6">
            The #1 Loyalty Wallet with Built-in Free Payments
          </h2>
          <p className="text-xl text-white/90 mb-12">
            Free payments processing when customers pay with Clavaa Wallet
          </p>

          {/* Features grid */}
          <div className="space-y-8">
            <div className="bg-white/10 rounded-xl p-6 backdrop-blur-sm hover:bg-white/20 transition-colors">
              <div className="flex items-start gap-4">
                <div className="p-2 bg-[#3EB489] rounded-lg">
                  <Wallet className="w-5 h-5 text-white" />
                </div>
                <div>
                  <h3 className="text-lg font-medium text-white">Smart Loyalty Wallet</h3>
                  <p className="text-white/80 mt-1">
                    Turn points into payments with our revolutionary loyalty system
                  </p>
                </div>
              </div>
            </div>

            <div className="bg-white/10 rounded-xl p-6 backdrop-blur-sm hover:bg-white/20 transition-colors">
              <div className="flex items-start gap-4">
                <div className="p-2 bg-[#3EB489] rounded-lg">
                  <Zap className="w-5 h-5 text-white" />
                </div>
                <div>
                  <h3 className="text-lg font-medium text-white">Zero-Fee Payments</h3>
                  <p className="text-white/80 mt-1">
                    Process payments for free when customers use their points
                  </p>
                </div>
              </div>
            </div>

            <div className="bg-white/10 rounded-xl p-6 backdrop-blur-sm hover:bg-white/20 transition-colors">
              <div className="flex items-start gap-4">
                <div className="p-2 bg-[#3EB489] rounded-lg">
                  <ChartBar className="w-5 h-5 text-white" />
                </div>
                <div>
                  <h3 className="text-lg font-medium text-white">Advanced Analytics</h3>
                  <p className="text-white/80 mt-1">
                    Track loyalty metrics and payment analytics in one dashboard
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Forgot Password Dialog */}
      {showForgotPassword && (
        <ForgotPasswordDialog 
          open={showForgotPassword} 
          onClose={() => setShowForgotPassword(false)} 
        />
      )}
     <AlreadySignedInModal/>
    </div>
  );
};

export default SignInPage;