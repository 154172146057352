import React from 'react';
import { Gift, Plus } from 'lucide-react';

const NoGiftsState = ({ onCreateClick }) => (
  <div className="text-center py-16 border border-gray-200 rounded-lg">
    <div className="mx-auto w-24 h-24 border-2 border-gray-100 rounded-full flex items-center justify-center mb-4">
      <Gift className="w-8 h-8 text-gray-400 transition-transform duration-200 group-hover:scale-105" />
    </div>
    <h3 className="text-xl font-semibold text-gray-900">No gifts yet</h3>
    <p className="mt-2 text-gray-600 max-w-md mx-auto">
      Create your first gift card to start rewarding customers and increase loyalty
    </p>
    <button 
      className="mt-6 px-5 py-2.5 bg-[#0D4029] text-white rounded-lg hover:bg-[#0D4029]/90 transition-colors flex items-center justify-center mx-auto space-x-2"
      onClick={onCreateClick}
    >
      <Plus className="w-4 h-4" />
      <span>Create gift</span>
    </button>
  </div>
);

export default NoGiftsState;