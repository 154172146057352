import React from "react";
import DashboardLayout from "sideBar/main";
import BoostDashboard from "./mainInside";



const BoostPage = ({
 
}) => {





  return (
    <>
    
<DashboardLayout>
<BoostDashboard />

</DashboardLayout>

    </>
  );
};

export default BoostPage;
