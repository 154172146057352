import React, { useState } from 'react';
import { X, Gift, VideoIcon, Wallet, ArrowRight, ArrowLeft, Store } from 'lucide-react';

const FeatureCard = ({ icon: Icon, title, description }) => (
  <div className="text-center p-6 rounded-xl bg-gray-50 hover:bg-gray-100 transition-all duration-300">
    <Icon className="w-8 h-8 text-[#0D4029] mx-auto mb-3" />
    <h3 className="text-sm font-medium text-gray-900">{title}</h3>
    <p className="text-xs text-gray-500 mt-1">{description}</p>
  </div>
);

const GiftCardModal = ({ isOpen, onClose }) => {
  const [step, setStep] = useState(1);
  
  const steps = [
    {
      title: "Gift Cards in Clavaa",
      illustration: (
        <div className="grid grid-cols-3 gap-4 max-w-xl mx-auto py-6">
          <FeatureCard
            icon={VideoIcon}
            title="Add Videos"
            description="Personal message with every card"
          />
          <FeatureCard
            icon={Wallet}
            title="Clavaa Wallet"
            description="Part of payment & loyalty"
          />
          <FeatureCard
            icon={Store}
            title="Auto-Deducted"
            description="From purchase price"
          />
        </div>
      ),
      description: "Send gift cards with video messages. Automatically deducted from purchase through Clavaa Wallet."
    },
    {
      title: "Simple Flow",
      illustration: (
        <div className="flex items-center justify-center gap-8 py-8">
          <div className="flex items-center gap-6">
            <div className="text-center">
              <div className="w-20 h-20 rounded-xl bg-[#0D4029]/10 flex items-center justify-center mb-3">
                <Gift className="w-10 h-10 text-[#0D4029]" />
              </div>
              <p className="text-xs font-medium">Send Card</p>
            </div>
            <ArrowRight className="w-5 h-5 text-gray-400" />
            <div className="text-center">
              <div className="w-20 h-20 rounded-xl bg-[#0D4029]/10 flex items-center justify-center mb-3">
                <Wallet className="w-10 h-10 text-[#0D4029]" />
              </div>
              <p className="text-xs font-medium">Added to Wallet</p>
            </div>
            <ArrowRight className="w-5 h-5 text-gray-400" />
            <div className="text-center">
              <div className="w-20 h-20 rounded-xl bg-[#0D4029]/10 flex items-center justify-center mb-3">
                <Store className="w-10 h-10 text-[#0D4029]" />
              </div>
              <p className="text-xs font-medium">Auto-Applied</p>
            </div>
          </div>
        </div>
      ),
      description: "Manage everything from your Clavaa dashboard. Gift cards integrate with your payment and loyalty system."
    }
  ];

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div 
        className="absolute inset-0 bg-black/50 transition-opacity backdrop-blur-sm"
        onClick={onClose}
      />
      <div className="relative bg-white rounded-xl shadow-xl w-[600px] max-w-[95vw] p-8">
        <button
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 p-2"
        >
          <X className="w-5 h-5" />
        </button>

        <div className="space-y-6">
          <div className="text-center">
            <h2 className="text-xl font-medium text-gray-900">{steps[step - 1].title}</h2>
          </div>

          {steps[step - 1].illustration}

          <p className="text-sm text-gray-600 text-center max-w-md mx-auto">
            {steps[step - 1].description}
          </p>

          <div className="flex items-center justify-between pt-4 border-t">
            <button
              onClick={() => setStep(step - 1)}
              disabled={step === 1}
              className={`p-2 rounded ${
                step === 1 
                  ? 'text-gray-300 cursor-not-allowed' 
                  : 'text-gray-600 hover:text-gray-900'
              }`}
            >
              <ArrowLeft className="w-4 h-4" />
            </button>

            {step < steps.length ? (
              <button
                onClick={() => setStep(step + 1)}
                className="px-4 py-2 bg-[#0D4029] text-white text-sm rounded-lg 
                  hover:bg-[#0D4029]/90 transition-all"
              >
                Continue
              </button>
            ) : (
              <button
                onClick={onClose}
                className="px-4 py-2 bg-[#0D4029] text-white text-sm rounded-lg 
                  hover:bg-[#0D4029]/90 transition-all"
              >
                Got it
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GiftCardModal;