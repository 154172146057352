import React, { useState } from 'react';
import { Calendar, Clock } from 'lucide-react';

const BoostDuration = ({ formData, setFormData }) => {
  const [selectedOption, setSelectedOption] = useState('now');
  const [customDuration, setCustomDuration] = useState('24h');

  const durationOptions = [
    { value: '24h', label: '24 hours' },
    { value: '48h', label: '48 hours' },
    { value: '7d', label: '7 days' },
    { value: '30d', label: '30 days' }
  ];

  const getTodayDate = () => {
    // Ensure date is adjusted to the local timezone
    return new Date().toLocaleDateString('en-CA'); // 'en-CA' outputs YYYY-MM-DD format
  };

  const calculateEndDate = (startDateStr, duration) => {
    const startDate = new Date(startDateStr);
    const endDate = new Date(startDate);

    switch (duration) {
      case '24h':
        endDate.setHours(endDate.getHours() + 24);
        break;
      case '48h':
        endDate.setHours(endDate.getHours() + 48);
        break;
      case '7d':
        endDate.setDate(endDate.getDate() + 7);
        break;
      case '30d':
        endDate.setDate(endDate.getDate() + 30);
        break;
      default:
        break;
    }

    return endDate.toISOString().split('T')[0];
  };

  const handleDurationSelect = (duration) => {
    setCustomDuration(duration);

    if (selectedOption === 'now') {
      const startDate = getTodayDate();
      const endDate = calculateEndDate(startDate, duration);
      console.log(`[DEBUG] Start Now: startDate=${startDate}, endDate=${endDate}`);

      setFormData({
        ...formData,
        startDate,
        endDate,
        startNow: true,
        duration,
      });
    } else if (formData.startDate) {
      const endDate = calculateEndDate(formData.startDate, duration);
      console.log(`[DEBUG] Scheduled: startDate=${formData.startDate}, endDate=${endDate}`);

      setFormData({
        ...formData,
        endDate,
        startNow: false,
        duration,
      });
    }
  };

  const handleStartDateChange = (date) => {
    const endDate = calculateEndDate(date, customDuration);
    console.log(`[DEBUG] Handle Start Date Change: startDate=${date}, endDate=${endDate}`);

    setFormData({
      ...formData,
      startDate: date,
      endDate,
      startNow: false,
    });
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);

    if (option === 'now') {
      const startDate = getTodayDate();
      const endDate = calculateEndDate(startDate, customDuration);
      console.log(`[DEBUG] Option Changed to Now: startDate=${startDate}, endDate=${endDate}`);

      setFormData({
        ...formData,
        startDate,
        endDate,
        startNow: true,
      });
    } else {
      console.log(`[DEBUG] Option Changed to Schedule`);
      setFormData({
        ...formData,
        startDate: '',
        endDate: '',
        startNow: false,
      });
    }
  };

  return (
    <div className="space-y-6">
      <div className="mb-6">
        <h2 className="text-lg font-semibold text-gray-900">Set duration</h2>
        <p className="text-sm text-gray-500">Choose when your boost starts and ends</p>
      </div>

      <div className="space-y-4">
        <div className="flex items-center gap-4">
          <button
            onClick={() => handleOptionChange('now')}
            className={`flex items-center gap-2 p-4 border rounded-lg text-left transition-all ${
              selectedOption === 'now'
                ? 'border-[#0D4029] bg-[#0D4029]/5'
                : 'border-gray-200 hover:border-[#0D4029] hover:bg-[#0D4029]/5'
            }`}
          >
            <div
              className={`p-2 rounded-lg ${
                selectedOption === 'now'
                  ? 'bg-[#0D4029] text-white'
                  : 'bg-gray-50 text-gray-500'
              }`}
            >
              <Clock className="w-5 h-5" />
            </div>
            <span className="font-medium text-gray-900">Start now</span>
          </button>

          <button
            onClick={() => handleOptionChange('schedule')}
            className={`flex items-center gap-2 p-4 border rounded-lg text-left transition-all ${
              selectedOption === 'schedule'
                ? 'border-[#0D4029] bg-[#0D4029]/5'
                : 'border-gray-200 hover:border-[#0D4029] hover:bg-[#0D4029]/5'
            }`}
          >
            <div
              className={`p-2 rounded-lg ${
                selectedOption === 'schedule'
                  ? 'bg-[#0D4029] text-white'
                  : 'bg-gray-50 text-gray-500'
              }`}
            >
              <Calendar className="w-5 h-5" />
            </div>
            <span className="font-medium text-gray-900">Schedule for later</span>
          </button>
        </div>

        {selectedOption === 'now' && (
          <div className="space-y-3">
            <label className="block text-sm font-medium text-gray-700">
              Duration
            </label>
            <div className="grid grid-cols-2 gap-3 sm:grid-cols-4">
              {durationOptions.map((option) => (
                <button
                  key={option.value}
                  onClick={() => handleDurationSelect(option.value)}
                  className={`p-4 border rounded-lg text-left transition-all ${
                    customDuration === option.value
                      ? 'border-[#0D4029] bg-[#0D4029]/5'
                      : 'border-gray-200 hover:border-[#0D4029] hover:bg-[#0D4029]/5'
                  }`}
                >
                  <span className="font-medium text-gray-900">{option.label}</span>
                </button>
              ))}
            </div>
          </div>
        )}

        {selectedOption === 'schedule' && (
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Start date
              </label>
              <div className="relative">
                <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                <input
                  type="date"
                  className="pl-10 pr-4 py-2 w-full border border-gray-200 rounded-lg focus:ring-1 focus:ring-[#0D4029] focus:border-[#0D4029]"
                  value={formData.startDate}
                  min={getTodayDate()}
                  onChange={(e) => handleStartDateChange(e.target.value)}
                />
              </div>
            </div>
            <div className="space-y-3">
              <label className="block text-sm font-medium text-gray-700">
                Duration
              </label>
              <div className="grid grid-cols-2 gap-3 sm:grid-cols-4">
                {durationOptions.map((option) => (
                  <button
                    key={option.value}
                    onClick={() => handleDurationSelect(option.value)}
                    className={`p-4 border rounded-lg text-left transition-all ${
                      customDuration === option.value
                        ? 'border-[#0D4029] bg-[#0D4029]/5'
                        : 'border-gray-200 hover:border-[#0D4029] hover:bg-[#0D4029]/5'
                    }`}
                  >
                    <span className="font-medium text-gray-900">{option.label}</span>
                  </button>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BoostDuration;