import React, { useState } from 'react';
import { Shield, LockKeyhole, Store, Wallet, ArrowRight } from 'lucide-react';
import CombinedFunctionality from 'pages/signUpTwoStripe/combined/organisms/Functionality';
import VerificationDialog from 'pages/signUp/email/ui/organisms/dialog/main';
import AlreadySignedInModal from './signedInModal';

const SignupPage = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  
  const [errors, setErrors] = useState({
    email: '',
    password: ''
  });

  const [touched, setTouched] = useState({
    email: false,
    password: false
  });

  const {
    isLoadingCode,
    isLoadingAccount,
    isResendingCode,
    isSuccess,
    errorMessage,
    codeValues,
    inputRefs,
    handleChange: handleCodeChange,
    handleKeyDown,
    resendCode,
    confirmCode,
    resetCode,
    handleGoToDashboard,
    handleCloseCodeDialog,
    handleEmailSubmit,
    isCodeDialogOpen,
    handleGoogleSignUp,
  } = CombinedFunctionality({ setError: setErrors });

  // All validation functions remain the same
  const validateEmail = (email) => {
    if (!email) return 'Email is required';
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) return 'Please enter a valid email address';
    return '';
  };

  const validatePassword = (password) => {
    if (!password) return 'Password is required';
    if (password.length < 6) return 'Password must be at least 6 characters';
    return '';
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    if (touched[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: name === 'email' ? validateEmail(value) : validatePassword(value)
      }));
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    setTouched(prev => ({ ...prev, [name]: true }));
    setErrors(prev => ({
      ...prev,
      [name]: name === 'email' ? validateEmail(value) : validatePassword(value)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailError = validateEmail(formData.email);
    const passwordError = validatePassword(formData.password);
    
    setErrors({ email: emailError, password: passwordError });
    setTouched({ email: true, password: true });

    if (!emailError && !passwordError) {
      await handleEmailSubmit(formData.email, formData.password);
    }
  };

  return (
    <div className="min-h-screen bg-white flex">
      {/* Left side with background patterns */}
      <div className="w-full md:w-1/2 relative flex items-center justify-center">
        {/* Background Patterns */}
        <div className="absolute inset-0 overflow-hidden">
          {/* Gradient circles */}
          <div className="absolute w-[500px] h-[500px] -left-48 -top-48 bg-[#3EB489]/10 rounded-full mix-blend-multiply blur-2xl animate-blob" />
         
          
          {/* Grid pattern */}
          <div 
            className="absolute inset-0 opacity-[0.15]"
            style={{
              backgroundImage: `
                radial-gradient(circle at center, #3EB489 1px, transparent 1px),
                radial-gradient(circle at center, #3EB489 1px, transparent 1px)
              `,
              backgroundSize: '40px 40px',
              backgroundPosition: '0 0, 20px 20px'
            }}
          />
        </div>

        {/* Form Container */}
        <div className="max-w-lg w-full m-8">
          {/* Main Form Card */}
          <div className="bg-white rounded-xl  border border-gray-100 px-10 pt-10 pb-8 relative z-10">
            {/* Logo and Headers */}
            <div className="text-center space-y-6 mb-8">
              <h1 className="text-3xl font-bold text-[#3EB489]">Clavaa</h1>
              <div>
                <h2 className="text-2xl font-semibold tracking-tight text-gray-900">
                  Sign up free for your business
                </h2>
                <p className="mt-2 text-sm text-gray-600">
                  Get started with Clavaa's smart loyalty & payments platform
                </p>
              </div>
            </div>

            <form onSubmit={handleSubmit} className="space-y-5">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                  Work email
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleFormChange}
                  onBlur={handleBlur}
                  placeholder="you@company.com"
                  className={`w-full px-3 py-2 text-sm border rounded-lg focus:ring-2 focus:ring-[#3EB489]/20 focus:border-[#3EB489] outline-none transition-all ${
                    errors.email && touched.email ? 'border-red-500' : 'border-gray-300'
                  }`}
                />
                {errors.email && touched.email && (
                  <p className="mt-1 text-xs text-red-500">{errors.email}</p>
                )}
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  value={formData.password}
                  onChange={handleFormChange}
                  onBlur={handleBlur}
                  placeholder="6+ characters"
                  className={`w-full px-3 py-2 text-sm border rounded-lg focus:ring-2 focus:ring-[#3EB489]/20 focus:border-[#3EB489] outline-none transition-all ${
                    errors.password && touched.password ? 'border-red-500' : 'border-gray-300'
                  }`}
                />
                {errors.password && touched.password && (
                  <p className="mt-1 text-xs text-red-500">{errors.password}</p>
                )}
              </div>

              {/* Submit Button */}
              <button 
                type="submit" 
                className="w-full bg-[#0D4029] text-white text-sm font-medium py-2 px-4 rounded-lg hover:bg-black focus:bg-[#0D4029] focus:ring-2 focus:ring-[#3EB489]/20 focus:outline-none transition-colors flex items-center justify-center gap-2"
              >
                Sign up free
                <ArrowRight className="w-4 h-4" />
              </button>

              {/* Or continue with Google */}
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-200"></div>
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">or</span>
                </div>
              </div>

              <button 
                type="button"
                onClick={handleGoogleSignUp}
                className="w-full flex items-center justify-center gap-2 px-3 py-2 border border-gray-300 rounded-lg text-sm font-medium text-gray-700 hover:bg-gray-50 transition-colors"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-4 h-4">
                  <path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4"/>
                  <path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853"/>
                  <path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05"/>
                  <path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335"/>
                </svg>
                Continue with Google
              </button>

              {/* Sign in link */}
              <div className="text-center pt-2">
                <div className="text-sm">
                  Already have an account?{' '}
                  <a 
                    href="/sign-in" 
                    className="font-medium text-[#3EB489] hover:text-[#0D4029] transition-colors"
                  >
                    Sign in
                  </a>
                </div>
              </div>
            </form>

            {/* Stripe Security Footer */}
            <div className="mt-6 pt-6 border-t border-gray-100">
              <div className="flex items-center justify-center gap-2 text-gray-500">
                <Shield className="w-4 h-4" />
                <span className="text-xs">Protected by Stripe</span>
              </div>
            </div>
          </div>

          {/* Terms under card */}
          <p className="text-xs text-gray-500 text-center mt-6">
            By creating an account, you agree to Clavaa's{' '}
            <a href="https://clavaa.com/business-terms-of-service" className="text-[#3EB489] hover:text-[#0D4029]">Terms of Service</a>{' '}
            and{' '}
            <a href="https://clavaa.com/privacy-policy" className="text-[#3EB489] hover:text-[#0D4029]">Privacy Policy</a>
          </p>
        </div>
      </div>

      {/* Right side - Value Proposition (remains exactly the same) */}
      <div className="hidden md:block md:w-1/2 bg-gradient-to-br from-[#3EB489] to-[#0D4029] relative overflow-hidden">
        <div className="absolute inset-0" style={{
          backgroundImage: `radial-gradient(circle at 2px 2px, rgba(255,255,255,0.1) 1px, transparent 0)`,
          backgroundSize: '16px 16px'
        }}>
          <div className="absolute inset-0 bg-gradient-to-br from-transparent to-black/20" />
        </div>

        <div className="relative h-full flex flex-col justify-center p-16 text-white">
          <div className="space-y-8">
            <h2 className="text-3xl font-semibold">Transform your business with zero-cost payments & smart loyalty</h2>
            
            <div className="space-y-6">
              <div className="flex items-start gap-4">
                <Wallet className="w-6 h-6 mt-1 text-[#3EB489]" />
                <div>
                  <h3 className="font-medium text-lg">Free payment processing</h3>
                  <p className="text-white/90 text-sm">Save on every transaction when customers pay with Clavaa Wallet</p>
                </div>
              </div>

              <div className="flex items-start gap-4">
                <Store className="w-6 h-6 mt-1 text-[#3EB489]" />
                <div>
                  <h3 className="font-medium text-lg">Self-funding rewards</h3>
                  <p className="text-white/90 text-sm">Turn processing fees into customer cashback, redeemable only at your store</p>
                </div>
              </div>
            </div>

            <div className="pt-4">
              <div className="text-sm bg-white/10 p-4 rounded-lg">
                "Since using Clavaa, our customer return rate has increased by 40%. The self-funding rewards model means we're reinvesting transaction fees into customer loyalty."
                <div className="mt-2 font-medium">Mike Chen, Owner of Local Brew Coffee</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <VerificationDialog
        email={formData.email}
        isLoadingCode={isLoadingCode}
        isLoadingAccount={isLoadingAccount}
        errorMessage={errorMessage}
        onResendCode={resendCode}
        open={isCodeDialogOpen}
        onClose={handleCloseCodeDialog}
        isSuccess={isSuccess}
        codeLength={6}
        onGoToDashboard={handleGoToDashboard}
        inputRefs={inputRefs}
        codeValues={codeValues}
        onChange={handleCodeChange}
        onKeyDown={handleKeyDown}
        isResendingCode={isResendingCode}
      />
      <AlreadySignedInModal/>
    </div>
  );
};

export default SignupPage;