import React, { useState } from 'react';
import { ArrowRight, ChevronRight, Gift, CreditCard, User, Terminal, HelpCircle, DollarSign, Zap, Store } from 'lucide-react';

const DashboardGuide = () => {
  const [activeTab, setActiveTab] = useState('payments');
  
  // Clavaa brand colors
  const primaryColor = '#3EB489';
  const secondaryColor = '#0D4029';
  
  const tabs = [
    { id: 'payments', label: 'Payments & POS' },
    { id: 'loyalty', label: 'Loyalty Program' },
    { id: 'marketing', label: 'Marketing' },
    { id: 'account', label: 'Account' }
  ];
  
  const renderTabContent = () => {
    switch(activeTab) {
      case 'payments':
        return (
          <div className="py-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="bg-white rounded-lg border border-gray-200 overflow-hidden hover:shadow-sm transition-all">
                <div className="px-6 py-5 border-b border-gray-100" style={{ backgroundColor: 'rgba(13, 64, 41, 0.03)' }}>
                  <h3 className="font-medium text-base" style={{ color: secondaryColor }}>POS Hardware</h3>
                </div>
                <div className="p-6">
                  <div className="flex items-start">
                    <div className="w-10 h-10 rounded-lg flex items-center justify-center mr-4" 
                      style={{ backgroundColor: 'rgba(13, 64, 41, 0.07)', color: secondaryColor }}>
                      <Terminal size={20} />
                    </div>
                    <div>
                      <h4 className="text-base font-medium text-gray-900">Order Equipment</h4>
                      <p className="text-sm text-gray-600 mt-1 mb-5 leading-relaxed">
                        Order the tablet, QR scanner, and accessories needed for your in-store Clavaa payment system.
                      </p>
                      <div className="flex items-center text-sm text-gray-500 mb-5">
                        <div className="w-1 h-1 rounded-full mr-2" style={{ backgroundColor: primaryColor }}></div>
                        Shipping typically takes 5-7 business days
                      </div>
                      <a 
                        href="/dashboard/material/order" 
                        className="inline-flex items-center px-4 py-2 rounded-md text-sm font-medium text-white shadow-sm hover:shadow transition-all"
                        style={{ backgroundColor: primaryColor }}
                      >
                        Order equipment
                        <ArrowRight size={16} className="ml-2" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="bg-white rounded-lg border border-gray-200 overflow-hidden hover:shadow-sm transition-all">
                <div className="px-6 py-5 border-b border-gray-100" style={{ backgroundColor: 'rgba(13, 64, 41, 0.03)' }}>
                  <h3 className="font-medium text-base" style={{ color: secondaryColor }}>POS App</h3>
                </div>
                <div className="p-6">
                  <div className="flex items-start">
                    <div className="w-10 h-10 rounded-lg flex items-center justify-center mr-4" 
                      style={{ backgroundColor: 'rgba(13, 64, 41, 0.07)', color: secondaryColor }}>
                      <CreditCard size={20} />
                    </div>
                    <div>
                      <h4 className="text-base font-medium text-gray-900">POS Credentials</h4>
                      <p className="text-sm text-gray-600 mt-1 mb-5 leading-relaxed">
                        Get your POS app sign-in credentials and download the Clavaa POS application.
                      </p>
                      <div className="grid grid-cols-2 gap-3 mb-5">
                        <a href="https://play.google.com/store/apps/details?id=com.yourcompany.posregister" 
                          className="inline-flex items-center px-3 py-2 rounded-md text-sm border border-gray-200 text-gray-700 hover:border-gray-300 transition-colors">
                          Android App
                          <ChevronRight size={16} className="ml-2 opacity-70" />
                        </a>
                        <a href="https://apps.apple.com/us/app/clavaa-pos/id6741890660" 
                          className="inline-flex items-center px-3 py-2 rounded-md text-sm border border-gray-200 text-gray-700 hover:border-gray-300 transition-colors">
                          iOS App
                          <ChevronRight size={16} className="ml-2 opacity-70" />
                        </a>
                      </div>
                      <a 
                        href="/pos/register" 
                        className="inline-flex items-center px-4 py-2 rounded-md text-sm font-medium text-white shadow-sm hover:shadow transition-all"
                        style={{ backgroundColor: primaryColor }}
                      >
                        View POS credentials
                        <ArrowRight size={16} className="ml-2" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="mt-6 bg-white rounded-lg border border-gray-200 overflow-hidden hover:shadow-sm transition-all">
              <div className="px-6 py-5 border-b border-gray-100" style={{ backgroundColor: 'rgba(13, 64, 41, 0.03)' }}>
                <h3 className="font-medium text-base" style={{ color: secondaryColor }}>Using Clavaa Payments</h3>
              </div>
              <div className="p-6">
                <ol className="space-y-5">
                  <li className="flex">
                    <div className="flex-shrink-0 w-8 h-8 rounded-full flex items-center justify-center mr-4 text-sm font-medium text-white" 
                      style={{ backgroundColor: primaryColor }}>1</div>
                    <div>
                      <p className="text-base text-gray-800 font-medium">Ring up customer in your regular POS system</p>
                      <p className="text-sm text-gray-500 mt-1">Process the transaction as you normally would</p>
                    </div>
                  </li>
                  <li className="flex">
                    <div className="flex-shrink-0 w-8 h-8 rounded-full flex items-center justify-center mr-4 text-sm font-medium text-white" 
                      style={{ backgroundColor: primaryColor }}>2</div>
                    <div>
                      <p className="text-base text-gray-800 font-medium">Enter total amount in the Clavaa POS app</p>
                      <p className="text-sm text-gray-500 mt-1">Use the tablet with the Clavaa POS app installed</p>
                    </div>
                  </li>
                  <li className="flex">
                    <div className="flex-shrink-0 w-8 h-8 rounded-full flex items-center justify-center mr-4 text-sm font-medium text-white" 
                      style={{ backgroundColor: primaryColor }}>3</div>
                    <div>
                      <p className="text-base text-gray-800 font-medium">Customer scans QR code with their Clavaa app</p>
                      <p className="text-sm text-gray-500 mt-1">The app will process the payment and apply any rewards</p>
                    </div>
                  </li>
                  <li className="flex">
                    <div className="flex-shrink-0 w-8 h-8 rounded-full flex items-center justify-center mr-4 text-sm font-medium text-white" 
                      style={{ backgroundColor: primaryColor }}>4</div>
                    <div>
                      <p className="text-base text-gray-800 font-medium">Mark transaction as complete in your regular POS</p>
                      <p className="text-sm text-gray-500 mt-1">Add "Clavaa" as a custom payment type in your POS system (all POS systems allow adding custom payment types) or use the "Cash" option if you prefer</p>
                    </div>
                  </li>
                </ol>
                
                <div className="mt-6 pt-5 border-t border-gray-100 flex items-start">
                  <div className="w-10 h-10 rounded-lg flex items-center justify-center mr-4 border border-gray-200" 
                    style={{ color: secondaryColor }}>
                    <HelpCircle size={20} />
                  </div>
                  <div>
                    <p className="text-sm text-gray-700">
                      Want a direct integration with your POS system? Contact your account admin at{' '}
                      <a href="mailto:help@clavaa.com" className="font-medium hover:underline" style={{ color: primaryColor }}>help@clavaa.com</a>{' '}
                      to check availability.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case 'loyalty':
        return (
          <div className="py-4">
            <div className="bg-white rounded-lg border border-gray-200 overflow-hidden hover:shadow-sm transition-all">
              <div className="px-6 py-5 border-b border-gray-100" style={{ backgroundColor: 'rgba(13, 64, 41, 0.03)' }}>
                <h3 className="font-medium text-base" style={{ color: secondaryColor }}>Loyalty & Cashback</h3>
              </div>
              <div className="p-6">
                <div className="flex items-start">
                  <div className="w-10 h-10 rounded-lg flex items-center justify-center mr-4" 
                    style={{ backgroundColor: 'rgba(62, 180, 137, 0.1)', color: primaryColor }}>
                    <DollarSign size={20} />
                  </div>
                  <div className="flex-1">
                    <h4 className="text-lg font-medium text-gray-900">Loyalty Program Settings</h4>
                    <p className="text-base text-gray-600 mt-2 mb-6 leading-relaxed">
                      Configure your customer loyalty program including standard and Gold cashback rates. 
                      Set minimum spending thresholds for Gold status, which is valid for 4 months.
                    </p>
                    
                    <div className="p-4 rounded-lg mb-6" style={{ backgroundColor: 'rgba(62, 180, 137, 0.08)' }}>
                      <h5 className="text-sm font-medium mb-2" style={{ color: secondaryColor }}>Recommended settings</h5>
                      <div className="flex flex-col space-y-3">
                        <div className="flex items-start">
                          <div className="w-5 h-5 rounded-full flex items-center justify-center mr-3 mt-0.5" 
                            style={{ backgroundColor: 'rgba(62, 180, 137, 0.15)' }}>
                            <div className="w-1.5 h-1.5 rounded-full" style={{ backgroundColor: primaryColor }}></div>
                          </div>
                          <div>
                            <span className="text-sm text-gray-800 font-medium">Standard customers: 4-5% cashback</span>
                          </div>
                        </div>
                        <div className="flex items-start">
                          <div className="w-5 h-5 rounded-full flex items-center justify-center mr-3 mt-0.5" 
                            style={{ backgroundColor: 'rgba(62, 180, 137, 0.15)' }}>
                            <div className="w-1.5 h-1.5 rounded-full" style={{ backgroundColor: primaryColor }}></div>
                          </div>
                          <div>
                            <span className="text-sm text-gray-800 font-medium">Gold customers: 5-8% cashback</span>
                          </div>
                        </div>
                        <div className="flex items-start">
                          <div className="w-5 h-5 rounded-full flex items-center justify-center mr-3 mt-0.5" 
                            style={{ backgroundColor: 'rgba(62, 180, 137, 0.15)' }}>
                            <div className="w-1.5 h-1.5 rounded-full" style={{ backgroundColor: primaryColor }}></div>
                          </div>
                          <div>
                            <span className="text-sm text-gray-800 font-medium">Gold status threshold: $300-500 spending in 4 months</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="flex justify-start">
                      <a 
                        href="/loyalty/cashback" 
                        className="inline-flex items-center px-5 py-2.5 rounded-md text-sm font-medium text-white shadow-sm hover:shadow transition-all"
                        style={{ backgroundColor: primaryColor }}
                      >
                        Configure loyalty settings
                        <ArrowRight size={16} className="ml-2" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case 'marketing':
        return (
          <div className="py-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
              <div className="bg-white rounded-lg border border-gray-200 overflow-hidden hover:shadow-sm transition-all">
                <div className="px-6 py-5 border-b border-gray-100" style={{ backgroundColor: 'rgba(13, 64, 41, 0.03)' }}>
                  <h3 className="font-medium text-base" style={{ color: secondaryColor }}>Marketing Boosts</h3>
                </div>
                <div className="p-6">
                  <div className="flex items-start">
                    <div className="w-10 h-10 rounded-lg flex items-center justify-center mr-4" 
                      style={{ backgroundColor: 'rgba(13, 64, 41, 0.07)', color: secondaryColor }}>
                      <Zap size={20} />
                    </div>
                    <div>
                      <h4 className="text-base font-medium text-gray-900">Targeted Offers</h4>
                      <p className="text-sm text-gray-600 mt-1 mb-5 leading-relaxed">
                        Create time-limited promotions to drive specific customer behaviors:
                      </p>
                      <ul className="space-y-2 mb-5">
                        <li className="flex items-start">
                          <div className="w-5 h-5 rounded-full flex items-center justify-center mr-3 mt-0.5" 
                            style={{ backgroundColor: 'rgba(62, 180, 137, 0.15)' }}>
                            <div className="w-1.5 h-1.5 rounded-full" style={{ backgroundColor: primaryColor }}></div>
                          </div>
                          <span className="text-sm text-gray-700">Attract new customers</span>
                        </li>
                        <li className="flex items-start">
                          <div className="w-5 h-5 rounded-full flex items-center justify-center mr-3 mt-0.5" 
                            style={{ backgroundColor: 'rgba(62, 180, 137, 0.15)' }}>
                            <div className="w-1.5 h-1.5 rounded-full" style={{ backgroundColor: primaryColor }}></div>
                          </div>
                          <span className="text-sm text-gray-700">Re-engage inactive customers</span>
                        </li>
                        <li className="flex items-start">
                          <div className="w-5 h-5 rounded-full flex items-center justify-center mr-3 mt-0.5" 
                            style={{ backgroundColor: 'rgba(62, 180, 137, 0.15)' }}>
                            <div className="w-1.5 h-1.5 rounded-full" style={{ backgroundColor: primaryColor }}></div>
                          </div>
                          <span className="text-sm text-gray-700">Encourage higher spending</span>
                        </li>
                      </ul>
                      <a 
                        href="/marketing/boosts" 
                        className="inline-flex items-center px-4 py-2 rounded-md text-sm font-medium text-white shadow-sm hover:shadow transition-all"
                        style={{ backgroundColor: primaryColor }}
                      >
                        Create a boost
                        <ArrowRight size={16} className="ml-2" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="bg-white rounded-lg border border-gray-200 overflow-hidden hover:shadow-sm transition-all">
                <div className="px-6 py-5 border-b border-gray-100" style={{ backgroundColor: 'rgba(13, 64, 41, 0.03)' }}>
                  <h3 className="font-medium text-base" style={{ color: secondaryColor }}>Gift Cards</h3>
                </div>
                <div className="p-6">
                  <div className="flex items-start">
                    <div className="w-10 h-10 rounded-lg flex items-center justify-center mr-4" 
                      style={{ backgroundColor: 'rgba(13, 64, 41, 0.07)', color: secondaryColor }}>
                      <Gift size={20} />
                    </div>
                    <div>
                      <h4 className="text-base font-medium text-gray-900">Digital Gift Cards</h4>
                      <p className="text-sm text-gray-600 mt-1 mb-5 leading-relaxed">
                        Send digital gift cards with custom messages to bulk phone numbers. Recipients will see your gift 
                        permanently in their Clavaa app.
                      </p>
                      <div className="p-4 rounded-lg bg-gray-50 border border-gray-200 mb-5">
                        <p className="text-sm text-gray-700">
                          <span className="font-medium">Pro tip:</span> Send seasonal promotions like holiday, back-to-school, 
                          or summer specials to drive traffic to your store.
                        </p>
                      </div>
                      <a 
                        href="/marketing/gift-cards" 
                        className="inline-flex items-center px-4 py-2 rounded-md text-sm font-medium text-white shadow-sm hover:shadow transition-all"
                        style={{ backgroundColor: primaryColor }}
                      >
                        Send gift cards
                        <ArrowRight size={16} className="ml-2" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="bg-white rounded-lg border border-gray-200 overflow-hidden hover:shadow-sm transition-all">
              <div className="px-6 py-5 border-b border-gray-100" style={{ backgroundColor: 'rgba(13, 64, 41, 0.03)' }}>
                <h3 className="font-medium text-base" style={{ color: secondaryColor }}>In-Store Promotion</h3>
              </div>
              <div className="p-6">
                <div className="flex items-start">
                  <div className="w-10 h-10 rounded-lg flex items-center justify-center mr-4" 
                    style={{ backgroundColor: 'rgba(13, 64, 41, 0.07)', color: secondaryColor }}>
                    <Store size={20} />
                  </div>
                  <div className="flex-1">
                    <h4 className="text-base font-medium text-gray-900">Promotional Materials</h4>
                    <p className="text-sm text-gray-600 mt-1 mb-5 leading-relaxed">
                      Get professionally designed signage with QR codes that offer customers $5 for signing up to Clavaa in your store.
                    </p>
                    
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-5">
                      <div className="p-4 rounded-lg border border-gray-200 bg-gray-50">
                        <h5 className="text-sm font-medium mb-2" style={{ color: secondaryColor }}>Large Store Sign</h5>
                        <p className="text-xs text-gray-600">
                          Main promotional sign for high-visibility areas
                        </p>
                      </div>
                      <div className="p-4 rounded-lg border border-gray-200 bg-gray-50">
                        <h5 className="text-sm font-medium mb-2" style={{ color: secondaryColor }}>Small Countertop Sign</h5>
                        <p className="text-xs text-gray-600">
                          Perfect for checkout areas and service counters
                        </p>
                      </div>
                      <div className="p-4 rounded-lg border border-gray-200 bg-gray-50">
                        <h5 className="text-sm font-medium mb-2" style={{ color: secondaryColor }}>Window Sign</h5>
                        <p className="text-xs text-gray-600">
                          Attracts passersby with $5 signup bonus QR code
                        </p>
                      </div>
                    </div>
                    
                    <div className="p-4 rounded-lg border border-gray-200 bg-gray-50 mb-5">
                      <p className="text-sm text-gray-700">
                        <span className="font-medium" style={{ color: secondaryColor }}>Note:</span> Only the window sign includes a QR code that rewards customers with $5 to spend at your store when they sign up for Clavaa, helping drive new customer acquisition.
                      </p>
                    </div>
                    
                    <a 
                      href="/dashboard/material/order" 
                      className="inline-flex items-center px-4 py-2 rounded-md text-sm font-medium text-white shadow-sm hover:shadow transition-all"
                      style={{ backgroundColor: primaryColor }}
                    >
                      Order promotional materials
                      <ArrowRight size={16} className="ml-2" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case 'account':
        return (
          <div className="py-4">
            <div className="bg-white rounded-lg border border-gray-200 overflow-hidden hover:shadow-sm transition-all">
              <div className="px-6 py-5 border-b border-gray-100" style={{ backgroundColor: 'rgba(13, 64, 41, 0.03)' }}>
                <h3 className="font-medium text-base" style={{ color: secondaryColor }}>Business Information</h3>
              </div>
              <div className="p-6">
                <div className="flex items-start">
                  <div className="w-10 h-10 rounded-lg flex items-center justify-center mr-4" 
                    style={{ backgroundColor: 'rgba(13, 64, 41, 0.07)', color: secondaryColor }}>
                    <User size={20} />
                  </div>
                  <div className="flex-1">
                    <h4 className="text-base font-medium text-gray-900">Account Details</h4>
                    <p className="text-sm text-gray-600 mt-1 mb-5 leading-relaxed">
                      Manage your business information, payment details, and account settings.
                    </p>
                    
                    <div className="space-y-4 mb-5">
                      <div className="flex items-start">
                        <div className="w-5 h-5 rounded-full flex items-center justify-center mr-3 mt-0.5" 
                          style={{ backgroundColor: 'rgba(62, 180, 137, 0.15)' }}>
                          <div className="w-1.5 h-1.5 rounded-full" style={{ backgroundColor: primaryColor }}></div>
                        </div>
                        <div>
                          <span className="text-sm text-gray-800 font-medium">Business details</span>
                          <p className="text-xs text-gray-500 mt-0.5">Legal name, tax information, address</p>
                        </div>
                      </div>
                      <div className="flex items-start">
                        <div className="w-5 h-5 rounded-full flex items-center justify-center mr-3 mt-0.5" 
                          style={{ backgroundColor: 'rgba(62, 180, 137, 0.15)' }}>
                          <div className="w-1.5 h-1.5 rounded-full" style={{ backgroundColor: primaryColor }}></div>
                        </div>
                        <div>
                          <span className="text-sm text-gray-800 font-medium">Payment information</span>
                          <p className="text-xs text-gray-500 mt-0.5">Bank accounts, payout preferences</p>
                        </div>
                      </div>
                      <div className="flex items-start">
                        <div className="w-5 h-5 rounded-full flex items-center justify-center mr-3 mt-0.5" 
                          style={{ backgroundColor: 'rgba(62, 180, 137, 0.15)' }}>
                          <div className="w-1.5 h-1.5 rounded-full" style={{ backgroundColor: primaryColor }}></div>
                        </div>
                        <div>
                          <span className="text-sm text-gray-800 font-medium">Contact settings</span>
                          <p className="text-xs text-gray-500 mt-0.5">Email, phone, notification preferences</p>
                        </div>
                      </div>
                    </div>
                    
                    <a 
                      href="/dashboard/kyc" 
                      className="inline-flex items-center px-4 py-2 rounded-md text-sm font-medium text-white shadow-sm hover:shadow transition-all"
                      style={{ backgroundColor: primaryColor }}
                    >
                      Update business information
                      <ArrowRight size={16} className="ml-2" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="mt-6 bg-white rounded-lg border border-gray-200 overflow-hidden hover:shadow-sm transition-all">
              <div className="px-6 py-5 border-b border-gray-100" style={{ backgroundColor: 'rgba(13, 64, 41, 0.03)' }}>
                <h3 className="font-medium text-base" style={{ color: secondaryColor }}>Support</h3>
              </div>
              <div className="p-6">
                <div className="flex items-start">
                  <div className="w-10 h-10 rounded-lg flex items-center justify-center mr-4" 
                    style={{ backgroundColor: 'rgba(13, 64, 41, 0.07)', color: secondaryColor }}>
                    <HelpCircle size={20} />
                  </div>
                  <div>
                    <h4 className="text-base font-medium text-gray-900">Need assistance?</h4>
                    <p className="text-sm text-gray-600 mt-1 mb-4 leading-relaxed">
                      For any questions or help with your Clavaa account, contact your account admin 
                      or email our support team:
                    </p>
                    <a href="mailto:help@clavaa.com" className="inline-flex items-center px-4 py-2 rounded-md text-sm font-medium" 
                      style={{ color: primaryColor, backgroundColor: 'rgba(62, 180, 137, 0.1)' }}>
                      help@clavaa.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };
  
  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden">
      <div className="px-6 py-5" style={{ backgroundColor: secondaryColor }}>
        <h2 className="text-lg font-medium text-white">Your Clavaa Dashboard</h2>
        <p className="text-sm text-gray-200 mt-1">Manage your payment system, loyalty program, and marketing tools</p>
      </div>
      
      <div className="border-b border-gray-200">
        <div className="flex overflow-x-auto">
          {tabs.map(tab => (
            <button
              key={tab.id}
              className={`px-6 py-4 text-sm font-medium whitespace-nowrap transition-colors ${
                activeTab === tab.id 
                  ? 'border-b-2 text-gray-900'
                  : 'text-gray-500 hover:text-gray-700 hover:border-b hover:border-gray-300'
              }`}
              style={{ 
                borderColor: activeTab === tab.id ? primaryColor : '',
                color: activeTab === tab.id ? secondaryColor : ''
              }}
              onClick={() => setActiveTab(tab.id)}
            >
              {tab.label}
            </button>
          ))}
        </div>
      </div>
      
      <div className="p-6">
        <div className="mb-4 flex items-center">
          <div className="inline-flex items-center text-sm font-medium px-3 py-1 rounded-full" 
            style={{ backgroundColor: 'rgba(62, 180, 137, 0.1)', color: primaryColor }}>
            <span className="inline-block w-2 h-2 rounded-full mr-2" 
              style={{ backgroundColor: primaryColor }}></span>
            Account Active
          </div>
        </div>
        
        {renderTabContent()}
      </div>
    </div>
  );
};

export default DashboardGuide;