import React, { useState } from 'react';
import { Check, ArrowRight, ExternalLink } from 'lucide-react';

const OnboardingGuide = () => {
  const [steps, setSteps] = useState([
    {
      id: 1,
      title: "Business Information",
      description: "Complete your account information for legal purposes",
      path: "/dashboard/kyc",
      buttonText: "Complete business info",
      completed: false,
      expanded: true
    },
    {
      id: 2,
      title: "Location Management",
      description: "Add your store location to appear on our discover page",
      path: "/profile",
      buttonText: "Set up location",
      completed: false,
      expanded: false
    },
    {
      id: 3,
      title: "Order Store Products",
      description: "Get the hardware needed for in-store loyalty system",
      path: "/dashboard/material/order",
      buttonText: "Order equipment",
      completed: false,
      expanded: false
    },
    {
      id: 4,
      title: "POS Setup",
      description: "Set up your point-of-sale integration",
      path: "/pos/register",
      buttonText: "Set up POS",
      completed: false,
      expanded: false
    }
  ]);

  // Clavaa brand colors
  const primaryColor = '#3EB489';
  const secondaryColor = '#0D4029';
  const lightPrimaryColor = 'rgba(62, 180, 137, 0.08)';

  const toggleComplete = (id) => {
    setSteps(steps.map(step => 
      step.id === id ? { ...step, completed: !step.completed } : step
    ));
  };

  const toggleExpand = (id) => {
    setSteps(steps.map(step => 
      step.id === id ? { ...step, expanded: !step.expanded } : { ...step, expanded: false }
    ));
  };

  const getCompletedCount = () => {
    return steps.filter(step => step.completed).length;
  };

  const renderBulletPoint = (text, subtext = null) => (
    <div className="flex items-start mb-4">
      <div className="w-5 h-5 rounded-full flex items-center justify-center mr-3 mt-0.5" 
        style={{ backgroundColor: 'rgba(62, 180, 137, 0.15)' }}>
        <div className="w-1.5 h-1.5 rounded-full" style={{ backgroundColor: primaryColor }}></div>
      </div>
      <div>
        <span className="text-gray-800 font-medium text-sm">{text}</span>
        {subtext && <p className="text-gray-500 text-xs mt-0.5">{subtext}</p>}
      </div>
    </div>
  );

  const renderStepContent = (step) => {
    switch(step.id) {
      case 1:
        return (
          <div className="px-6 py-5">
            <p className="text-gray-600 text-sm mb-5">
              Complete your business information to ensure secure payment processing and platform safety.
            </p>
            
            <div className="mb-6">
              {renderBulletPoint("Business legal name and tax ID", "Required for payment processing")}
              {renderBulletPoint("Owner identification", "Secure account verification")}
              {renderBulletPoint("Banking information", "For receiving payments")}
            </div>
            
            <a 
              href="/dashboard/kyc" 
              className="inline-flex items-center px-5 py-2.5 rounded-md text-white font-medium transition-all"
              style={{ 
                backgroundColor: primaryColor,
                boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
              }}
            >
              {step.buttonText}
              <ArrowRight size={16} className="ml-2" />
            </a>
          </div>
        );
      case 2:
        return (
          <div className="px-6 py-5">
            <p className="text-gray-600 text-sm mb-5">
              Enter your store details to be featured in the Clavaa app's discover page and drive new customers to your business.
            </p>
            
            <div className="mb-6">
              {renderBulletPoint("Store name and address", "Help customers find you")}
              {renderBulletPoint("Store photos", "Showcase your business")}
              {renderBulletPoint("Contact information", "For customer inquiries")}
            </div>
            
            <div className="p-4 rounded-lg bg-gray-50 border border-gray-200 text-xs text-gray-600 mb-5">
              We'll verify your information promptly. If verification takes more than 24 hours, 
              contact <span className="font-medium">help@clavaa.com</span>.
            </div>
            
            <a 
              href="/profile" 
              className="inline-flex items-center px-5 py-2.5 rounded-md text-white font-medium transition-all"
              style={{ 
                backgroundColor: primaryColor,
                boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
              }}
            >
              {step.buttonText}
              <ArrowRight size={16} className="ml-2" />
            </a>
          </div>
        );
      case 3:
        return (
          <div className="px-6 py-5">
            <p className="text-gray-600 text-sm mb-5">
              Order the hardware needed for your in-store Clavaa loyalty and payment system.
            </p>
            
            <div className="p-4 mb-5 rounded-lg bg-gray-50 border border-gray-200">
              <div className="flex items-start mb-3">
                <div className="w-5 h-5 rounded-full flex items-center justify-center mr-3 mt-0.5" 
                  style={{ backgroundColor: 'rgba(62, 180, 137, 0.15)' }}>
                  <div className="w-1.5 h-1.5 rounded-full" style={{ backgroundColor: primaryColor }}></div>
                </div>
                <div>
                  <span className="text-gray-800 font-medium text-sm">Ordering options</span>
                  <p className="text-gray-500 text-xs mt-1">
                    You can order directly through Clavaa (much cheaper but may take up to a week for delivery) or
                    purchase the recommended products yourself from the links below.
                  </p>
                </div>
              </div>
            </div>
            
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mb-6">
              <div>
                <h4 className="font-medium text-sm mb-4" style={{ color: secondaryColor }}>Required Equipment</h4>
                <div>
                  {renderBulletPoint("Tablet for Clavaa POS app", "Runs the payment processing app")}
                  {renderBulletPoint("QR code scanner", "For customers to scan their app QR code to pay")}
                  {renderBulletPoint("Tablet holder", "Securely mounts your tablet")}
                  {renderBulletPoint("USB charging splitter", "Connects tablet and QR scanner - and to charge")}
                  {renderBulletPoint("Marketing signage", "In-store promotion for the loyalty app")}
                </div>
              </div>
              
              <div>
                <h4 className="font-medium text-sm mb-4" style={{ color: secondaryColor }}>Recommended Models</h4>
                <div className="space-y-4">
                  <a href="https://www.amazon.com/Lenovo-Tab-4th-Gen-Touchscreen/dp/B0CHYMGQ38/" 
                    className="flex items-center text-gray-600 hover:text-gray-900 group">
                    <div className="w-5 h-5 rounded-full flex items-center justify-center mr-3" 
                      style={{ backgroundColor: 'rgba(62, 180, 137, 0.15)' }}>
                      <div className="w-1.5 h-1.5 rounded-full" style={{ backgroundColor: primaryColor }}></div>
                    </div>
                    <div className="text-sm group-hover:text-gray-900 transition-colors">Lenovo Tab 4th Gen</div>
                    <ExternalLink size={12} className="ml-1.5 opacity-0 group-hover:opacity-100 transition-opacity" />
                  </a>
                  <a href="https://www.amazon.com/NetumScan-Automatic-Omnidirectional-Hands-Free-Supermarket/dp/B0BBFYYYL1/" 
                    className="flex items-center text-gray-600 hover:text-gray-900 group">
                    <div className="w-5 h-5 rounded-full flex items-center justify-center mr-3" 
                      style={{ backgroundColor: 'rgba(62, 180, 137, 0.15)' }}>
                      <div className="w-1.5 h-1.5 rounded-full" style={{ backgroundColor: primaryColor }}></div>
                    </div>
                    <div className="text-sm group-hover:text-gray-900 transition-colors">NetumScan QR Scanner</div>
                    <ExternalLink size={12} className="ml-1.5 opacity-0 group-hover:opacity-100 transition-opacity" />
                  </a>
                  <a href="https://www.amazon.com/LISEN-Adjustable-Portable-Monitor-Essentials/dp/B0BTDHQJ6X/" 
                    className="flex items-center text-gray-600 hover:text-gray-900 group">
                    <div className="w-5 h-5 rounded-full flex items-center justify-center mr-3" 
                      style={{ backgroundColor: 'rgba(62, 180, 137, 0.15)' }}>
                      <div className="w-1.5 h-1.5 rounded-full" style={{ backgroundColor: primaryColor }}></div>
                    </div>
                    <div className="text-sm group-hover:text-gray-900 transition-colors">LISEN Tablet Holder</div>
                    <ExternalLink size={12} className="ml-1.5 opacity-0 group-hover:opacity-100 transition-opacity" />
                  </a>
                  <a href="https://www.amazon.com/Adapter-Female-Charging-Splitter-Compatible/dp/B08KPD5S82/" 
                    className="flex items-center text-gray-600 hover:text-gray-900 group">
                    <div className="w-5 h-5 rounded-full flex items-center justify-center mr-3" 
                      style={{ backgroundColor: 'rgba(62, 180, 137, 0.15)' }}>
                      <div className="w-1.5 h-1.5 rounded-full" style={{ backgroundColor: primaryColor }}></div>
                    </div>
                    <div className="text-sm group-hover:text-gray-900 transition-colors">USB Charging Splitter</div>
                    <ExternalLink size={12} className="ml-1.5 opacity-0 group-hover:opacity-100 transition-opacity" />
                  </a>
                </div>
              </div>
            </div>
            
            <div className="flex justify-between">
             
              
              <a 
                href="/dashboard/material/order" 
                className="inline-flex items-center px-5 py-2.5 rounded-md text-white font-medium transition-all"
                style={{ 
                  backgroundColor: primaryColor,
                  boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
                }}
              >
                {step.buttonText}
                <ArrowRight size={16} className="ml-2" />
              </a>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="px-6 py-5">
            <p className="text-gray-600 text-sm mb-5">
              Set up your POS integration to start accepting Clavaa payments and build customer loyalty.
            </p>
            
            <div className="space-y-5 mb-6">
              <div>
                <div className="flex items-center mb-2">
                  <div className="flex-shrink-0 w-6 h-6 rounded-full flex items-center justify-center mr-3" 
                    style={{ backgroundColor: lightPrimaryColor, color: primaryColor }}>
                    <span className="text-xs font-medium">1</span>
                  </div>
                  <p className="text-sm font-medium" style={{ color: secondaryColor }}>Download the Clavaa POS app</p>
                </div>
                <div className="ml-9">
                  <div className="flex space-x-3 mb-3">
                    <a href="https://play.google.com/store/apps/details?id=com.yourcompany.posregister" 
                      className="inline-flex items-center px-3 py-1.5 rounded text-xs text-gray-700 border border-gray-200 hover:border-gray-300 transition-colors">
                      Android
                      <ExternalLink size={10} className="ml-1" />
                    </a>
                    <a href="https://apps.apple.com/us/app/clavaa-pos/id6741890660" 
                      className="inline-flex items-center px-3 py-1.5 rounded text-xs text-gray-700 border border-gray-200 hover:border-gray-300 transition-colors">
                      iOS
                      <ExternalLink size={10} className="ml-1" />
                    </a>
                  </div>
                </div>
              </div>
              
              <div>
                <div className="flex items-center mb-2">
                  <div className="flex-shrink-0 w-6 h-6 rounded-full flex items-center justify-center mr-3" 
                    style={{ backgroundColor: lightPrimaryColor, color: primaryColor }}>
                    <span className="text-xs font-medium">2</span>
                  </div>
                  <p className="text-sm font-medium" style={{ color: secondaryColor }}>Activate your account</p>
                </div>
                <div className="ml-9">
                  <p className="text-xs text-gray-600">Click the "Activate Account" button at the top of your dashboard</p>
                </div>
              </div>
              
              <div>
                <div className="flex items-center mb-2">
                  <div className="flex-shrink-0 w-6 h-6 rounded-full flex items-center justify-center mr-3" 
                    style={{ backgroundColor: lightPrimaryColor, color: primaryColor }}>
                    <span className="text-xs font-medium">3</span>
                  </div>
                  <p className="text-sm font-medium" style={{ color: secondaryColor }}>Log in to the POS add-on</p>
                </div>
                <div className="ml-9">
                  <p className="text-xs text-gray-600">Use your credentials from the POS Credentials tab</p>
                </div>
              </div>
              
              <div>
                <div className="flex items-center mb-2">
                  <div className="flex-shrink-0 w-6 h-6 rounded-full flex items-center justify-center mr-3" 
                    style={{ backgroundColor: lightPrimaryColor, color: primaryColor }}>
                    <span className="text-xs font-medium">4</span>
                  </div>
                  <p className="text-sm font-medium" style={{ color: secondaryColor }}>Set up payment type</p>
                </div>
                <div className="ml-9">
                  <p className="text-xs text-gray-600">Add "Clavaa" as a custom payment type in your existing POS system</p>
                </div>
              </div>
            </div>
            
            <div className="p-4 rounded-lg bg-gray-50 border border-gray-200 mb-5">
              <h4 className="text-sm font-medium mb-2" style={{ color: secondaryColor }}>How it works</h4>
              <ol className="ml-2 space-y-2 text-xs text-gray-600">
                <li className="flex items-start">
                  <span className="mr-2">1.</span>
                  <span>Ring up the customer as usual in your regular POS system</span>
                </li>
                <li className="flex items-start">
                  <span className="mr-2">2.</span>
                  <span>Enter the total amount into the Clavaa POS add-on</span>
                </li>
                <li className="flex items-start">
                  <span className="mr-2">3.</span>
                  <span>Customer scans the QR code with their Clavaa app</span>
                </li>
                <li className="flex items-start">
                  <span className="mr-2">4.</span>
                  <span>Once payment is confirmed in the Clavaa POS add-on, mark the transaction as complete in your regular POS using either the "Clavaa" custom payment type that you can add in all POS systems or "Cash"</span>
                </li>
              </ol>
              <p className="mt-3 text-xs text-gray-600">Once you get the hang of it, the process is quick and simple! If you'd like a direct integration with your POS system, contact your account admin to check if we already have one available.</p>
            </div>
            
            <a 
              href="/pos/register" 
              className="inline-flex items-center px-5 py-2.5 rounded-md text-white font-medium transition-all"
              style={{ 
                backgroundColor: primaryColor,
                boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
              }}
            >
              View POS credentials
              <ArrowRight size={16} className="ml-2" />
            </a>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-sm border border-gray-200">
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <div>
            <h2 className="text-xl font-medium" style={{ color: secondaryColor }}>Getting Started</h2>
            <p className="text-sm text-gray-500 mt-1">Complete these steps to start accepting Clavaa payments</p>
          </div>
          <div style={{ color: primaryColor }} className="text-sm font-medium">
            {getCompletedCount()}/{steps.length} completed
          </div>
        </div>

        {/* Progress bar */}
        <div className="h-1 bg-gray-100 rounded-full w-full mb-6 overflow-hidden">
          <div 
            className="h-full rounded-full transition-all duration-500 ease-out" 
            style={{ width: `${(getCompletedCount() / steps.length) * 100}%`, backgroundColor: primaryColor }} 
          />
        </div>

        <div className="space-y-3">
          {steps.map((step, index) => (
            <div key={step.id} 
              className={`border rounded-lg overflow-hidden transition-all duration-200 ${
                step.expanded ? 'border-gray-200' : 'border-gray-100'
              }`}
            >
              <div 
                className={`flex items-center p-4 cursor-pointer ${
                  step.expanded ? 'border-b border-gray-100' : ''
                }`}
                onClick={() => toggleExpand(step.id)}
              >
                <button
                  className="w-6 h-6 rounded-full mr-3 flex items-center justify-center cursor-pointer transition-colors"
                  style={{ 
                    backgroundColor: step.completed ? primaryColor : 'white',
                    border: !step.completed ? `1.5px solid ${step.expanded ? primaryColor : 'rgb(209, 213, 219)'}` : 'none'
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleComplete(step.id);
                  }}
                >
                  {step.completed && <Check size={14} className="text-white" />}
                </button>
                
                <div className="flex-1">
                  <p className={`font-medium ${step.completed ? 'text-gray-400 line-through' : 'text-gray-800'}`}>
                    {step.title}
                  </p>
                  {!step.expanded && (
                    <p className="text-xs text-gray-500 mt-0.5">{step.description}</p>
                  )}
                </div>
                
                <svg 
                  className={`h-5 w-5 text-gray-400 transform transition-transform ${step.expanded ? 'rotate-180' : ''}`}
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </div>
              
              {step.expanded && renderStepContent(step)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OnboardingGuide;