import { X, ArrowRight, AlertCircle, DollarSign, Calendar, Users, Clock } from 'lucide-react';

const boostTypes = [
    {
      id: 'new-customers',
      title: 'New Customer Welcome',
      description: 'Attract first-time customers to your store',
      icon: Users,
      audiences: ['never-shopped', 'high-spenders', 'frequent-visitors']
    },
    {
      id: 'win-back',
      title: 'Win-back Campaign',
      description: "Re-engage customers who haven't visited recently",
      icon: ArrowRight,
      audiences: ['inactive-30', 'inactive-60', 'inactive-90']
    },
    {
      id: 'loyalty-reward',
      title: 'Loyalty Reward',
      description: 'Thank your regular customers and encourage more visits',
      icon: DollarSign,
      audiences: ['regular', 'frequent', 'vip', 'gold']
    }
  ];

  const audienceOptions = {
    'never-shopped': {
      title: 'Never shopped',
      description: 'Customers who have never made a purchase'
    },

    'inactive-30': {
      title: 'Inactive for 30+ days',
      description: "Previous customers who haven't returned in a month"
    },
    'inactive-60': {
      title: 'Inactive for 60+ days',
      description: "Previous customers who haven't returned in two months"
    },
    'inactive-90': {
      title: 'Inactive for 90+ days',
      description: "Previous customers who haven't returned in three months"
    },
    'regular': {
      title: 'Regular customers',
      description: 'Shop at least once a month'
    },
    'frequent': {
      title: 'Frequent customers',
      description: 'Shop multiple times per month'
    },
    'vip': {
      title: 'VIP customers',
      description: 'Top 10% of your customers by spend'
    },
    'gold': {
      title: 'Gold members',
      description: 'Shoppers that are gold tier in your loyalty program'
    },
    'high-spenders': {
      title: 'High spenders',
      description: 'Customers who spend more than average but have not spent by your store'
    },
    'frequent-visitors': {
      title: 'Frequent visitors',
      description: 'Customers who visit stores often but have not visited yours'
    },

  };

    export { boostTypes, audienceOptions };