import React from "react";
import DashboardLayout from "sideBar/main";
import GiftDashboard from "./mainInside";



const GiftPage = ({
 
}) => {





  return (
    <>
    
<DashboardLayout>
<GiftDashboard />

</DashboardLayout>

    </>
  );
};

export default GiftPage;
