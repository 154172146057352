import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { selectAllItems } from 'features/cart/cartSelector';
import useFullFetchFeedback from 'functions/apiFetchers/fullPageLoaders';
import { ChevronLeft, Check, AlertCircle, Copy, CheckCircle2 } from 'lucide-react';

const OrderProcessingPage = () => {
  // Main state
  const [status, setStatus] = useState('processing'); // 'processing', 'success', 'failed'
  const [confirmationCode, setConfirmationCode] = useState(null);
  const [error, setError] = useState(null);
  const [progressWidth, setProgressWidth] = useState(25);
  const [cartItems, setCartItems] = useState([]);
  
  // Track if API request has been made
  const requestMade = useRef(false);
  const requestInProgress = useRef(false);
  
  // Services
  const allItems = useSelector(selectAllItems);
  const navigate = useNavigate();
  const location = useLocation();
  const fetchFeedback = useFullFetchFeedback();
  
  // Get session_id from URL
  const getSessionId = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('session_id');
  }, [location.search]);
  
  // Load cart items from localStorage - only once on mount
  useEffect(() => {
    const loadCart = () => {
      try {
        const savedCart = JSON.parse(localStorage.getItem('clavaaCart') || '{}');
        
        const items = Object.entries(savedCart)
          .map(([id, quantity]) => {
            const item = allItems.find(item => item.id.toString() === id);
            return item ? { ...item, quantity } : null;
          })
          .filter(Boolean);
        
        setCartItems(items);
      } catch (err) {
        console.error("Failed to load cart items", err);
      }
    };
    
    loadCart();
  }, [allItems]);
  
  // Animate progress bar when in processing state
  useEffect(() => {
    if (status === 'processing') {
      // Initial progress
      setProgressWidth(40);
      
      const timer = setTimeout(() => {
        setProgressWidth(65);
      }, 300);
      
      return () => clearTimeout(timer);
    }
  }, [status]);
  
  // Make API request - controlled by requestMade ref to prevent duplicates
  const checkOrderStatus = useCallback(async () => {
    // Prevent concurrent requests
    if (requestInProgress.current) return;
    requestInProgress.current = true;
    
    console.log("Making API request to check order status");
    
    const sessionId = getSessionId();
    if (!sessionId) {
      setError("Missing session information");
      setStatus('failed');
      requestInProgress.current = false;
      return;
    }
    
    const product_ids_and_quantity = cartItems.reduce((acc, item) => {
      acc[item.id] = item.quantity;
      return acc;
    }, {});
    
    try {
      // Show progress advancing
      setProgressWidth(80);
      
      await fetchFeedback({
        endpoint: 'merchant/order/place',
        requestType: 'POST',
        data: {
          product_ids_and_quantity,
          session_id: sessionId
        },
        onSuccess: (responseData) => {
          setProgressWidth(100);
          
          // Short delay to show completed progress
          setTimeout(() => {
            if (responseData.order_placed) {
              localStorage.setItem('clavaaCart', '{}');
              setCartItems([]);
              setStatus('success');
              setConfirmationCode(responseData.order_id);
            } else {
              setStatus('failed');
              setError(responseData.message || "Your payment could not be processed");
            }
            requestInProgress.current = false;
          }, 400);
        },
        onError: (error) => {
          console.error(error);
          setStatus('failed');
          setError("Failed to verify order status. Please contact support.");
          requestInProgress.current = false;
        },
      });
    } catch (err) {
      console.error(err);
      setStatus('failed');
      setError("An unexpected error occurred while processing your order");
      requestInProgress.current = false;
    }
  }, [fetchFeedback, getSessionId, cartItems]);
  
  // Trigger the API request once when component mounts or when manually reset
  useEffect(() => {
    // Small delay to allow component to fully mount
    const timer = setTimeout(() => {
      if (status === 'processing') {
        checkOrderStatus();
      }
    }, 300);
    
    return () => clearTimeout(timer);
  }, [status, checkOrderStatus]);
  
  // Handle navigation back to dashboard
  const handleBackToDashboard = () => {
    navigate('/dashboard');
  };
  
  // Handle retry payment - reset flags to allow one more request
  const handleRetry = () => {
    requestMade.current = false;
    setStatus('processing');
    setProgressWidth(25);
    setError(null);
  };
  
  // Processing state component
  const ProcessingState = () => (
    <div className="text-center">
      <div className="relative w-full max-w-sm mx-auto mb-12">
        <div className="w-full bg-gray-100 rounded-full h-1.5">
          <div 
            className="bg-gradient-to-r from-[#0D4029] to-[#3EB489] h-1.5 rounded-full transition-all duration-700 ease-out"
            style={{ width: `${progressWidth}%` }}
          ></div>
        </div>
      </div>
      
      <h3 className="text-xl font-medium text-gray-900 mb-4 tracking-tight">Processing</h3>
      <p className="text-gray-500 mb-8 max-w-xs mx-auto text-sm">
        Verifying payment and finalizing your order
      </p>
      
      <div className="flex justify-center items-center mt-6">
        <div className="loading-pulse flex space-x-1">
          <span className="w-2 h-2 bg-gradient-to-r from-[#0D4029] to-[#3EB489] rounded-full inline-block opacity-75" 
                style={{animation: 'pulse 1.4s infinite ease-in-out'}}></span>
          <span className="w-2 h-2 bg-gradient-to-r from-[#0D4029] to-[#3EB489] rounded-full inline-block opacity-75"
                style={{animation: 'pulse 1.4s infinite ease-in-out 0.2s'}}></span>
          <span className="w-2 h-2 bg-gradient-to-r from-[#0D4029] to-[#3EB489] rounded-full inline-block opacity-75"
                style={{animation: 'pulse 1.4s infinite ease-in-out 0.4s'}}></span>
        </div>
      </div>
      
      <style jsx>{`
        @keyframes pulse {
          0%, 100% {
            transform: scale(0.6);
            opacity: 0.3;
          }
          50% {
            transform: scale(1);
            opacity: 1;
          }
        }
      `}</style>
    </div>
  );
  
  // Success state component with copy functionality
  const SuccessState = () => {
    const [copied, setCopied] = useState(false);
    
    const copyOrderId = () => {
      if (confirmationCode) {
        navigator.clipboard.writeText(confirmationCode);
        setCopied(true);
        
        // Reset copied state after 2 seconds
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      }
    };
    
    return (
      <div className="text-center max-w-xs mx-auto">
        <div className="flex justify-center mb-8">
          <div className="w-14 h-14 bg-gradient-to-r from-[#0D4029] to-[#3EB489] rounded-full flex items-center justify-center shadow-sm">
            <Check className="w-7 h-7 text-white" />
          </div>
        </div>
        
        <h3 className="text-2xl font-medium text-gray-900 mb-2 tracking-tight">Payment Successful</h3>
        
        <div className="relative mb-2">
          <div 
            onClick={copyOrderId}
            className="inline-flex items-center gap-1.5 bg-gray-50 px-3 py-1.5 rounded-lg cursor-pointer hover:bg-gray-100 transition-colors relative"
          >
            <span className="font-mono font-medium text-gray-700 text-sm" style={{wordBreak: 'break-all'}}>{confirmationCode && confirmationCode.length > 12 ? `${confirmationCode.substring(0, 8)}...` : confirmationCode}</span>
            {copied ? 
              <CheckCircle2 className="w-3.5 h-3.5 text-[#3EB489]" /> : 
              <Copy className="w-3.5 h-3.5 text-gray-400" />
            }
          </div>
          
          {copied && (
            <div className="absolute left-1/2 transform -translate-x-1/2 -bottom-8 bg-gray-800 text-white text-xs py-1 px-2 rounded whitespace-nowrap">
              Copied to clipboard
            </div>
          )}
        </div>
        
        <p className="text-gray-400 mb-10 text-xs">
          We've sent a confirmation to your email
        </p>
        
        <div className="mt-4">
          <button
            onClick={handleBackToDashboard}
            className="w-full bg-[#0D4029] text-white py-3.5 rounded-lg hover:bg-[#0D4029]/90 transition-all transform active:scale-[0.98] text-sm font-medium"
          >
            Continue to Dashboard
          </button>
        </div>
      </div>
    );
  };
  
  // Failed state component
  const FailedState = () => (
    <div className="text-center max-w-xs mx-auto">
      <div className="flex justify-center mb-8">
        <div className="w-14 h-14 bg-red-50 rounded-full flex items-center justify-center shadow-sm">
          <AlertCircle className="w-7 h-7 text-red-500" />
        </div>
      </div>
      
      <h3 className="text-2xl font-medium text-gray-900 mb-3 tracking-tight">Payment Failed</h3>
      <p className="text-gray-500 mb-8 text-sm">
        {error || "Your payment could not be processed or has not been completed."}
      </p>
      
      <div className="flex flex-col gap-2">
        <button
          onClick={handleRetry}
          className="w-full bg-[#0D4029] text-white py-3.5 rounded-lg hover:bg-[#0D4029]/90 transition-all transform active:scale-[0.98] text-sm font-medium"
        >
          Try Again
        </button>
        <button
          onClick={handleBackToDashboard}
          className="w-full text-gray-500 py-3 hover:text-[#0D4029] transition-all text-sm"
        >
          Return to Dashboard
        </button>
      </div>
    </div>
  );
  
  return (
    <div className="min-h-screen" style={{ backgroundColor: '#fcfcfc' }}>
      {/* Sleek header bar */}
      <div className="h-1.5 w-full bg-gradient-to-r from-[#0D4029] to-[#3EB489]"></div>
      
      <div className="max-w-2xl mx-auto px-6 py-20">
        {/* Minimal header */}
        <div className="flex items-center justify-center relative mb-20">
          <button 
            onClick={handleBackToDashboard}
            className="absolute left-0 text-gray-400 hover:text-[#0D4029] transition-colors"
          >
            <ChevronLeft className="w-5 h-5" />
          </button>
          
          <div className="font-bold text-lg tracking-tight text-[#0D4029]">
            Clavaa
          </div>
        </div>

        {/* Main content with subtle shadow */}
        <div className="bg-white rounded-2xl overflow-hidden w-full py-10 px-2 shadow-sm">
          {status === 'processing' && <ProcessingState />}
          {status === 'success' && <SuccessState />}
          {status === 'failed' && <FailedState />}
        </div>
        
        {/* Minimal footer */}
        <div className="flex justify-center items-center gap-2 text-xs text-gray-300 mt-12">
          <span>© {new Date().getFullYear()} Clavaa</span>
          <span>•</span>
          <span>Secure checkout</span>
        </div>
      </div>
    </div>
  );
};

export default OrderProcessingPage;