import React, { useEffect, useState } from 'react';
import { ArrowRight, LogOut, AlertCircle } from 'lucide-react';
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";

const AlreadySignedInModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  
  // Clavaa brand colors
  const primaryColor = '#3EB489';
  const secondaryColor = '#0D4029';
  
  useEffect(() => {
    const checkAuthStatus = async () => {
      const auth = getAuth();
      
      const user = await new Promise((resolve) => {
        onAuthStateChanged(auth, (user) => {
          resolve(user);
        });
      });
      
      if (user) {
        setUserEmail(user.email || '');
        setIsOpen(true);
      }
    };
    
    checkAuthStatus();
  }, []);
  
  const handleSignOut = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      window.location.reload();
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };
  
  const navigateToDashboard = () => {
    window.location.href = "/get-started";
  };
  
  if (!isOpen) return null;
  
  return (
    <div className="fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg w-full max-w-md overflow-hidden shadow-xl">
        <div className="p-6 pb-2">
          <div className="flex space-x-4 mb-5">
            <div className="flex-shrink-0">
              <div className="w-10 h-10 rounded-full flex items-center justify-center" 
                style={{ backgroundColor: 'rgba(13, 64, 41, 0.1)' }}>
                <AlertCircle size={20} style={{ color: secondaryColor }} />
              </div>
            </div>
            <div>
              <h2 className="text-lg font-medium" style={{ color: secondaryColor }}>
                Already signed in
              </h2>
              <p className="text-gray-600 text-sm mt-1">
                {userEmail ? 
                  `You're signed in as ${userEmail}` : 
                  "You're signed in as account admin."}
              </p>
            </div>
          </div>
          
          <div className="py-2 text-sm text-gray-600">
            You're trying to access a sign-in page, but you already have an active session.
            Would you like to go to your dashboard or sign out?
          </div>
        </div>
        
        <div className="border-t border-gray-100 p-6 bg-gray-50">
          <div className="flex flex-col space-y-3">
            <button
              onClick={navigateToDashboard}
              className="w-full inline-flex items-center justify-center px-4 py-2.5 rounded-md text-sm font-medium border transition-colors"
              style={{ 
                backgroundColor: secondaryColor, 
                color: 'white',
                borderColor: secondaryColor,
                boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)'
              }}
            >
              Continue to dashboard
              <ArrowRight size={16} className="ml-2" />
            </button>
            
            <button
              onClick={handleSignOut}
              className="w-full inline-flex items-center justify-center px-4 py-2.5 rounded-md text-sm font-medium text-gray-700 bg-white border border-gray-300 hover:bg-gray-50 transition-colors"
              style={{ boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)' }}
            >
              Sign out
              <LogOut size={16} className="ml-2" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlreadySignedInModal;