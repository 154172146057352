import React from 'react';
import { motion } from 'framer-motion';
import { Plus, Minus } from 'lucide-react';
import { Card, Button, Badge } from '../ui';
export const CashbackReview = ({ cashback, handleChangeThreshold }) => {
    const items = [
        { name: "Regular Cashback", value: cashback.regular, type: "percentage" },
        { name: "Gold Cashback", value: cashback.gold, type: "percentage" },
        { name: "Gold Threshold", value: cashback.gold_required_spend, type: "currency" }
      ];
    
      return (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full mt-6">
          {items.map((item, index) => (
            <div 
              key={index}
              className="bg-white border border-gray-200 shadow-sm"
            >
              <div className="px-4 py-3 border-b border-gray-200">
                <h3 className="text-sm font-medium text-gray-900">
                  {item.name}
                </h3>
              </div>
    
              <div className="p-4 flex items-center justify-between">
                <span className="text-2xl font-semibold text-gray-900">
                  {item.type === "currency" ? `$${item.value}` : `${item.value}%`}
                </span>
                
                {item.type === "currency" && (
                  <div className="flex items-center gap-2">
                    <button 
                      onClick={() => handleChangeThreshold("decrement")}
                      className="p-1.5 hover:bg-gray-50 transition-colors"
                    >
                      <Minus className="w-4 h-4 text-gray-600" />
                    </button>
                    <button 
                      onClick={() => handleChangeThreshold("increment")}
                      className="p-1.5 hover:bg-gray-50 transition-colors"
                    >
                      <Plus className="w-4 h-4 text-gray-600" />
                    </button>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      );
    };