import { useCloudFetch } from "../../../config";

const BASE_URL = useCloudFetch
  ? "https://clavaa-api.com/api/"
  : "http://192.168.1.246:8000/api/";

export async function getData(endpoint, authKey) {
  const url = `${BASE_URL}${endpoint}`;

  const headers = {};

  if (authKey) {
    headers["Authorization"] = `Token ${authKey}`;
  }

  const response = await fetch(url, {
    method: "GET",
    headers: headers,
  });

  return response.json();
}
