import React, { useState } from 'react';
import { DollarSign, CalendarDays, Settings, BadgePercent, Crown, Target, ChevronRight, HelpCircle, Plus } from 'lucide-react';
import PageHeader from 'components/molecules/pageHeader/PageHeader';

const MerchantCashbackDashboard = ({ 
  onUpdateRates,
  onLearnMoreClick,
  periods = {
    current: {
      goldCashback: 7.5,
      regularCashback: 2.5,
      spendingThreshold: 2000
    },
    next: {
      goldCashback: 8.0,
      regularCashback: 3.0,
      spendingThreshold: 2500
    },
    pending: {
      goldCashback: 8.5,
      regularCashback: 3.5,
      spendingThreshold: 3000
    }
  }
}) => {
  const [activeTab, setActiveTab] = useState('current');

  const getCurrentPeriod = () => {
    const now = new Date();
    const month = now.getMonth();
    const periods = {
      0: { current: 'Jan 1 - Apr 30', next: 'May 1 - Aug 31' },
      1: { current: 'Jan 1 - Apr 30', next: 'May 1 - Aug 31' },
      2: { current: 'Jan 1 - Apr 30', next: 'May 1 - Aug 31' },
      3: { current: 'Jan 1 - Apr 30', next: 'May 1 - Aug 31' },
      4: { current: 'May 1 - Aug 31', next: 'Sep 1 - Dec 31' },
      5: { current: 'May 1 - Aug 31', next: 'Sep 1 - Dec 31' },
      6: { current: 'May 1 - Aug 31', next: 'Sep 1 - Dec 31' },
      7: { current: 'May 1 - Aug 31', next: 'Sep 1 - Dec 31' },
      8: { current: 'Sep 1 - Dec 31', next: 'Jan 1 - Apr 30' },
      9: { current: 'Sep 1 - Dec 31', next: 'Jan 1 - Apr 30' },
      10: { current: 'Sep 1 - Dec 31', next: 'Jan 1 - Apr 30' },
      11: { current: 'Sep 1 - Dec 31', next: 'Jan 1 - Apr 30' }
    };
    return periods[month];
  };

  const periodDates = getCurrentPeriod();
  
  const tabs = {
    current: {
      icon: DollarSign,
      label: "Current Period",
      badge: "Active",
      description: "Active rates & thresholds",
      details: `View your active loyalty rates for the current period (${periodDates.current}). These rates determine customer earnings.`,
      learnMoreText: "Understanding Current Period Rates"
    },
    next: {
      icon: CalendarDays,
      label: "Next Period",
      badge: periodDates.next,
      description: "Preview upcoming changes",
      details: `Preview confirmed rates for next period. These rates will become active on ${periodDates.next.split(' - ')[0]}.`,
      learnMoreText: "How Rate Transitions Work"
    },
    pending: {
      icon: Settings,
      label: "Pending Updates",
      badge: "Draft",
      description: "Configure future rates",
      details: "Configure and review rate changes before they're locked in for the next period.",
      learnMoreText: "Rate Update Guidelines"
    }
  };

  // Rest of the component code stays the same...
  
  const Tab = ({ id }) => {
    const isActive = activeTab === id;
    const tab = tabs[id];
    
    return (
      <button
        onClick={() => setActiveTab(id)}
        className={`
          group relative flex-1 px-2 md:px-5 py-3.5
          transition-all duration-200 ease-out
          ${isActive ? 'z-10' : ''}
        `}
      >
        <div className="relative flex items-start gap-2 md:gap-3">
          <div className={`
            p-1.5 rounded-md transition-colors duration-200 mt-0.5
            ${isActive ? 'bg-[#0D4029]/10' : 'bg-transparent group-hover:bg-[#0D4029]/5'}
          `}>
            <tab.icon className={`
              w-3.5 h-3.5 transition-colors duration-200
              ${isActive ? 'text-[#0D4029]' : 'text-gray-400 group-hover:text-[#0D4029]'}
            `} />
          </div>

          <div className="flex-1 text-left">
            <div className="flex items-center justify-between">
              <span className={`
                text-xs md:text-sm font-medium tracking-tight transition-colors duration-200
                ${isActive ? 'text-[#0D4029]' : 'text-gray-600 group-hover:text-[#0D4029]'}
              `}>
                {tab.label}
              </span>
              <span className={`
                text-[10px] font-medium px-1.5 py-0.5 rounded-full transition-colors duration-200
                ${isActive 
                  ? 'bg-[#0D4029] text-white' 
                  : 'bg-gray-50 text-gray-500 group-hover:bg-[#0D4029]/5 group-hover:text-[#0D4029]'
                }
              `}>
                {tab.badge}
              </span>
            </div>
            <div className="hidden md:flex items-center gap-1.5 mt-0.5">
              <p className={`
                text-[11px] transition-colors duration-200
                ${isActive ? 'text-gray-600' : 'text-gray-400 group-hover:text-gray-500'}
              `}>
                {tab.description}
              </p>
              <ChevronRight className={`
                w-3 h-3 transition-all duration-200
                ${isActive 
                  ? 'text-[#0D4029] translate-x-0.5 opacity-100' 
                  : 'text-gray-300 -translate-x-1 opacity-0 group-hover:translate-x-0 group-hover:opacity-100'
                }
              `} />
            </div>
          </div>
        </div>

        <div className={`
          absolute bottom-0 left-0 right-0 h-0.5
          bg-gradient-to-r from-[#0D4029]/0 via-[#0D4029] to-[#0D4029]/0
          transition-opacity duration-200
          ${isActive ? 'opacity-100' : 'opacity-0 group-hover:opacity-20'}
        `} />
      </button>
    );
  };

  const RateCard = ({ 
    icon: Icon, 
    label, 
    value, 
    unit = '%', 
    helperText, 
    status
  }) => (
    <div className="group relative">
      <div className="relative h-52 bg-white rounded-xl border border-[#0D4029]/10 overflow-hidden transition-all duration-300 hover:shadow-lg hover:border-[#0D4029]/20">
        <div className="absolute inset-x-0 top-0 h-[2px] bg-gradient-to-r from-[#0D4029]/0 via-[#0D4029]/30 to-[#0D4029]/0 transform -translate-x-full group-hover:translate-x-0 transition-transform duration-1000" />
        
        <div className="h-full p-4 md:p-6 flex flex-col justify-between">
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-3">
                <div className="p-2 rounded-lg bg-[#0D4029]/5 transform transition-transform duration-300 group-hover:scale-110 group-hover:rotate-3">
                  <Icon className="w-5 h-5 text-[#0D4029]" />
                </div>
                {status && (
                  <div className="hidden md:flex items-center space-x-2 opacity-0 group-hover:opacity-100 transition-all duration-300 delay-100">
                    <div className="w-1.5 h-1.5 rounded-full bg-[#0D4029] animate-pulse" />
                    <span className="text-xs font-medium text-[#0D4029]/70">{status}</span>
                  </div>
                )}
              </div>
            </div>

            <div>
              <div className="flex items-baseline space-x-1 mb-2 transform transition-all duration-300 group-hover:translate-x-1">
                <span className="text-2xl md:text-3xl font-bold text-[#0D4029] tracking-tight">
                  {value.toLocaleString()}
                </span>
                <span className="text-sm font-medium text-[#0D4029]/60">{unit}</span>
              </div>
              <span className="text-sm font-medium text-[#0D4029]/80 block">
                {label}
              </span>
            </div>
          </div>

          {helperText && (
            <div className="pt-4 border-t border-[#0D4029]/5">
              <p className="text-xs text-[#0D4029]/60 leading-relaxed min-h-[2.5rem] line-clamp-2">
                {helperText}
              </p>
            </div>
          )}
        </div>

        <div className="absolute inset-0 bg-gradient-to-br from-[#0D4029]/[0.02] via-transparent to-[#0D4029]/[0.02] opacity-0 group-hover:opacity-100 transition-all duration-500" />
      </div>
    </div>
  );

  return (
    <div className="min-h-screen">
      <PageHeader 
        title="Cashback Rate Management"
        description="View and manage your store's loyalty program rates across different time periods."
        action={
            <button
              onClick={onUpdateRates}
              className="inline-flex items-center px-4 py-2 bg-[#0D4029] text-white rounded-lg hover:bg-[#0D4029]/90 transition-colors"
            >
              Update Pending Cashback
              <Plus className="w-4 h-4 ml-2" />
            </button>
          }
      />
      
      <div className="max-w-screen-2xl mx-auto">
        <div className="border-b">
          <div className="flex -mb-px px-2 md:px-8">
            {Object.keys(tabs).map(id => (
              <Tab key={id} id={id} />
            ))}
          </div>
        </div>
        
        <div className="px-4 md:px-8 py-6 md:py-8">
          <div className="mb-6 md:mb-8">
            <p className="text-xs md:text-sm text-gray-600">{tabs[activeTab].details}</p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6 mb-6 md:mb-8">
            <RateCard
              icon={Crown}
              label="Gold Status Cashback"
              value={periods[activeTab].goldCashback}
              status={
                activeTab === 'pending'
                  ? 'Configurable'
                  : activeTab === 'current'
                  ? 'Customers Earning'
                  : activeTab === 'next'
                  ? 'Customers See It'
                  : null
              }
              helperText="Enhanced rewards rate for Gold Status members. This rate applies to all qualifying purchases."
            />
            <RateCard
              icon={BadgePercent}
              label="Regular Cashback"
              value={periods[activeTab].regularCashback}
              status={
                activeTab === 'pending'
                  ? 'Configurable'
                  : activeTab === 'current'
                  ? 'Customers Earning'
                  : activeTab === 'next'
                  ? 'Customers See It'
                  : null
              }
              helperText="Base rewards rate for all customers. Automatically applied to every purchase."
            />
            <RateCard
              icon={Target}
              label="Gold Status Threshold"
              value={periods[activeTab].spendingThreshold}
              unit="$"
              status={
                activeTab === 'pending'
                  ? 'Configurable'
                  : activeTab === 'current'
                  ? 'Customers Earning'
                  : activeTab === 'next'
                  ? 'Customers See It'
                  : null
              }
              helperText="Quarterly spending requirement to achieve and maintain Gold Status benefits."
            />
          </div>

          <div className="flex flex-col md:flex-row items-center justify-between gap-4 pt-6 border-t">
            <button 
              onClick={() => onLearnMoreClick(activeTab)}
              className="flex items-center gap-2 text-sm font-medium text-[#0D4029] hover:text-[#0D4029]/80 transition-colors"
            >
              <HelpCircle className="w-4 h-4" />
              {tabs[activeTab].learnMoreText}
            </button>

            {activeTab === 'pending' && (
              <button 
                onClick={onUpdateRates}
                className="w-full md:w-auto px-4 py-2 bg-[#0D4029] text-white text-sm font-medium rounded-lg hover:bg-[#0D4029]/90 transition-colors"
              >
                Update Pending Cashback

              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MerchantCashbackDashboard;