import React, { useState, useCallback, useEffect } from 'react';
import { Plus, AlertCircle } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import PageHeader from 'pages/locationNew/header';
import GiftCardCampaign from './giftCampaigns';
import NoGiftsState from './noGiftsState';
import CreateGiftsModal from './sendGiftModal';
import GiftsExplanationModal from './explainedModal';
import LoadingState from './LoadingState';  // Import the new loading component

import useFullFetchFeedback from 'functions/apiFetchers/fullPageLoaders';
import { selectGiftCards, selectWasFetched } from 'features/giftCards/selector';
import { setGiftCards, setWasFetched } from 'features/giftCards/slice';

const GiftCardDashboard = () => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isExplainedModalOpen, setIsExplainedModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);  // Add loading state

  const dispatch = useDispatch();
  const fetchFeedback = useFullFetchFeedback();
  const giftCards = useSelector(selectGiftCards);
  console.log(giftCards);
  const wasFetched = useSelector(selectWasFetched);

  const loadGiftCards = async () => {
    if (wasFetched) {
      setIsLoading(false);
      return;
    }
    try {
      await fetchFeedback({
        endpoint: 'merchant/gift-cards/get/',
        requestType: 'GET',
        onSuccess: (response) => {
          dispatch(setGiftCards(response));
          dispatch(setWasFetched(true));
        },
        onError: (error) => {
          console.log('Error loading gift card:', error);
        },
      });
    } catch (error) {
      console.log('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadGiftCards();
  }, []);

  if (isLoading) {
    return <LoadingState />;
  }

  return (
    <>
      <div className="min-h-screen flex flex-col">
        <PageHeader
          title="Gift Cards"
          description="Create and manage digital gift cards for your customers"
          action={
            <button
              onClick={() => setIsCreateModalOpen(true)}
              className="inline-flex items-center px-4 py-2 bg-[#0D4029] text-white rounded-lg hover:bg-[#0D4029]/90 transition-colors"
            >
              Create Gift Card
              <Plus className="w-4 h-4 ml-2" />
            </button>
          }
        />

        <div className="px-6 max-w-[1600px] mx-auto w-full flex-1">
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 mb-8">
            {giftCards.map((campaign) => (
              <GiftCardCampaign
                key={campaign.id}
                campaign={campaign}
              />
            ))}
          </div>

          {!giftCards?.length && (
            <NoGiftsState onCreateClick={() => setIsCreateModalOpen(true)} />
          )}

          <div className="mt-auto border-t border-gray-200 pt-6 pb-8">
            <button 
              className="text-sm text-gray-600 hover:text-gray-900 transition-colors flex items-center gap-2"
              onClick={() => setIsExplainedModalOpen(true)}
            >
              <AlertCircle className="w-4 h-4" />
              Learn about gift cards
            </button>
          </div>
        </div>
      </div>

      {isCreateModalOpen && (
        <CreateGiftsModal
          isOpen={isCreateModalOpen}
          onClose={() => setIsCreateModalOpen(false)}
        />
      )}

      {isExplainedModalOpen && (
        <GiftsExplanationModal
          isOpen={isExplainedModalOpen}
          onClose={() => setIsExplainedModalOpen(false)}
        />
      )}
    </>
  );
};

export default GiftCardDashboard;