import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  giftCards: [
    {
      id: '1',
      title: 'Holiday Gift Card',
      senderName: 'Coffee Shop Inc.',
      businessName: 'Coffee Shop Inc.',
      amount: 50,
      date: '2024-12-25',
      colorIndex: 0,
      campaignName: 'Christmas Marketing 2024',
      recipientCount: 500,
    },
    {
      id: '2',
      title: 'Welcome Bonus',
      senderName: 'Coffee Shop Inc.',
      businessName: 'Coffee Shop Inc.',
      amount: 25,
      date: '2024-01-01',
      colorIndex: 1,
      campaignName: 'New Year Campaign 2024',
      recipientCount: 200,
    }
  ],
  wasFetched: false
};

const giftCardsSlice = createSlice({
  name: 'giftCards',
  initialState,
  reducers: {
    setGiftCards: (state, action) => {
      state.giftCards = action.payload;
    },
    addGiftCard: (state, action) => {
      state.giftCards.push(action.payload);
    },
    updateGiftCard: (state, action) => {
      const { id, updatedData } = action.payload;
      const index = state.giftCards.findIndex((giftCard) => giftCard.id === id);
      if (index !== -1) {
        state.giftCards[index] = { ...state.giftCards[index], ...updatedData };
      }
    },
    removeGiftCard: (state, action) => {
      const id = action.payload;
      state.giftCards = state.giftCards.filter((giftCard) => giftCard.id !== id);
    },
    setWasFetched: (state, action) => {
      state.wasFetched = action.payload;
    },
  }
});

export const { 
  setGiftCards, 
  addGiftCard, 
  updateGiftCard, 
  removeGiftCard,
  setWasFetched
} = giftCardsSlice.actions;

export default giftCardsSlice.reducer;