import { useCloudFetch } from "../../../config";

const BASE_URL = useCloudFetch
  ? "https://clavaa-api.com/api/"
  : "http://192.168.1.246:8000/api/";

export async function postData(endpoint, data, authKey) {
  const url = `${BASE_URL}${endpoint}`;

  const headers = {
    "Content-Type": "application/json",
  };

  if (authKey) {
    headers["Authorization"] = `Token ${authKey}`;
  }
  const response = await fetch(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
  });

  return response.json();
}
