import React, { useState, useEffect } from 'react';
import { X, DollarSign, Check, Loader, MessageSquare, Video, Mic, AlertCircle } from 'lucide-react';
import { useDispatch } from 'react-redux';
import useFullFetchFeedback from 'functions/apiFetchers/fullPageLoaders';
import { addGiftCard } from 'features/giftCards/slice';

const Dialog = ({ open, onOpenChange, children }) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [open]);

  if (!open) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div 
        className="absolute inset-0 bg-black bg-opacity-50 transition-opacity"
        onClick={() => onOpenChange(false)}
      />
      <div className="relative">
        {children}
      </div>
    </div>
  );
};

const DialogContent = ({ children }) => {
  return (
    <div className="bg-white rounded-xl shadow-2xl w-[500px] max-w-[95vw] max-h-[90vh] p-6 relative overflow-y-auto">
      {children}
    </div>
  );
};

const CreateGiftCardModal = ({ isOpen, onClose }) => {
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const fetchFeedback = useFullFetchFeedback();
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    campaignName: '',
    cardTitle: '',
    amount: '',
    recipients: [],
    inputValue: '',
    bulkInput: '',
    useEmail: true,
    emails: [],
    emailInput: '',
    bulkEmails: '',
    mediaType: 'Text',
    attachmentContent: '',
    attachmentDuration: 0,
    colorIndex: Math.floor(Math.random() * 13) // 0 to 12
  });
  const addGift = (gift) => ({ type: 'ADD_GIFT', payload: gift });

  
  const dispatch = useDispatch();

  const handleNext = () => {
    setError('');
    setStep(step + 1);
  };

  const handleBack = () => {
    setError('');
    setStep(step - 1);
  };
// Validation functions
const isValidEmail = (email) => {
  // Your existing email validation logic
  return /\S+@\S+\.\S+/.test(email);
};

const isValidPhoneNumber = (phone) => {
  // US phone validation - exactly 10 digits
  const digitsOnly = phone.replace(/\D/g, '');
  return digitsOnly.length === 10;
};

// Format US phone number for display
const formatPhoneNumber = (phone) => {
  // Remove all non-numeric characters
  const digitsOnly = phone.replace(/\D/g, '');
  
  // Format as (XXX) XXX-XXXX
  if (digitsOnly.length === 10) {
    return `(${digitsOnly.slice(0, 3)}) ${digitsOnly.slice(3, 6)}-${digitsOnly.slice(6)}`;
  }
  
  // Return original if we can't format it
  return phone;
};

// Format input as user types (for real-time formatting)
const formatPhoneNumberInput = (input) => {
  const digitsOnly = input.replace(/\D/g, '');
  let formattedNumber = '';
  
  if (digitsOnly.length <= 3) {
    // (123
    formattedNumber = digitsOnly.length ? `(${digitsOnly}` : '';
  } else if (digitsOnly.length <= 6) {
    // (123) 456
    formattedNumber = `(${digitsOnly.slice(0, 3)}) ${digitsOnly.slice(3)}`;
  } else {
    // (123) 456-7890
    formattedNumber = `(${digitsOnly.slice(0, 3)}) ${digitsOnly.slice(3, 6)}-${digitsOnly.slice(6, 10)}`;
  }
  
  return formattedNumber;
};

const isValidBulkEmails = (bulkText) => {
  if (!bulkText.trim()) return false;
  const emails = bulkText.split(',').map(e => e.trim());
  return emails.some(email => isValidEmail(email));
};

const isValidBulkPhoneNumbers = (bulkText) => {
  if (!bulkText.trim()) return false;
  const phones = bulkText.split(',').map(p => p.trim());
  return phones.some(phone => isValidPhoneNumber(phone));
};

// Handler functions
const handleAddRecipient = () => {
  const value = formData.inputValue.trim();
  if (!value) return;
  
  // Format phone numbers for better readability when storing
  const formattedValue = !formData.useEmail ? formatPhoneNumber(value) : value;
  
  // Check if this exact value or a formatted version already exists
  if (!formData.recipients.some(r => 
    r === formattedValue || 
    (!formData.useEmail && r.replace(/\D/g, '') === value.replace(/\D/g, ''))
  )) {
    setFormData({
      ...formData,
      recipients: [...formData.recipients, formattedValue],
      inputValue: ''
    });
  }
};

const handleRemoveRecipient = (recipient) => {
  setFormData({
    ...formData,
    recipients: formData.recipients.filter(r => r !== recipient)
  });
};

const handleBulkAdd = () => {
  // Split by commas and process each value
  const processedValues = formData.bulkInput
    .split(',')
    .map(value => value.trim())
    .filter(value => {
      // Keep only valid values
      return value && (formData.useEmail ? isValidEmail(value) : isValidPhoneNumber(value));
    })
    .map(value => {
      // Format phone numbers for better readability (US format only)
      return !formData.useEmail ? formatPhoneNumber(value) : value;
    })
    .filter(formattedValue => {
      // Filter out values that already exist in recipients
      // For phone numbers, compare digits only to avoid format differences
      if (formData.useEmail) {
        return !formData.recipients.includes(formattedValue);
      } else {
        const digitOnly = formattedValue.replace(/\D/g, '');
        return !formData.recipients.some(r => r.replace(/\D/g, '') === digitOnly);
      }
    });
  
  if (processedValues.length > 0) {
    setFormData({
      ...formData,
      recipients: [...formData.recipients, ...processedValues],
      bulkInput: ''
    });
  }
};


  const handleSubmit = async () => {
    setIsLoading(true);
    setError('');

    try {
      await fetchFeedback({
        endpoint: 'merchant/gift-cards/create/',
        requestType: 'POST',
        data: {
          ...formData,
          colorIndex: formData.colorIndex,
          mediaType: formData.mediaType,
          attachmentContent: formData.attachmentContent,
    

        },
        onSuccess: (responseData) => {
          console.log('Gift card created successfully:', responseData);
          dispatch(addGiftCard(responseData));
          setIsSuccess(true);
          setIsLoading(false);
        },
        onError: (error) => {
          console.error('Error creating gift card:', error);
          setError(error.message || 'Failed to create gift cards. Please try again.');
          setIsLoading(false);
        },
      });
    } catch (err) {
      setError('An unexpected error occurred. Please try again.');
      setIsLoading(false);
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="space-y-6">
            <div className="mb-6">
              <h2 className="text-lg font-semibold text-gray-900">Campaign Name</h2>
              <p className="text-sm text-gray-500">Enter a name for your records</p>
            </div>
            <div>
              <input
                type="text"
                className="px-4 py-2 w-full border border-gray-200 rounded-lg focus:ring-1 focus:ring-[#0D4029] focus:border-[#0D4029]"
                placeholder="e.g., Christmas Campaign 2025"
                value={formData.campaignName}
                onChange={(e) => setFormData({ ...formData, campaignName: e.target.value })}
              />
            </div>
          </div>
        );

      case 2:
        return (
          <div className="space-y-6">
            <div className="mb-6">
              <h2 className="text-lg font-semibold text-gray-900">Gift Card Title</h2>
              <p className="text-sm text-gray-500">This will appear on the gift card</p>
            </div>
            <div>
              <input
                type="text"
                className="px-4 py-2 w-full border border-gray-200 rounded-lg focus:ring-1 focus:ring-[#0D4029] focus:border-[#0D4029]"
                placeholder="e.g., Happy Holidays!"
                value={formData.cardTitle}
                onChange={(e) => setFormData({ ...formData, cardTitle: e.target.value })}
              />
            </div>
          </div>
        );

      case 3:
        return (
          <div className="space-y-6">
            <div className="mb-6">
              <h2 className="text-lg font-semibold text-gray-900">Gift Card Amount</h2>
              <p className="text-sm text-gray-500">Enter the value for each gift card</p>
            </div>
            <div className="relative">
              <DollarSign className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
              <input
                type="number"
                className="pl-10 pr-4 py-2 w-full border border-gray-200 rounded-lg focus:ring-1 focus:ring-[#0D4029] focus:border-[#0D4029]"
                placeholder="0.00"
                value={formData.amount}
                onChange={(e) => setFormData({ ...formData, amount: e.target.value })}
              />
            </div>
          </div>
        );

 // Add this validation function at the top


 case 4:
  return (
    <div className="space-y-6">
      <div className="mb-6">
        <h2 className="text-lg font-semibold text-gray-900">Add Recipients</h2>
        <p className="text-sm text-gray-500">Enter valid phone numbers or email addresses</p>
      </div>

      {/* Toggle between phone and email */}
      <div className="flex items-center space-x-4 mb-4">
        <label className="flex items-center cursor-pointer">
          <input
            type="radio"
            className="form-radio text-[#0D4029]"
            name="inputType"
            checked={!formData.useEmail}
            onChange={() => setFormData({ ...formData, useEmail: false })}
          />
          <span className="ml-2 text-sm">Phone Number</span>
        </label>
        <label className="flex items-center cursor-pointer">
          <input
            type="radio"
            className="form-radio text-[#0D4029]"
            name="inputType"
            checked={formData.useEmail}
            onChange={() => setFormData({ ...formData, useEmail: true })}
          />
          <span className="ml-2 text-sm">Email</span>
        </label>
      </div>

      {/* Single input (email or phone) */}
      <div className="space-y-1">
        <div className="flex gap-2">
          <input
            type={formData.useEmail ? "email" : "tel"}
            className="flex-1 px-4 py-2 border border-gray-200 rounded-lg focus:ring-1 focus:ring-[#0D4029] focus:border-[#0D4029]"
            placeholder={formData.useEmail ? "Enter a valid email address" : "Enter a valid US phone number"}
            value={formData.inputValue}
            onChange={(e) => {
              // Format phone numbers as the user types
              if (!formData.useEmail) {
                setFormData({ ...formData, inputValue: formatPhoneNumberInput(e.target.value) });
              } else {
                setFormData({ ...formData, inputValue: e.target.value });
              }
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                if (formData.useEmail && isValidEmail(formData.inputValue)) {
                  handleAddRecipient();
                } else if (!formData.useEmail && isValidPhoneNumber(formData.inputValue)) {
                  handleAddRecipient();
                }
              }
            }}
          />
          <button
            onClick={() => {
              if (formData.useEmail && isValidEmail(formData.inputValue)) {
                handleAddRecipient();
              } else if (!formData.useEmail && isValidPhoneNumber(formData.inputValue)) {
                handleAddRecipient();
              }
            }}
            disabled={formData.useEmail ? !isValidEmail(formData.inputValue) : !isValidPhoneNumber(formData.inputValue)}
            className={`px-4 py-2 rounded-lg ${
              (formData.useEmail && isValidEmail(formData.inputValue)) || 
              (!formData.useEmail && isValidPhoneNumber(formData.inputValue))
                ? 'bg-[#0D4029] text-white hover:bg-[#0D4029]/90'
                : 'bg-gray-300 text-gray-500 cursor-not-allowed'
            }`}
          >
            Add
          </button>
        </div>
      </div>

      {/* Bulk input */}
      <div className="space-y-2">
        <textarea
          className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:ring-1 focus:ring-[#0D4029] focus:border-[#0D4029]"
          placeholder={formData.useEmail 
            ? "Paste multiple emails separated by commas" 
            : "Paste multiple phone numbers separated by commas"}
          rows={3}
          value={formData.bulkInput}
          onChange={(e) => setFormData({ ...formData, bulkInput: e.target.value })}
        />
        <button
          onClick={handleBulkAdd}
          disabled={formData.useEmail 
            ? !isValidBulkEmails(formData.bulkInput)
            : !isValidBulkPhoneNumbers(formData.bulkInput)}
          className={`w-full px-4 py-2 rounded-lg ${
            (formData.useEmail && isValidBulkEmails(formData.bulkInput)) ||
            (!formData.useEmail && isValidBulkPhoneNumbers(formData.bulkInput))
              ? 'bg-[#0D4029] text-white hover:bg-[#0D4029]/90'
              : 'bg-gray-300 text-gray-500 cursor-not-allowed'
          }`}
        >
          Add Bulk {formData.useEmail ? "Emails" : "Phone Numbers"}
        </button>
      </div>

      {/* Recipients list */}
      <div className="mt-4">
        <h3 className="text-sm font-medium text-gray-700 mb-2">
          Recipients ({formData.recipients.length})
        </h3>
        <div className="max-h-64 overflow-y-auto">
          <div className="space-y-2">
            {formData.recipients.map((recipient) => (
              <div
                key={recipient}
                className="flex items-center justify-between p-2 bg-gray-50 rounded-lg"
              >
                <div className="flex items-center">
                  {/* Show different icon based on recipient type */}
                  {recipient.includes('@') ? (
                    <span className="mr-2 text-gray-400">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                        <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                      </svg>
                    </span>
                  ) : (
                    <span className="mr-2 text-gray-400">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                      </svg>
                    </span>
                  )}
                  <span className="text-sm text-gray-600">{recipient}</span>
                </div>
                <button
                  onClick={() => handleRemoveRecipient(recipient)}
                  className="text-gray-400 hover:text-gray-600"
                >
                  <X className="w-4 h-4" />
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );

      case 5:
        return (
          <div className="space-y-6">
            <div className="mb-6">
              <h2 className="text-lg font-semibold text-gray-900">Add a Personal Touch</h2>
              <p className="text-sm text-gray-500">Choose how you want to personalize your gift cards</p>
            </div>

            <div className="grid grid-cols-3 gap-4">
              <button
                onClick={() => setFormData({ ...formData, mediaType: 'Text' })}
                className={`p-4 rounded-lg border ${
                  formData.mediaType === 'Text'
                    ? 'border-[#0D4029] bg-[#0D4029]/5'
                    : 'border-gray-200'
                } flex flex-col items-center gap-2`}
              >
                <MessageSquare className={`w-6 h-6 ${
                  formData.mediaType === 'Text' ? 'text-[#0D4029]' : 'text-gray-400'
                }`} />
                <span className="text-sm font-medium">Text Message</span>
              </button>

              <button
                disabled
                className="p-4 rounded-lg border border-gray-200 flex flex-col items-center gap-2 opacity-50 cursor-not-allowed"
              >
                <Video className="w-6 h-6 text-gray-400" />
                <span className="text-sm font-medium">Video (Coming Soon)</span>
              </button>

              <button
                disabled
                className="p-4 rounded-lg border border-gray-200 flex flex-col items-center gap-2 opacity-50 cursor-not-allowed"
              >
                <Mic className="w-6 h-6 text-gray-400" />
                <span className="text-sm font-medium">Voice (Coming Soon)</span>
              </button>
            </div>

            {formData.mediaType === 'Text' && (
              <div className="mt-4">
                <textarea
                  className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:ring-1 focus:ring-[#0D4029] focus:border-[#0D4029]"
                  placeholder="Write your personal message here..."
                  rows={4}
                  value={formData.attachmentContent}
                  onChange={(e) => setFormData({ ...formData, attachmentContent: e.target.value })}
                />
              </div>
            )}
          </div>
        );

      case 6:
        return (
          <div className="space-y-6">
            <div className="mb-6">
              <h2 className="text-lg font-semibold text-gray-900">Review Your Gift Cards</h2>
              <p className="text-sm text-gray-500">Please review the details before sending</p>
            </div>

            <div className="space-y-4">
              <div className="p-4 bg-gray-50 rounded-lg">
                <h3 className="font-medium">Campaign Details</h3>
                <p className="text-sm text-gray-600">Name: {formData.campaignName}</p>
                <p className="text-sm text-gray-600">Title: {formData.cardTitle}</p>
                <p className="text-sm text-gray-600">Amount: ${formData.amount}</p>
                <p className="text-sm text-gray-600">Recipients: {formData.emails.length}</p>
              </div>

              <div className="p-4 bg-gray-50 rounded-lg">
                <h3 className="font-medium">Message Preview</h3>
                <p className="text-sm text-gray-600">{formData.attachmentContent || 'No message added'}</p>
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  const canProgress = () => {
    switch (step) {
      case 1:
        return !!formData.campaignName;
      case 2:
        return !!formData.cardTitle;
      case 3:
        return !!formData.amount && formData.amount > 0;
      case 4:
        return formData.emails.length > 0 || formData.recipients.length > 0;
      case 5:
        return formData.mediaType === 'text' ? !!formData.attachmentContent : true;
      case 6:
        return true;
      default:
        return false;
    }
  };

  const getStepTitle = () => {
    switch (step) {
      case 1:
        return 'Campaign Name';
      case 2:
        return 'Gift Card Title';
      case 3:
        return 'Set Amount';
      case 4:
        return 'Add Recipients';
      case 5:
        return 'Add Message';
      case 6:
        return 'Review';
      default:
        return '';
    }
  };

  if (isSuccess) {
    return (
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent>
          <div className="flex flex-col items-center justify-center h-64 text-center">
            <div className="w-12 h-12 bg-green-100 rounded-full flex items-center justify-center mb-4">
              <Check className="w-6 h-6 text-green-600" />
            </div>
            <h2 className="text-xl font-semibold text-gray-900 mb-2">Gift cards created successfully!</h2>
            <p className="text-gray-500">Your gift cards will be sent to the specified recipients.</p>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <div className="flex items-center justify-between mb-6">
          <h1 className="text-xl font-semibold text-gray-900">{getStepTitle()}</h1>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500 transition-colors"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="w-full h-1 bg-gray-50 rounded-full mb-6">
          <div
            className="h-1 bg-[#0D4029] rounded-full transition-all"
            style={{ width: `${(step / 6) * 100}%` }}
          />
        </div>

        {error && (
          <div className="mb-6 p-4 bg-red-50 rounded-lg flex items-center gap-2 text-red-600">
            <AlertCircle className="w-5 h-5" />
            <span className="text-sm">{error}</span>
          </div>
        )}

        <div className="flex-1 overflow-y-auto">
          {renderStep()}
        </div>

        <div className="mt-8 flex items-center justify-between border-t border-gray-100 pt-4">
          {step > 1 ? (
            <button
              onClick={handleBack}
              className="text-gray-600 hover:text-gray-900 transition-colors"
              disabled={isLoading}
            >
              Back
            </button>
          ) : (
            <div />
          )}
          <button
            onClick={step === 6 ? handleSubmit : handleNext}
            disabled={!canProgress() || isLoading}
            className={`px-6 py-2 rounded-lg transition-colors flex items-center space-x-2 ${
              canProgress() && !isLoading
                ? 'bg-[#0D4029] text-white hover:bg-[#0D4029]/90'
                : 'bg-gray-50 text-gray-400 cursor-not-allowed'
            }`}
          >
            <span>{step === 6 ? 'Send Gift Cards' : 'Continue'}</span>
            {isLoading && <Loader className="w-4 h-4 animate-spin" />}
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CreateGiftCardModal;