import React, { useState, useEffect } from 'react';
import { ArrowRight, Mail, X } from 'lucide-react';

const WelcomeModal = ({ isOpen = true, onClose = () => {} }) => {
  const [showModal, setShowModal] = useState(isOpen);
  
  // Clavaa brand colors
  const primaryColor = '#3EB489';
  const secondaryColor = '#0D4029';
  
  useEffect(() => {
    setShowModal(isOpen);
  }, [isOpen]);
  
  const handleClose = () => {
    setShowModal(false);
    onClose();
  };
  
  if (!showModal) return null;
  
  return (
    <div className="fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center z-50 p-4 overflow-y-auto">
      <div className="bg-white rounded-lg max-w-md w-full shadow-xl overflow-hidden relative animate-fadeIn">
        <button 
          onClick={handleClose}
          className="absolute top-4 right-4 p-1 rounded-full hover:bg-gray-100 transition-colors"
          aria-label="Close"
        >
          <X size={20} className="text-gray-500" />
        </button>
        
        <div className="pt-10 px-8 pb-4">
          <div className="text-center">
            <h2 className="text-xl font-medium mb-2" style={{ color: secondaryColor }}>Welcome to Clavaa</h2>
            <p className="text-sm text-gray-600 mb-8">
              Thank you for joining the Clavaa platform!
            </p>
          </div>
        </div>
        
        <div className="px-8 py-6 border-t border-b border-gray-100 bg-gray-50">
          <h3 className="text-base font-medium mb-3" style={{ color: secondaryColor }}>Complete Your Setup</h3>
          <p className="text-sm text-gray-600 mb-6">
            An account admin will reach out soon to help if you need assistance.
          </p>
          <button 
            onClick={() => window.location.href = '/get-started'}
            className="w-full inline-flex items-center justify-center px-5 py-2.5 rounded-md text-sm font-medium text-white shadow-sm hover:shadow transition-colors"
            style={{ backgroundColor: primaryColor }}
          >
            Go to Dashboard Guide
            <ArrowRight size={16} className="ml-2" />
          </button>
        </div>
        
        <div className="px-8 py-6">
          <div className="flex items-center">
            <div className="w-10 h-10 rounded-full flex items-center justify-center mr-3" 
              style={{ backgroundColor: 'rgba(62, 180, 137, 0.1)', color: primaryColor }}>
              <Mail size={18} />
            </div>
            <div>
              <p className="text-sm text-gray-600">
                Need help? Email us at <a href="mailto:help@clavaa.com" className="font-medium" style={{ color: primaryColor }}>help@clavaa.com</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Add CSS for animation
const style = document.createElement('style');
style.innerHTML = `
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
  }
  .animate-fadeIn {
    animation: fadeIn 0.3s ease-out forwards;
  }
`;
document.head.appendChild(style);

export default WelcomeModal;