import React from "react";
import DashboardLayout from "sideBar/main";

import GettingStartedGuide from "./mainInside";
import DashboardGuide from "./mainInside2";
import { useSelector } from "react-redux";
import { selectActivationStatus } from "features/user/userSelectors";


const GettingStartedPage = ({
 
}) => {
  const activationStatus = useSelector(selectActivationStatus);
  const accountActive = activationStatus === 'activated';

  return (
    <>
    
<DashboardLayout>
{accountActive ? <DashboardGuide /> : <GettingStartedGuide />}

</DashboardLayout>

    </>
  );
};

export default GettingStartedPage;
