import React, { useState, useEffect, useRef } from 'react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';

// Base Dialog Components
function BaseDialog({ children, open, onOpenChange }) {
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape' && open) {
        onOpenChange(false);
      }
    };

    document.addEventListener('keydown', handleEscape);
    return () => document.removeEventListener('keydown', handleEscape);
  }, [open, onOpenChange]);

  if (!open) return null;

  return (
    <div className="fixed inset-0 z-50 bg-black/40 backdrop-blur-sm flex items-center justify-center p-4">
      {children}
    </div>
  );
}

function BaseDialogContent({ children, className = "" }) {
  const contentRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (contentRef.current && !contentRef.current.contains(event.target)) {
        const dialogProps = contentRef.current.closest('[role="dialog"]')
          ?.parentElement?.__reactProps$;
        if (dialogProps?.onOpenChange) {
          dialogProps.onOpenChange(false);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div
      ref={contentRef}
      role="dialog"
      aria-modal="true"
      className={`relative bg-white rounded-xl shadow-2xl transform transition-all duration-200 
                 w-full max-w-md mx-auto ${className}`}
      style={{
        boxShadow: '0 4px 24px rgba(0, 0, 0, 0.12)',
      }}
    >
      {children}
    </div>
  );
}

function ForgotPasswordDialog({ open, onClose }) {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState('');
  const auth = getAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    
    // Basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    setIsLoading(true);

    try {
      await sendPasswordResetEmail(auth, email);
      setIsSuccess(true);
    } catch (error) {
      // Generic error message for all cases
      setError('Something went wrong. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <BaseDialog open={open} onOpenChange={onClose}>
      <BaseDialogContent>
        <div className="relative p-6 sm:p-8">
          {/* Close Button */}
          <button
            onClick={onClose}
            className="absolute right-4 top-4 rounded-full p-2 text-gray-400 
                     hover:text-gray-600 hover:bg-gray-50/80 transition-colors"
          >
            <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>

          {isSuccess ? (
            <div className="flex flex-col items-center text-center py-6">
              <div className="w-16 h-16 rounded-full bg-[#0D4029]/10 flex items-center justify-center mb-6">
                <svg className="w-8 h-8 text-[#0D4029]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                        d="M3 19.5v-15A1.5 1.5 0 014.5 3h15A1.5 1.5 0 0121 4.5v9a1.5 1.5 0 01-1.5 1.5H12l-4.5 4.5V15H4.5A1.5 1.5 0 013 13.5v-9z" />
                </svg>
              </div>
              
              <h2 className="text-xl font-semibold text-gray-900 mb-3"
                  style={{ fontFamily: 'SF Pro Display, -apple-system, BlinkMacSystemFont, sans-serif' }}>
                Check Your Email
              </h2>
              <p className="text-gray-600 mb-6">
                If an account exists for {email}, we've sent password reset instructions.
              </p>
              <button
                onClick={onClose}
                className="w-full bg-[#0D4029] text-white rounded-lg py-3 px-6 font-medium
                         hover:bg-[#0D4029]/90 active:bg-[#0D4029]/95 
                         transform transition-all duration-200 
                         hover:scale-[1.02] active:scale-[0.98]
                         focus:outline-none focus:ring-2 focus:ring-[#0D4029]/20"
              >
                Return to Login
              </button>
            </div>
          ) : (
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="text-center mb-6">
                <h2 className="text-xl font-semibold text-gray-900 mb-2"
                    style={{ fontFamily: 'SF Pro Display, -apple-system, BlinkMacSystemFont, sans-serif' }}>
                  Reset Your Password
                </h2>
                <p className="text-gray-600">
                  Enter your email address and we'll send reset instructions if an account exists.
                </p>
              </div>

              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1.5">
                    Email Address
                  </label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full px-4 py-3 rounded-lg border border-gray-300
                             focus:border-[#0D4029] focus:ring-2 focus:ring-[#0D4029]/20
                             transition-colors duration-200"
                    placeholder="Enter your email"
                    required
                  />
                </div>

                {error && (
                  <div className="flex items-center gap-2 text-red-600 text-sm">
                    <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                    </svg>
                    <span>{error}</span>
                  </div>
                )}

                <button
                  type="submit"
                  disabled={isLoading}
                  className={`w-full bg-[#0D4029] text-white rounded-lg py-3 px-6 font-medium
                           hover:bg-[#0D4029]/90 active:bg-[#0D4029]/95 
                           transform transition-all duration-200 
                           hover:scale-[1.02] active:scale-[0.98]
                           focus:outline-none focus:ring-2 focus:ring-[#0D4029]/20
                           disabled:opacity-50 disabled:cursor-not-allowed
                           flex items-center justify-center gap-2`}
                >
                  {isLoading ? (
                    <>
                      <div className="w-5 h-5 border-2 border-white/30 border-t-white rounded-full animate-spin" />
                      <span>Processing...</span>
                    </>
                  ) : (
                    'Send Instructions'
                  )}
                </button>
              </div>
            </form>
          )}
        </div>
      </BaseDialogContent>
    </BaseDialog>
  );
}

export default ForgotPasswordDialog;