// BoostDashboard.jsx
import React, { useState, useMemo, useCallback } from 'react';
import { Plus, AlertCircle } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import PageHeader from 'pages/locationNew/header';
import BoostCard from './boostCard';
import SearchAndFilter from './SearchAndFilter';
import NoBoostsState from './NoBoostsState';
import NoSearchResultsState from './NoSearchResultsState';
import CreateBoostModal from './create-boostModal';
import BoostExplanationModal from './explainedModal';
import { selectCampaigns } from 'features/boosts/boostsSelector';
import useFullFetchFeedback from 'functions/apiFetchers/fullPageLoaders';
import { removeCampaign } from 'features/boosts/boostSlice';
import DeleteBoostModal from './deleteModal';


const BoostDashboard = () => {
  // State management
  const [expandedBoosts, setExpandedBoosts] = useState(new Set());
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    status: 'all',
    targetGroup: 'all',
  });
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isExplainedModalOpen, setIsExplainedModalOpen] = useState(false);
  
  // Delete modal state
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [boostToDelete, setBoostToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteError, setDeleteError] = useState(null);

  const dispatch = useDispatch();
  const fetchFeedback = useFullFetchFeedback();
  const boosts = useSelector(selectCampaigns);

  // Memoized handlers
  const toggleExpand = useCallback((id) => {
    setExpandedBoosts((prev) => {
      const newExpanded = new Set(prev);
      if (newExpanded.has(id)) {
        newExpanded.delete(id);
      } else {
        newExpanded.add(id);
      }
      return newExpanded;
    });
  }, []);

  const handleDeleteClick = useCallback((boost) => {
    setBoostToDelete(boost);
    setIsDeleteModalOpen(true);
    setDeleteError(null);
    setDeleteSuccess(false);
  }, []);

  const handleDeleteClose = useCallback(() => {
    setIsDeleteModalOpen(false);
    setBoostToDelete(null);
    setDeleteError(null);
    setDeleteSuccess(false);
    setIsDeleting(false);
  }, []);

  const handleDeleteConfirm = useCallback(async () => {
    if (!boostToDelete) return;
    
    setIsDeleting(true);
    try {
      await fetchFeedback({
        endpoint: 'merchant/boosts/delete',
        requestType: 'POST',
        data: { id: boostToDelete.id },
        onSuccess: () => {
          dispatch(removeCampaign(boostToDelete.id));
          setDeleteSuccess(true);
          setDeleteError(null);
          // Close modal after success message
          setTimeout(handleDeleteClose, 1500);
        },
        onError: (error) => {
          setDeleteError(error);
        },
      });
    } catch (error) {
      setDeleteError(error);
    } finally {
      setIsDeleting(false);
    }
  }, [boostToDelete, dispatch, fetchFeedback, handleDeleteClose]);

  // Debounced search handler
  const debouncedSearchChange = useCallback(
    _.debounce((value) => {
      setSearchTerm(value.toLowerCase());
    }, 300),
    []
  );

  const handleFilterChange = useCallback((key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  }, []);

  // Memoized filtered boosts
  const filteredBoosts = useMemo(() => {
    if (!boosts) return [];
    
    return boosts.filter((boost) => {
      const statusMatch = filters.status === 'all' ? true :
        filters.status === 'active-scheduled' ?
        (boost.status === 'active' || boost.status === 'scheduled') :
        boost.status === filters.status;

      const targetGroupMatch = filters.targetGroup === 'all' ? true :
        boost.targetGroup === filters.targetGroup;

      const searchMatch = !searchTerm ? true :
        boost.name.toLowerCase().includes(searchTerm);

      return statusMatch && targetGroupMatch && searchMatch;
    });
  }, [boosts, filters.status, filters.targetGroup, searchTerm]);

  // UI handlers
  const addBoostClick = useCallback(() => {
    setIsCreateModalOpen(true);
  }, []);

  const closeCreateModal = useCallback(() => {
    setIsCreateModalOpen(false);
  }, []);

  const closeExplainedModal = useCallback(() => {
    setIsExplainedModalOpen(false);
  }, []);

  return (
    <>
      <div className="min-h-screen flex flex-col">
        <PageHeader
          title="Boosts"
          description="Create targeted cashback offers to attract and retain customers"
          action={
            <button
              onClick={addBoostClick}
              className="inline-flex items-center px-4 py-2 bg-[#0D4029] text-white rounded-lg hover:bg-[#0D4029]/90 transition-colors"
            >
              Create Boost
              <Plus className="w-4 h-4 ml-2" />
            </button>
          }
        />

        <div className="px-6 max-w-[1600px] mx-auto w-full flex-1">
          <SearchAndFilter
            onSearchChange={debouncedSearchChange}
            filters={[
              {
                key: 'status',
                value: filters.status,
                options: [
                  { label: 'All statuses', value: 'all' },
                  { label: 'Active', value: 'active' },
                  { label: 'Scheduled', value: 'scheduled' },
                  { label: 'Expired', value: 'ended' },
                ],
              },
              {
                key: 'targetGroup',
                value: filters.targetGroup,
                options : [
                  { label: 'All customer segments', value: 'all' },
                  { label: 'Never shopped', value: 'never-shopped' },
                  { label: 'Inactive for 30+ days', value: 'inactive-30' },
                  { label: 'Inactive for 60+ days', value: 'inactive-60' },
                  { label: 'Inactive for 90+ days', value: 'inactive-90' },
                  { label: 'Regular customers', value: 'regular' },
                  { label: 'Frequent customers', value: 'frequent' },
                  { label: 'VIP customers', value: 'vip' },
                  { label: 'Gold members', value: 'gold' },
                  { label: 'High spenders', value: 'high-spenders' },
                  { label: 'Frequent visitors', value: 'frequent-visitors' }
                ],
              },
            ]}
            onFilterChange={handleFilterChange}
          />

          <div className="grid grid-cols-2 xl:grid-cols-3 gap-6 mb-8">
            {filteredBoosts.map((boost) => (
              <BoostCard
                key={boost.id}
                boost={boost}
                isExpanded={expandedBoosts.has(boost.id)}
                onToggle={toggleExpand}
                onDelete={() => handleDeleteClick(boost)}
              />
            ))}
          </div>

          {!boosts?.length ? (
            <NoBoostsState addBoostClick={addBoostClick} />
          ) : !filteredBoosts.length ? (
            <NoSearchResultsState
              setFilters={setFilters}
              setSearchTerm={setSearchTerm}
              addBoostClick={addBoostClick}
            />
          ) : null}

          <div className="mt-auto border-t border-gray-200 pt-6 pb-8">
            <button 
              className="text-sm text-gray-600 hover:text-gray-900 transition-colors flex items-center gap-2"
              onClick={() => setIsExplainedModalOpen(true)}
            >
              <AlertCircle className="w-4 h-4" />
              Understand how boosts work
            </button>
          </div>
        </div>
      </div>

      {isCreateModalOpen && (
        <CreateBoostModal
          isOpen={isCreateModalOpen}
          onClose={closeCreateModal}
        />
      )}

      <DeleteBoostModal
        isOpen={isDeleteModalOpen}
        onClose={handleDeleteClose}
        onConfirm={handleDeleteConfirm}
        isLoading={isDeleting}
        isSuccess={deleteSuccess}
        isError={!!deleteError}
        boostName={boostToDelete?.name}
        error={deleteError}
      />

      {isExplainedModalOpen && (
        <BoostExplanationModal
          isOpen={isExplainedModalOpen}
          onClose={closeExplainedModal}
        />
      )}
    </>
  );
};

export default BoostDashboard;