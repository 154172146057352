import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentUser: "Yechiel Gartenhaus",
  status: "inactive", // Default status; adjust as needed
  activationStatus: "notActivated", // Corrected this field name to camelCase for consistency
  subscriptionStatus: null,
  subscriptionType: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {

    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
   setActivationStatus: (state, action) => {
    state.activationStatus = action.payload === true ? "activated" : "notActivated";
},
    setSubscriptionStatus: (state, action) => {
      state.subscriptionStatus = action.payload;
    },
    setSubsciptionType: (state, action) => {
      state.subscriptionType = action.payload;
    },

    // Additional user-related reducers can be added here as needed
  },
});

export const { setCurrentUser, setStatus, setActivationStatus, setSubscriptionStatus, setSubsciptionType } = userSlice.actions;
export default userSlice.reducer;
