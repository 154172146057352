import React, { useState } from 'react';
import { PlayCircle, PauseCircle, Gift, Mic, MessageSquare } from 'lucide-react';

// Updated gradient combinations for a more subtle, professional look
const GRADIENT_THEMES = [
  ['from-blue-500/90 to-blue-600/90', 'blue'],
  ['from-purple-500/90 to-purple-600/90', 'purple'],
  ['from-emerald-500/90 to-emerald-600/90', 'emerald'],
  ['from-rose-500/90 to-rose-600/90', 'rose'],
  ['from-indigo-500/90 to-indigo-600/90', 'indigo'],
  ['from-cyan-500/90 to-cyan-600/90', 'cyan'],
];

const MediaIcon = ({ type, isPlaying }) => {
  const iconClass = "w-8 h-8 text-white/90";
  
  switch (type?.toLowerCase()) {
    case 'video':
      return isPlaying ? 
        <PauseCircle className={iconClass} /> : 
        <PlayCircle className={iconClass} />;
    case 'voice':
      return <Mic className={iconClass} />;
    case 'text':
      return <MessageSquare className={iconClass} />;
    default:
      return null;
  }
};

const GiftCard = ({ 
  title,
  senderName,
  businessName,
  amount,
  date,
  mediaType = 'none',
  colorIndex = -1,
  onMediaClick = () => {},
  className = '',
}) => {
  const [isPlaying, setIsPlaying] = useState(false);

  // Get gradient theme
  const gradientIndex = colorIndex === -1 
    ? Math.floor(Math.random() * GRADIENT_THEMES.length) 
    : (colorIndex % GRADIENT_THEMES.length);
  const gradientClass = GRADIENT_THEMES[gradientIndex][0];

  const handleMediaClick = () => {
    setIsPlaying(!isPlaying);
    onMediaClick();
  };

  return (
    <div className={`w-full p-3 ${className}`}>
      <div 
        className={`
          relative aspect-[2/1] bg-gradient-to-br ${gradientClass} 
          rounded-lg shadow-sm overflow-hidden
          hover:shadow-md transition-all duration-300
        `}
      >
        {/* Subtle Pattern */}
        <div className="absolute inset-0 opacity-5">
          <div className="absolute inset-0 grid grid-cols-8 gap-4">
            {[...Array(32)].map((_, i) => (
              <Gift key={i} className="text-white transform rotate-45" />
            ))}
          </div>
        </div>

        {/* Main Content */}
        <div className="relative h-full flex">
          {/* Left Section */}
          <div className="flex-1 p-4 sm:p-5 flex flex-col justify-between text-white">
            <div>
              <h2 className="text-2xl sm:text-3xl font-medium mb-1 tracking-tight">
                {title}
              </h2>
              <div className="text-lg sm:text-xl font-medium opacity-90">
                {businessName}
              </div>
              <div className="text-base mt-1 opacity-80">
                From {senderName}
              </div>
            </div>

            <div className="flex items-center justify-between text-xs opacity-75">
              <time>{date}</time>
              <span>Added to cashback</span>
            </div>
          </div>

          {/* Right Section */}
          <div className="w-32 sm:w-40 border-l border-white/10 flex flex-col items-center justify-between p-4">
            <div className="text-2xl sm:text-3xl font-medium text-white/95">
              ${amount}
            </div>

            {mediaType !== 'none' && (
              <button 
                onClick={handleMediaClick}
                className="mt-4 hover:bg-white/10 rounded-lg p-2 transition-colors
                         focus:outline-none focus:ring-2 focus:ring-white/30"
                aria-label={`Play ${mediaType} message`}
              >
                <MediaIcon type={mediaType} isPlaying={isPlaying} />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GiftCard;