import React from 'react';
import { Check, ChevronRight, ShieldCheck, Timer, BadgeCheck, ExternalLink, ArrowRight, CreditCard } from 'lucide-react';

import { useNavigate } from 'react-router-dom';
import useFullFetchFeedback from 'functions/apiFetchers/fullPageLoaders';

const SetupStepsModal = ({
  open,
  onClose,
  onComplete,
  accountActive,
  // can be validButPaymentFailed, active, or inactive
  SubscriptionStatus,
  stepStatuses,
   // Can be pro_yealy, pro_monthly, basic_monthly, basic_yearly
  subscriptionType,
  
}) => {
  const navigate = useNavigate();
  const fetchFeedback = useFullFetchFeedback();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const steps = [
    {
      title: 'Business Details Verified (KYC)',
      description: 'Ensure your business information is verified according to regulations.',
      status: stepStatuses.kyc,
    },
    {
      title: 'Setup Location',
      description: 'Add your business location for customers to discover you in the app.',
      status: stepStatuses.locationVerified,
    },
  ];
  const onNavigateToSubscription = () => {
    // Navigate to subscription page
    navigate('/dashboard/cart/choose/subscription');
  };
   // Handle account activation
    const onManageSubscription = async () => {
      setLoading(true);
      setError(null);
     
  
      await fetchFeedback({
        endpoint: `merchant/stripe/subscription/manage/link/`, // Activation endpoint
        requestType: 'GET', // Assuming GET request, adjust as necessary
        onSuccess: (responseData) => {
          setLoading(false);

          // navigate to the responseData.url from stripe
          window
            .open(responseData.url, '_blank')
            .focus();
        
        },
        onError: (error) => {
          setLoading(false);
          setError(error);
         
        },
      });
    };
  
 

  const allStepsCompleted = steps.every((step) => step.status);

  // Get subscription display info
  const getSubscriptionInfo = () => {
    switch(subscriptionType) {
      case 'pro_yearly':
        return { 
          name: 'Growth', 
          price: '$299.99/year',
          features: ['0% payment processing fees', 'Advanced analytics', 'Priority support']
        };
      case 'pro_monthly':
        return { 
          name: 'Growth', 
          price: '$34.99/month',
          features: ['0% payment processing fees', 'Advanced analytics', 'Priority support']
        };
      case 'basic_yearly':
        return { 
          name: 'Starter', 
          price: '$179.00/year',
          features: ['0.49% payment processing fees', 'Basic analytics', 'Standard support']
        };
      case 'basic_monthly':
      default:
        return { 
          name: 'Starter', 
          price: '$19.99/month',
          features: ['0.49% payment processing fees', 'Basic analytics', 'Standard support']
        };
    }
  };

  if (!open) return null;

  // Render content based on account state
  const renderContent = () => {
    // Active Account State
    if (accountActive) {
      const subscriptionInfo = getSubscriptionInfo();
      
      return (
        <div className="flex flex-col px-6 py-8">
          <div className="flex items-center mb-6">
            <div className="w-14 h-14 rounded-full bg-emerald-50 flex items-center justify-center">
              <BadgeCheck className="w-7 h-7 text-emerald-600" />
            </div>
            <div className="ml-5">
              <h2 className="text-xl font-semibold text-gray-900">
                Your account is active
              </h2>
              <p className="text-sm text-gray-600">
                Your account is ready to process transactions and grow your business.
              </p>
            </div>
          </div>
          
          <div className="w-full mb-6 p-5 bg-gray-50 border border-gray-200 rounded-lg">
            <div className="flex items-center mb-4">
              <div className="w-10 h-10 rounded-full flex items-center justify-center" style={{ backgroundColor: 'rgba(62, 180, 137, 0.1)' }}>
                <CreditCard className="w-5 h-5" style={{ color: '#3EB489' }} />
              </div>
              <div className="ml-4">
                <h3 className="text-base font-medium text-gray-900">Current Subscription: {subscriptionInfo.name}</h3>
                <p className="text-sm text-gray-600">{subscriptionInfo.price} - Renews automatically</p>
              </div>
            </div>
            
            <ul className="space-y-2 mb-4">
              {subscriptionInfo.features.map((feature, index) => (
                <li key={index} className="flex items-start">
                  <Check className="w-4 h-4 mt-0.5 mr-2" style={{ color: '#3EB489' }} />
                  <span className="text-sm text-gray-700">{feature}</span>
                </li>
              ))}
            </ul>
          </div>
          
          <button
            onClick={onManageSubscription}
            className="flex items-center justify-center w-full sm:w-auto px-5 py-3 text-white font-medium rounded-lg focus:ring-4 focus:ring-green-100 disabled:opacity-50 transition-colors"
            style={{ backgroundColor: '#0D4029', borderColor: '#0D4029' }}
          >
            Manage Your Subscription
            <ArrowRight className="ml-2 w-4 h-4" />
          </button>
          
          <p className="text-xs text-gray-500 mt-4">
            You can upgrade, downgrade, or update your payment information from the subscription management page.
          </p>
        </div>
      );
    }

    // Ready for Activation State
    if (allStepsCompleted) {
      return (
        <div className="flex flex-col px-6 py-8">
          <div className="w-16 h-16 mb-6 rounded-full bg-green-50 flex items-center justify-center" style={{ backgroundColor: 'rgba(13, 64, 41, 0.1)' }}>
            <Timer className="w-8 h-8" style={{ color: '#0D4029' }} />
          </div>
          <h2 className="text-xl font-semibold text-gray-900 mb-2">
            Your account is ready to be activated
          </h2>
          <p className="text-base text-gray-600 mb-6 max-w-lg">
            To complete activation, you must select a subscription package for your account.
          </p>
          
          <div className="w-full mb-6 p-5 bg-gray-50 border border-gray-200 rounded-lg">
            <div className="flex items-center mb-4">
              <div className="w-10 h-10 rounded-full flex items-center justify-center" style={{ backgroundColor: 'rgba(62, 180, 137, 0.1)' }}>
                <Check className="w-5 h-5" style={{ color: '#3EB489' }} />
              </div>
              <div className="ml-4">
                <h3 className="text-base font-medium text-gray-900">Subscription Required</h3>
                <p className="text-sm text-gray-600">Choose between our Starter and Growth plans</p>
              </div>
            </div>
            
            <ul className="space-y-2 mb-4">
              <li className="flex items-start">
                <Check className="w-4 h-4 mt-0.5 mr-2" style={{ color: '#3EB489' }} />
                <span className="text-sm text-gray-700">Starter: $19.99/month or $179.00/year (0.49% swipe fees)</span>
              </li>
              <li className="flex items-start">
                <Check className="w-4 h-4 mt-0.5 mr-2" style={{ color: '#3EB489' }} />
                <span className="text-sm text-gray-700">Growth: $34.99/month or $299.99/year (0% payment fees)</span>
              </li>
              <li className="flex items-start">
                <Check className="w-4 h-4 mt-0.5 mr-2" style={{ color: '#3EB489' }} />
                <span className="text-sm text-gray-700">All plans include customer support and analytics</span>
              </li>
            </ul>
          </div>
          
          <button
            onClick={onNavigateToSubscription}
            className="flex items-center justify-center w-full sm:w-auto px-5 py-3 text-white font-medium rounded-lg focus:ring-4 focus:ring-green-100 disabled:opacity-50 transition-colors"
            style={{ backgroundColor: '#0D4029', borderColor: '#0D4029' }}
          >
            Choose Subscription Package
            <ArrowRight className="ml-2 w-4 h-4" />
          </button>
          
          <p className="text-xs text-gray-500 mt-4">
            You will be redirected to the subscription page to select your package.
          </p>
        </div>
      );
    }

    // Setup Steps State
    return (
      <div className="max-w-2xl mx-auto px-6 py-8">
        <div className="flex items-center gap-4 mb-8">
          <div className="w-12 h-12 rounded-full flex items-center justify-center" style={{ backgroundColor: 'rgba(13, 64, 41, 0.1)' }}>
            <ShieldCheck className="w-6 h-6" style={{ color: '#0D4029' }} />
          </div>
          <div>
            <h2 className="text-xl font-semibold text-gray-900">
              Complete your setup
            </h2>
            <p className="text-sm text-gray-600 mt-1">
              Complete these steps to activate your account
            </p>
          </div>
        </div>

        <div className="space-y-6">
          {steps.map((step, index) => (
            <div 
              key={index} 
              className="flex items-start gap-4 p-4 rounded-lg hover:bg-gray-50 transition-colors"
            >
              <div className={`w-6 h-6 rounded-full flex items-center justify-center flex-shrink-0 mt-1
                ${step.status ? 'bg-green-50' : 'bg-gray-50'}`}
                style={{ backgroundColor: step.status ? 'rgba(62, 180, 137, 0.1)' : undefined }}
              >
                {step.status ? (
                  <Check className="w-4 h-4" style={{ color: '#3EB489' }} />
                ) : (
                  <div className="w-1.5 h-1.5 rounded-full bg-gray-400" />
                )}
              </div>
              <div className="flex-1 min-w-0">
                <div className="flex items-center justify-between gap-4">
                  <h3 className="text-base font-medium text-gray-900">
                    {step.title}
                  </h3>
                  {!step.status && (
                    <button
                      onClick={() => onComplete(index)}
                      className="flex items-center gap-1.5 px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors"
                    >
                      Complete
                      <ChevronRight className="w-4 h-4" />
                    </button>
                  )}
                </div>
                <p className="mt-1 text-sm text-gray-600">{step.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="fixed inset-0 bg-gray-900/20 backdrop-blur-sm z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-xl shadow-xl w-full max-w-2xl overflow-hidden">
        {renderContent()}
        
        {/* Only show footer with close button when not in the "Ready for Activation" state */}
        {!(allStepsCompleted && !accountActive) && (
          <div className="px-6 py-4 border-t border-gray-100 bg-gray-50 flex justify-end">
            <button
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-900 transition-colors"
            >
              Close
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SetupStepsModal;