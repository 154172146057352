import React, { useState } from 'react';
import { X, CircleDollarSign, Store, ArrowRight, ArrowLeft, CreditCard, Zap, Users, Target, Clock } from 'lucide-react';

const StepCard = ({ icon: Icon, title, description, isActive }) => (
  <div 
    className={`text-center transform transition-all duration-500 ease-out ${
      isActive ? 'scale-105' : 'scale-100'
    }`}
  >
    <div 
      className={`w-24 h-24 rounded-2xl mx-auto flex items-center justify-center mb-3 
        transition-all duration-300 ease-out transform
        ${isActive ? 'bg-[#0D4029]/10 shadow-lg translate-y-[-4px]' : 'bg-[#0D4029]/5'}
      `}
    >
      <Icon className={`w-12 h-12 transition-all duration-300 ${
        isActive ? 'text-[#0D4029] scale-110' : 'text-[#0D4029]/70'
      }`} />
    </div>
    <div className="text-sm font-medium text-gray-900 transition-all duration-300">
      {title}
    </div>
    <div className="text-xs text-gray-500 mt-1 transition-all duration-300">
      {description}
    </div>
  </div>
);

const ComparisonCard = ({ children, label, sublabel, tag }) => (
  <div className="text-center transform transition-all duration-500 hover:scale-105">
    <div className="relative inline-block group">
      <div className={`w-28 h-28 rounded-2xl flex items-center justify-center mb-4
        transition-all duration-300 ease-out transform
        ${tag === 'Boost' ? 'bg-[#0D4029]/10 group-hover:shadow-lg' : 'bg-gray-50'}
      `}>
        {children}
      </div>
      <div className={`absolute -top-2 right-0 px-3 py-1 rounded-full text-sm
        transition-all duration-300 transform translate-y-0 group-hover:translate-y-[-2px]
        ${tag === 'Boost' ? 'bg-[#0D4029] text-white' : 'bg-gray-200 text-gray-600'}
      `}>
        {tag}
      </div>
    </div>
    <div className="text-sm font-medium text-gray-900">{label}</div>
    <div className="text-xs text-gray-500 mt-1">{sublabel}</div>
  </div>
);

const ProcessorCard = ({ type, icon: Icon, label, fee, isNegative }) => (
  <div className="text-center transform transition-all duration-500 hover:scale-105">
    <div className="relative">
      <div className={`w-28 h-28 rounded-2xl flex items-center justify-center mb-4 
        transition-all duration-300
        ${isNegative ? 'bg-red-50' : 'bg-[#0D4029]/10'}
      `}>
        <div className="relative">
          <Icon className={`w-14 h-14 transition-all duration-300 
            ${isNegative ? 'text-red-300' : 'text-[#0D4029]'}`} 
          />
          {isNegative && (
            <X className="w-16 h-16 text-red-400 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
          )}
        </div>
      </div>
      <div className="text-sm font-medium text-gray-900">{label}</div>
      <div className={`text-xs mt-1 font-medium
        ${isNegative ? 'text-red-500' : 'text-[#0D4029]'}`}
      >
        {fee}
      </div>
    </div>
  </div>
);

const BoostExplanationModal = ({ isOpen, onClose }) => {
  const [step, setStep] = useState(1);
  const [activeCard, setActiveCard] = useState(1);
  
  const boostExamples = [
    {
      icon: Users,
      title: "First Orders",
      description: "$20 back when new customers spend $50+"
    },
    {
      icon: Clock,
      title: "Happy Hour",
      description: "$15 back on orders 2-5pm"
    },
    {
      icon: Target,
      title: "Big Orders",
      description: "$25 back on orders over $100"
    }
  ];
  
  const steps = [
    {
      title: "Introducing Boosts",
      subtitle: "High-value rewards that drive customer actions",
      illustration: (
        <div className="py-8">
          <div className="grid grid-cols-3 gap-8 max-w-2xl mx-auto">
            {boostExamples.map((example, index) => (
              <StepCard 
                key={index}
                icon={example.icon}
                title={example.title}
                description={example.description}
                isActive={activeCard === index}
                onMouseEnter={() => setActiveCard(index)}
                onMouseLeave={() => setActiveCard(-1)}
              />
            ))}
          </div>
        </div>
      ),
      description: "Give $10-25 back to customers who complete specific actions. More effective than 3-5% cashback for driving first orders, slow hour visits, and large purchases.",
      hint: "Boosts are the best marketing tools around"
    },
    {
      title: "Regular Loyalty vs Boosts",
      subtitle: "Bigger rewards for specific actions",
      illustration: (
        <div className="flex items-center justify-center gap-12 py-12">
          <ComparisonCard 
            label="Regular Loyalty" 
            sublabel="Always active" 
            tag="4%"
          >
            <CircleDollarSign className="w-14 h-14 text-gray-400" />
          </ComparisonCard>
          
          <ComparisonCard 
            label="Boost Reward" 
            sublabel="On $50+ orders" 
            tag="Boost"
          >
            <div className="text-[#0D4029] text-xl font-semibold">$20</div>
          </ComparisonCard>
        </div>
      ),
      description: "Boosts deliver much higher value than traditional rewards, encouraging customers to make their first purchase, order during slow hours, or place larger orders."
    },
    {
      title: "Powered by Clavaa Pay",
      subtitle: "Zero processing fees enable higher rewards",
      illustration: (
        <div className="flex items-center justify-center gap-12 py-12">
          <ProcessorCard
            type="regular"
            icon={CreditCard}
            label="Other Processors"
            fee="3% Fee Per Order"
            isNegative={true}
          />

          <Zap className="w-8 h-8 text-[#0D4029] transform -rotate-12 animate-pulse" />

          <ProcessorCard
            type="clavaa"
            icon={CreditCard}
            label="Clavaa Pay"
            fee="0% Processing Fee"
            isNegative={false}
          />
        </div>
      ),
      description: "By processing payments through Clavaa, you eliminate processing fees. These savings help fund the higher boost rewards for your customers."
    }
  ];

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div 
        className="absolute inset-0 bg-black bg-opacity-50 transition-opacity duration-300 backdrop-blur-sm"
        onClick={onClose}
      />
      <div className="relative bg-white rounded-2xl shadow-2xl w-[700px] max-w-[95vw] max-h-[90vh] p-10 overflow-y-auto
        transform transition-all duration-500 
        animate-in slide-in-from-bottom-4 fade-in
      ">
        <div className="flex items-center justify-between mb-8">
          <h1 className="text-2xl font-semibold text-gray-900">Understanding Boosts</h1>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500 transition-colors p-2 hover:bg-gray-50 rounded-lg"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        <div className="space-y-8">
          <div className="relative">
            <div className="absolute top-0 left-0 w-full h-1 bg-gray-50 rounded-full overflow-hidden">
              <div 
                className="h-1 bg-[#0D4029] rounded-full transition-all duration-500 ease-out"
                style={{ width: `${(step / steps.length) * 100}%` }}
              />
            </div>
          </div>

          <div className="pt-8">
            <div className="text-center mb-8 transform transition-all duration-500">
              <h2 className="text-xl font-medium text-gray-900 mb-1">{steps[step - 1].title}</h2>
              <p className="text-sm text-gray-500">{steps[step - 1].subtitle}</p>
            </div>

            <div className="transform transition-all duration-500 ease-out">
              {steps[step - 1].illustration}
            </div>

            <div className="max-w-lg mx-auto text-center">
              <p className="text-gray-600 text-lg leading-relaxed">
                {steps[step - 1].description}
              </p>
              {steps[step - 1].hint && (
                <p className="text-sm text-gray-400 mt-4 italic">
                  {steps[step - 1].hint}
                </p>
              )}
            </div>
          </div>

          <div className="flex items-center justify-between pt-6 border-t border-gray-100">
            <button
              onClick={() => setStep(step - 1)}
              disabled={step === 1}
              className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-all duration-300 
                ${step === 1 
                  ? 'text-gray-300 cursor-not-allowed' 
                  : 'text-gray-600 hover:text-gray-900 hover:bg-gray-50'
                }`}
            >
              <ArrowLeft className="w-4 h-4" />
              Back
            </button>
            {step < steps.length ? (
              <button
                onClick={() => setStep(step + 1)}
                className="flex items-center gap-2 px-6 py-2 bg-[#0D4029] text-white rounded-lg 
                  hover:bg-[#0D4029]/90 transition-all duration-300 transform hover:translate-x-1"
              >
                Next
                <ArrowRight className="w-4 h-4" />
              </button>
            ) : (
              <button
                onClick={onClose}
                className="px-6 py-2 bg-[#0D4029] text-white rounded-lg 
                  hover:bg-[#0D4029]/90 transition-all duration-300"
              >
                Got it
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoostExplanationModal;