import React from 'react';
import { Users } from 'lucide-react';
import GiftCard from './GiftCard';

const GiftCardCampaign = ({ campaign }) => {
  return (
    <div className="bg-white rounded-lg overflow-hidden shadow-[0_0_1px_rgba(0,0,0,0.1),0_2px_4px_rgba(0,0,0,0.05)] hover:shadow-[0_0_1px_rgba(0,0,0,0.1),0_4px_8px_rgba(0,0,0,0.1)] transition-shadow duration-200">
      {/* Card itself */}
      <div className="scale-[0.7] origin-center mx-auto -my-5 ">
        <GiftCard
          title={campaign.title}
          senderName={campaign.senderName}
          businessName={campaign.businessName}
          amount={campaign.amount}
          date={campaign.date}
          colorIndex={campaign.colorIndex}
          mediaType={campaign.mediaType}

        />
      </div>

      {/* Campaign info with refined Stripe styling */}
      <div className="px-4 py-2.5 bg-gray-50/80 border-t border-gray-100">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <span className="font-medium text-[13px] text-gray-700">
              {campaign.campaignName}
            </span>
            <div className="h-3 w-[1px] bg-gray-200"></div>
            <div className="flex items-center text-gray-500">
              <Users className="w-3 h-3 mr-0.5" />
              <span className="text-[11px]">{campaign.recipientCount}</span>
            </div>
          </div>
          <div className="text-[13px] font-medium text-gray-600">
            ${campaign.amount}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GiftCardCampaign;