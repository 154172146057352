import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import useFullFetchFeedback from 'functions/apiFetchers/fullPageLoaders';
import { ChevronLeft, Check, AlertCircle, RefreshCw, ArrowRight } from 'lucide-react';

import { useDispatch } from 'react-redux';
import { selectsubscriptionType } from 'features/user/userSelectors';
import { setSubsciptionType, setSubscriptionStatus } from 'features/user/userSlice';

const SubscriptionStatusUpdatePage = () => {
  // Main state
  const [status, setStatus] = useState('processing'); // 'processing', 'success', 'failed'
  const [subscriptionInfo, setSubscriptionInfo] = useState(null);
  const [error, setError] = useState(null);
  const [progressWidth, setProgressWidth] = useState(25);
  const dispatch = useDispatch();
  
  // Track if API request has been made
  const requestInProgress = useRef(false);
  
  // Services
  const navigate = useNavigate();
  const fetchFeedback = useFullFetchFeedback();
  
  // Animate progress bar when in processing state
  useEffect(() => {
    if (status === 'processing') {
      // Initial progress
      setProgressWidth(40);
      
      const timer = setTimeout(() => {
        setProgressWidth(65);
      }, 800);
      
      return () => clearTimeout(timer);
    }
  }, [status]);
  
  // Make API request to check and update subscription status
  const checkSubscriptionStatus = useCallback(async () => {
    // Prevent concurrent requests
    if (requestInProgress.current) return;
    requestInProgress.current = true;
    
    try {
      // Show progress advancing
      setProgressWidth(80);
      
      await fetchFeedback({
        endpoint: 'merchant/stripe/subscription/status/update/',
        requestType: 'GET',
        onSuccess: (responseData) => {
          setProgressWidth(100);
          
          // Short delay to show completed progress
          setTimeout(() => {
            if (responseData.changed || responseData.new) {
              setStatus('success');
              setSubscriptionInfo({
                old: responseData.old || {},
                new: responseData.new || {},
                changed: responseData.changed || false,
                notes: responseData.notes || []
              });
              
              // Update Redux store with subscription information
              if (responseData.new && responseData.new.subscription_status) {
                dispatch(setSubscriptionStatus(responseData.new.subscription_status));
              }
              if (responseData.new && responseData.new.subscription_type) {
                dispatch(setSubsciptionType(responseData.new.subscription_type));
              }
            } else {
              setStatus('failed');
              setError(responseData.message || "Failed to update subscription status");
            }
            requestInProgress.current = false;
          }, 600);
        },
        onError: (error) => {
          console.error(error);
          setStatus('failed');
          setError("Failed to update subscription status. Please contact support.");
          requestInProgress.current = false;
        },
      });
    } catch (err) {
      console.error(err);
      setStatus('failed');
      setError("An unexpected error occurred while updating subscription status");
      requestInProgress.current = false;
    }
  }, [fetchFeedback, dispatch]);
  
  // Trigger the API request once when component mounts or when manually reset
  useEffect(() => {
    // Small delay to allow component to fully mount
    const timer = setTimeout(() => {
      if (status === 'processing') {
        checkSubscriptionStatus();
      }
    }, 300);
    
    return () => clearTimeout(timer);
  }, [status, checkSubscriptionStatus]);
  
  // Handle navigation back to dashboard
  const handleBackToDashboard = () => {
    navigate('/dashboard');
  };
  
  // Handle retry - reset status to allow one more request
  const handleRetry = () => {
    setStatus('processing');
    setProgressWidth(25);
    setError(null);
  };
  
  // Processing state component
  const ProcessingState = () => (
    <div className="text-center">
      {/* Progress bar */}
      <div className="relative w-full max-w-sm mx-auto mb-6">
        <div className="w-full bg-gray-100 rounded-full h-2">
          <div 
            className="bg-gradient-to-r from-[#0D4029] to-[#3EB489] h-2 rounded-full transition-all duration-700 ease-out"
            style={{ width: `${progressWidth}%` }}
          ></div>
        </div>
        <div className="flex justify-between mt-1.5 text-xs text-gray-400 font-medium px-1">
          <span>Connecting</span>
          <span>Verifying</span>
          <span>Updating</span>
        </div>
      </div>
      
      {/* Loading animation */}
      <div className="flex justify-center mb-5">
        <div className="w-14 h-14 flex items-center justify-center rounded-full bg-gradient-to-br from-[#0D4029]/5 to-[#3EB489]/10 border border-[#3EB489]/20">
          <div className="loading-pulse flex space-x-1.5">
            <span className="w-2.5 h-2.5 bg-gradient-to-r from-[#0D4029] to-[#3EB489] rounded-full inline-block opacity-75" 
                  style={{animation: 'pulse 1.4s infinite ease-in-out'}}></span>
            <span className="w-2.5 h-2.5 bg-gradient-to-r from-[#0D4029] to-[#3EB489] rounded-full inline-block opacity-75"
                  style={{animation: 'pulse 1.4s infinite ease-in-out 0.2s'}}></span>
            <span className="w-2.5 h-2.5 bg-gradient-to-r from-[#0D4029] to-[#3EB489] rounded-full inline-block opacity-75"
                  style={{animation: 'pulse 1.4s infinite ease-in-out 0.4s'}}></span>
          </div>
        </div>
      </div>
      
      <h3 className="text-xl font-semibold text-gray-900 mb-1.5">Refreshing subscription</h3>
      <p className="text-gray-500 text-sm mb-5 max-w-xs mx-auto">
        We're verifying your Clavaa subscription status
      </p>
      
      <div className="mt-2 py-2 px-3 bg-[#0D4029]/5 rounded text-xs text-gray-600 inline-block">
        Just a moment while we sync with Stripe
      </div>
      
      <style jsx>{`
        @keyframes pulse {
          0%, 100% {
            transform: scale(0.6);
            opacity: 0.3;
          }
          50% {
            transform: scale(1);
            opacity: 1;
          }
        }
      `}</style>
    </div>
  );
  
  // Success state component
  const SuccessState = () => {
    const formatSubscriptionType = (type) => {
      switch(type) {
        case 'basic_yearly': return 'Basic Yearly';
        case 'basic_monthly': return 'Basic Monthly';
        case 'pro_yearly': return 'Pro Yearly';
        case 'pro_monthly': return 'Pro Monthly';
        default: return type || 'Unknown';
      }
    };
    
    const getStatusColor = (status) => {
      return status === 'active' ? 'bg-green-500' : 'bg-yellow-500';
    };
    
    const showChanges = subscriptionInfo?.changed && 
                        subscriptionInfo?.notes && 
                        subscriptionInfo.notes.length > 0;
    
    return (
      <div className="text-center">
        <div className="flex justify-center mb-5">
          <div className="w-16 h-16 flex items-center justify-center rounded-full bg-gradient-to-r from-[#0D4029] to-[#3EB489] text-white shadow-sm">
            <Check className="w-8 h-8" />
          </div>
        </div>
        
        <h3 className="text-xl font-semibold text-gray-900 mb-1.5">
          {showChanges ? 'Subscription updated' : 'Subscription verified'}
        </h3>
        <p className="text-gray-500 text-sm mb-5">
          {showChanges 
            ? 'Your subscription information has been refreshed and updated' 
            : 'Your subscription information is up to date'}
        </p>
        
        {/* Status indicator */}
        <div className="bg-[#3EB489]/5 p-2.5 rounded-md mb-5 inline-flex items-center">
          {showChanges ? (
            <>
              <div className="w-4 h-4 rounded-full bg-[#3EB489] flex items-center justify-center mr-2">
                <Check className="w-3 h-3 text-white" />
              </div>
              <span className="text-xs text-[#0D4029] font-medium">
                Changes detected and applied
              </span>
            </>
          ) : (
            <>
              <Check className="w-4 h-4 text-[#3EB489] mr-1.5" />
              <span className="text-xs text-[#0D4029] font-medium">
                No changes needed or detected
              </span>
            </>
          )}
        </div>
        
        {subscriptionInfo && (
          <div className="bg-gray-50 rounded-lg p-4 mb-6 border border-gray-100">
            <div className="mb-4">
              <div className="text-xs text-gray-500 font-medium mb-1">Subscription Type</div>
              <div className="text-sm text-gray-700 font-medium flex justify-center items-center h-8 bg-white rounded-md shadow-sm border border-gray-50">
                {formatSubscriptionType(subscriptionInfo.new.subscription_type)}
              </div>
            </div>
            
            <div className="mb-3">
              <div className="text-xs text-gray-500 font-medium mb-1">Status</div>
              <div className="flex items-center justify-center">
                <div className="flex items-center">
                  <span className={`inline-block w-2.5 h-2.5 rounded-full mr-1.5 ${
                    getStatusColor(subscriptionInfo.new.subscription_status)
                  }`}></span>
                  <span className="text-sm text-gray-700 font-medium capitalize">
                    {subscriptionInfo.new.subscription_status || 'Unknown'}
                  </span>
                </div>
              </div>
            </div>
            
            {showChanges && (
              <div className="mt-3 pt-3 border-t border-gray-100">
                <div className="text-xs text-gray-500 font-medium mb-1">Changes Made</div>
                <div className="bg-[#3EB489]/5 rounded-md p-2.5 mt-2">
                  <ul className="text-xs">
                    {subscriptionInfo.notes.map((note, index) => (
                      <li key={index} className="flex items-start py-1.5">
                        <Check className="w-3.5 h-3.5 text-[#3EB489] mr-2 mt-0.5 flex-shrink-0" />
                        <span className="text-gray-700">{note}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        )}
        
        <button
          onClick={handleBackToDashboard}
          className="w-full bg-[#0D4029] text-white py-3 rounded-md text-sm font-medium hover:bg-[#0D4029]/90 transition-colors flex items-center justify-center shadow-sm"
        >
          <span>Continue to Dashboard</span>
          <ArrowRight className="w-4 h-4 ml-1.5" />
        </button>
      </div>
    );
  };
  
  // Failed state component
  const FailedState = () => (
    <div className="text-center">
      <div className="flex justify-center mb-5">
        <div className="w-16 h-16 flex items-center justify-center rounded-full bg-red-50 border border-red-100">
          <AlertCircle className="w-8 h-8 text-red-500" />
        </div>
      </div>
      
      <h3 className="text-xl font-semibold text-gray-900 mb-1.5">Verification failed</h3>
      <p className="text-gray-500 text-sm mb-5">
        We couldn't verify your subscription status
      </p>
      
      <div className="bg-red-50 border border-red-100 rounded-md p-3.5 mb-5 text-left">
        <div className="text-xs text-red-700">
          <p className="font-medium mb-1">Error details</p>
          <p>{error || "The subscription verification process couldn't be completed."}</p>
          <p className="text-xs text-gray-500 mt-2">
            Reference: {Date.now().toString(36).slice(-6).toUpperCase()}
          </p>
        </div>
      </div>
      
      <div className="space-y-2.5 mb-5">
        <button
          onClick={handleRetry}
          className="w-full bg-[#0D4029] text-white py-3 rounded-md text-sm font-medium hover:bg-[#0D4029]/90 transition-colors shadow-sm flex items-center justify-center"
        >
          <RefreshCw className="w-4 h-4 mr-1.5" />
          Try Again
        </button>
        <button
          onClick={handleBackToDashboard}
          className="w-full bg-white border border-gray-200 text-gray-700 py-3 rounded-md text-sm hover:bg-gray-50 transition-colors"
        >
          Return to Dashboard
        </button>
      </div>
      
      <div className="mt-4 py-2 px-3 bg-[#0D4029]/5 rounded text-xs text-gray-600 inline-flex items-center">
        <svg className="w-3.5 h-3.5 mr-1.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <circle cx="12" cy="12" r="10"></circle>
          <path d="M12 8v4"></path>
          <path d="M12 16h.01"></path>
        </svg>
        Need help? <a href="mailto:support@clavaa.com" className="text-[#3EB489] ml-1 font-medium">Contact support</a>
      </div>
    </div>
  );
  
  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-br from-white via-[#f9fafb] to-[#f3f4f6]">
      {/* Header with subtle gradient */}
      <header className="border-b border-gray-100 bg-white" style={{boxShadow: "0 1px 3px rgba(0,0,0,0.02)"}}>
        <div className="max-w-5xl mx-auto px-4 sm:px-6 w-full">
          <div className="h-16 flex items-center justify-between">
            <button 
              onClick={handleBackToDashboard}
              className="text-sm text-gray-500 hover:text-[#0D4029] transition-colors flex items-center gap-1.5"
            >
              <ChevronLeft className="w-4 h-4" />
              <span>Dashboard</span>
            </button>
            <div className="flex items-center">
              <div className="text-[#0D4029] font-semibold text-base">Clavaa</div>
              <div className="text-xs text-gray-400 ml-2 tracking-wider">LOYALTY PLATFORM</div>
            </div>
            <div className="w-20"></div> {/* Spacer for balance */}
          </div>
        </div>
      </header>

      {/* Main content with subtle gradient background */}
      <main className="flex-grow flex items-start justify-center pt-12" style={{background: "linear-gradient(135deg, rgba(13,64,41,0.01) 0%, rgba(62,180,137,0.03) 100%)"}}>
        <div className="w-full max-w-lg px-4 sm:px-6">
          <div className="text-center mb-8">
            <h2 className="text-xl font-semibold text-gray-900">Subscription Status Verification</h2>
            <p className="text-sm text-gray-500">Syncing with Stripe</p>
          </div>
          
          <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden transition-all hover:shadow" style={{borderTop: "3px solid #3EB489"}}>
            <div className="px-6 py-7">
              {status === 'processing' && <ProcessingState />}
              {status === 'success' && <SuccessState />}
              {status === 'failed' && <FailedState />}
            </div>
          </div>
          
          {/* Help text below card */}
          {status === 'processing' && (
            <div className="mt-4 text-center">
              <p className="text-xs text-gray-500">
                This usually takes less than a minute
              </p>
            </div>
          )}
        </div>
      </main>
      
      {/* Footer with subtle accent color */}
      <footer className="border-t border-gray-100 mt-auto py-6 bg-[#0D4029]/3">
        <div className="max-w-5xl mx-auto px-4 sm:px-6">
          <div className="flex flex-col md:flex-row justify-between items-center gap-3">
            <div className="text-xs text-gray-500">
              © {new Date().getFullYear()} Clavaa. All rights reserved.
            </div>
            <div className="flex items-center gap-4">
              <a href="mailto:help@clavaa.com" className="text-xs text-gray-500 hover:text-[#0D4029] transition-colors">Help</a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default SubscriptionStatusUpdatePage;