// CombinedFunctionality.js
import React, { useState, useRef } from 'react';
import { getAuth, createUserWithEmailAndPassword, setPersistence, browserLocalPersistence, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import useFullFetchFeedback from '../../../../functions/apiFetchers/fullPageLoaders';
import { setStatus } from "../../../../features/user/userSlice";
import { useDispatch } from "react-redux";
import { setHasFetched } from '../../../../features/initialData/initialDataSlice';
import { showDialog } from "../../../../features/dialog/dialogSlice";
import { updateRegisterData } from '../../../../features/register/registerSlice';
import { useNavigate } from 'react-router-dom';
import { set } from 'lodash';
import { showAlert } from '../../../../features/alerts/alertSlice';
function CombinedFunctionality({ setError }) {

  const codeLength = 5;
  const [codeValues, setCodeValues] = useState(Array(codeLength).fill(''));
  const inputRefs = useRef([]);
  const [isLoadingCode, setIsLoadingCode] = useState(false);
  const [isLoadingAccount, setIsLoadingAccount] = useState(false);
  const [isResendingCode, setIsResendingCode] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const dispatch = useDispatch();
  const [email , setEmail] = useState('');
  const [password, setPassword] = useState('');



  const [isCodeDialogOpen, setIsCodeDialogOpen] = useState(false);
  const [profilePk, setProfilePk] = useState(null);
  const navigate = useNavigate();
  const fetchFeedback = useFullFetchFeedback();
  const auth = getAuth();

  // Handle email and password submission
  const handleEmailSubmit = async (email, password) => {
    setEmail(email);
    setPassword(password);
   

    await fetchFeedback({
      endpoint: 'merchant/email/',
      requestType: 'POST',
      auth: false,
      data: { email},
      setError: setError,
      loaderMessage: 'Verifying Email...',
      onSuccess: (responseData) => {
        console.log(responseData)
        setProfilePk(responseData.profile_pk);
        setIsCodeDialogOpen(true); // Open code verification dialog after email success
      },
      onError: (err) => {
        console.log(err)
        setError(err);
      },
    });
  };

  const handleCloseCodeDialog = () => {
    setIsCodeDialogOpen(false);
  };

  // Handle input change and move to the next field
  const handleChange = (index, value) => {
    if (!/^\d?$/.test(value)) return; // Ensure only numbers

    const newCodeValues = [...codeValues];
    newCodeValues[index] = value;
    setCodeValues(newCodeValues);

    if (value && index < codeLength - 1) {
      inputRefs.current[index + 1]?.focus();
    }

    if (newCodeValues.every((char) => char !== '')) {
      confirmCode(newCodeValues.join(''));
    }
  };

  // Handle backspace key for navigating between fields
  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace' && !codeValues[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const provider = new GoogleAuthProvider();

  const handleGoogleSignUp = async (isChecked) => {
    if (!isChecked) {
      dispatch(showAlert({ type: "warning", message: "You must first agree to the terms and privacy policy above" }));
      return;
    }
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
  
      console.log("Google Sign-Up successful:", user);
  
      // Activate loader only after successful sign-up
    
  
      // Assuming fetchFeedback is an API call utility function
      await fetchFeedback({
        endpoint: "merchant/account_info/stripe/",
        requestType: "POST",
        loaderMessage: "Creating your account...",


        data: {
          email: user.email, // Use the email from the signed-in user
          uid: user.uid,     // Firebase UID
    
        },
        onSuccess: (data) => {
          // Handle success
          dispatch(updateRegisterData(data.register_data));
          setIsLoadingAccount(false);
          setIsSuccess(true);
          setIsCodeDialogOpen(true);
        },
        onError: (err) => {
          console.error(err);
          setIsLoadingAccount(false);
        },
      });
  
    } catch (error) {
    
      console.error("Google Sign-Up error:", error);
  
      setError({
        email: "",
        password: "Google Sign-Up failed",
      });
    }
  };

  // Reset code input fields
  const resetCode = () => {
    setCodeValues(Array(codeLength).fill(''));
    inputRefs.current.forEach((ref) => {
      if (ref) {
        ref.value = '';
      }
    });
    inputRefs.current[0]?.focus();
  };
  
  const confirmCode = async (code) => {
    setErrorMessage('');
    setIsLoadingCode(true);

    try {
      // Submit the code to `email/confirm/` endpoint
      await fetchFeedback({
        endpoint: "email/confirm/",
        requestType: "POST",
        auth: false,
        data: { profile_pk: profilePk, otp: code },
        onSuccess: async () => {
          setIsLoadingAccount(true);
          setIsLoadingCode(false);
          try {
            const auth = getAuth();
            await setPersistence(auth, browserLocalPersistence);
            const userCredential = await createUserWithEmailAndPassword(
              auth,
              email,
              password
            );
            const firebaseUser = userCredential.user;
            // Link Firebase user with backend user
            await fetchFeedback({
              endpoint: "merchant/account_info/stripe/",
              requestType: "POST",
              data: {
                email: firebaseUser.email,
                uid: firebaseUser.uid,
                profile_pk: profilePk,
              },
              onSuccess: (data) => {
                // Show success component
                dispatch(updateRegisterData(data.register_data));

                setIsLoadingAccount(false);
                setIsSuccess(true);
                setIsCodeDialogOpen(true);
              },
              onError: (err) => {
                console.log(err);
                setIsLoadingAccount(false);
              },
            });
          } catch (firebaseError) {
            console.log(firebaseError);
            setIsLoadingAccount(false);
          }
        },
        onError: (err) => {
          setErrorMessage(err.otp || 'An error occurred. Please try again.');
          resetCode();
          setIsLoadingCode(false);
          setIsLoadingAccount(false);
        },
      });
    } catch (err) {
      console.log(err);
      setErrorMessage(err.otp || 'An error occurred. Please try again.');
      resetCode();
      setIsLoadingCode(false);
      setIsLoadingAccount(false);
    }
  };

  // Resend verification code
  const resendCode = async (email) => {
    setIsResendingCode(true);
    setErrorMessage('');

    await fetchFeedback({
      endpoint: 'merchant/email/',
      requestType: 'POST',
      data: { email },
      onSuccess: (responseData) => {
        setIsResendingCode(false);
        setProfilePk(responseData.profile_pk);
      },
      onError: (err) => {
        setIsResendingCode(false);
      },
    });
  };

  const handleGoToDashboard = () => {
    dispatch(setHasFetched(true));
  
    navigate("/dashboard");
    dispatch(showDialog({
      dialogType: 'guide'
    }));
  };

  return {
    isLoadingCode,
    isLoadingAccount,
    isResendingCode,
    isSuccess,
    errorMessage,
    codeValues,
    inputRefs,
    handleChange,
    handleKeyDown,
    resendCode,
    confirmCode,
    resetCode,
    handleGoToDashboard,
    handleCloseCodeDialog,
    handleCloseCodeDialog,
    handleEmailSubmit,
    isCodeDialogOpen,
    handleGoogleSignUp
    


  };
}

export default CombinedFunctionality;
