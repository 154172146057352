import GiftPage from "pages/gifts/main";
import React from "react";
import { useForm } from "react-hook-form";

const ClavaaSignUp = () => {

  return (
<GiftPage/>
  );
};

export default ClavaaSignUp;