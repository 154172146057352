import React, { useState, useEffect } from 'react';
import { X, DollarSign, Calendar, Check, Loader } from 'lucide-react';
import { audienceOptions, boostTypes } from './boostTypes';
import BoostDuration from './boostDuration';
import useFullFetchFeedback from 'functions/apiFetchers/fullPageLoaders';
import { duration } from '@mui/material';
import { addCampaign } from 'features/boosts/boostSlice';
import { useDispatch } from 'react-redux';

const Dialog = ({ open, onOpenChange, children }) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [open]);

  if (!open) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div 
        className="absolute inset-0 bg-black bg-opacity-50 transition-opacity"
        onClick={() => onOpenChange(false)}
      />
      <div className="relative">
        {children}
      </div>
    </div>
  );
};

const DialogContent = ({ children }) => {
    return (
      <div className="bg-white rounded-xl shadow-2xl w-[500px] max-w-[95vw] max-h-[90vh] p-6 relative overflow-y-auto">
        {children}
      </div>
    );
  };

  const CreateBoostModal = ({ isOpen, onClose }) => {
    const [step, setStep] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [formData, setFormData] = useState({
      boostType: '',
      targetAudience: '',
      minSpend: '',
      cashbackAmount: '',
      startDate: '',
      endDate: '',
      name: '',
      startNow: true,
      duration: "24h",
    });
    const dispatch = useDispatch();
    const fetchFeedback = useFullFetchFeedback();
    const getCurrentAudiences = () => {
        // Find the currently selected boost type
        const selectedType = boostTypes.find(type => type.id === formData.boostType);
        
        if (!selectedType) return [];
    
        // Map through the audiences array of the selected boost type
        // For each audience ID, combine it with the full audience data from audienceOptions
        return selectedType.audiences.map(audienceId => ({
          id: audienceId,
          title: audienceOptions[audienceId].title,
          description: audienceOptions[audienceId].description
        }));
      };
  
    const handleNext = () => setStep(step + 1);
    const handleBack = () => setStep(step - 1);
  
    const handleSubmit = async () => {
      setIsLoading(true);


       await fetchFeedback({
            endpoint: 'merchant/boosts/create',
            requestType: 'POST',
            data: {
              ...formData,
              
              
            },
            onSuccess: (responseData) => {
           
              dispatch(addCampaign(responseData));

              setStep(1);
              setFormData({
                boostType: '',
                targetAudience: '',
                minSpend: '',
                cashbackAmount: '',
                startDate: '',
                endDate: '',
                name: ''
              });
              setIsSuccess(true);
             
            
            },
            onError: (error) => {
             
             
            },
          });

    };
  
    const renderStep = () => {
      switch (step) {
        case 1:
          return (
            <div className="space-y-6">
              <div className="mb-6">
                <h2 className="text-lg font-semibold text-gray-900">Choose boost type</h2>
                <p className="text-sm text-gray-500">Select the type of boost you want to create for your customers</p>
              </div>
              <div className="grid grid-cols-1 gap-4">
                {boostTypes.map((type) => (
                  <button
                    key={type.id}
                    className={`p-4 border rounded-lg text-left transition-all ${
                      formData.boostType === type.id
                        ? 'border-[#0D4029] bg-[#0D4029]/5'
                        : 'border-gray-200 hover:border-[#0D4029] hover:bg-[#0D4029]/5'
                    }`}
                    onClick={() => {
                      setFormData({ 
                        ...formData, 
                        boostType: type.id,
                        targetAudience: ''
                      });
                    }}
                  >
                    <div className="flex items-start gap-4">
                      <div className={`p-2 rounded-lg ${
                        formData.boostType === type.id
                          ? 'bg-[#0D4029] text-white'
                          : 'bg-gray-50 text-gray-500'
                      }`}>
                        <type.icon className="w-5 h-5" />
                      </div>
                      <div>
                        <h3 className="font-medium text-gray-900">{type.title}</h3>
                        <p className="text-sm text-gray-500 mt-1">{type.description}</p>
                      </div>
                    </div>
                  </button>
                ))}
              </div>
            </div>
          );
  
        case 2:
          return (
            <div className="space-y-6">
              <div className="mb-6">
                <h2 className="text-lg font-semibold text-gray-900">Select target audience</h2>
                <p className="text-sm text-gray-500">Choose which customer segment will receive this boost</p>
              </div>
              <div className="grid grid-cols-1 gap-4">
                {getCurrentAudiences().map((audience) => (
                  <button
                    key={audience.id}
                    className={`p-4 border rounded-lg text-left transition-all ${
                      formData.targetAudience === audience.id
                        ? 'border-[#0D4029] bg-[#0D4029]/5'
                        : 'border-gray-200 hover:border-[#0D4029] hover:bg-[#0D4029]/5'
                    }`}
                    onClick={() => setFormData({ ...formData, targetAudience: audience.id })}
                  >
                    <h3 className="font-medium text-gray-900">{audience.title}</h3>
                    <p className="text-sm text-gray-500 mt-1">{audience.description}</p>
                  </button>
                ))}
              </div>
            </div>
          );
  
        case 3:
          return (
            <div className="space-y-6">
            <div className="mb-6">
                <h2 className="text-lg font-semibold text-gray-900">Set boost details</h2>
                <p className="text-sm text-gray-500">Define the spending requirements and reward amount</p>
              </div>

              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Minimum spend amount
                  </label>
                  <div className="relative">
                    <DollarSign className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                    <input
                      type="number"
                      className="pl-10 pr-4 py-2 w-full border border-gray-200 rounded-lg focus:ring-1 focus:ring-[#0D4029] focus:border-[#0D4029]"
                      placeholder="0.00"
                      value={formData.minSpend}
                      onChange={(e) => setFormData({ ...formData, minSpend: e.target.value })}
                    />
                  </div>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Cashback amount
                  </label>
                  <div className="relative">
                    <DollarSign className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                    <input
                      type="number"
                      className="pl-10 pr-4 py-2 w-full border border-gray-200 rounded-lg focus:ring-1 focus:ring-[#0D4029] focus:border-[#0D4029]"
                      placeholder="0.00"
                      value={formData.cashbackAmount}
                      onChange={(e) => setFormData({ ...formData, cashbackAmount: e.target.value })}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
  
          case 4:
            return <BoostDuration formData={formData} setFormData={setFormData} />;
  
        case 5:
          return (
            <div className="space-y-6">
             <div className="mb-6">
                <h2 className="text-lg font-semibold text-gray-900">Name your boost</h2>
                <p className="text-sm text-gray-500">Give your boost a clear, descriptive name</p>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Boost name
                </label>
                <input
                  type="text"
                  className="px-4 py-2 w-full border border-gray-200 rounded-lg focus:ring-1 focus:ring-[#0D4029] focus:border-[#0D4029]"
                  placeholder="e.g., Spring Welcome Offer"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                />
              </div>
            </div>
          );
  
        default:
          return null;
      }
    };
  
    const canProgress = () => {
      switch (step) {
        case 1:
          return !!formData.boostType;
        case 2:
          return !!formData.targetAudience;
        case 3:
          return !!formData.minSpend && !!formData.cashbackAmount;
        case 4:
          return !!formData.startDate && !!formData.endDate;
        case 5:
          return !!formData.name;
        default:
          return false;
      }
    };
  
    const getStepTitle = () => {
      switch (step) {
        case 1:
          return 'Create new boost';
        case 2:
          return 'Target audience';
        case 3:
          return 'Boost details';
        case 4:
          return 'Set duration';
        case 5:
          return 'Name your boost';
        default:
          return '';
      }
    };
  
    if (isSuccess) {
      return (
        <Dialog open={isOpen} onOpenChange={onClose}>
          <DialogContent>
            <div className="flex flex-col items-center justify-center h-64 text-center">
              <div className="w-12 h-12 bg-green-100 rounded-full flex items-center justify-center mb-4">
                <Check className="w-6 h-6 text-green-600" />
              </div>
              <h2 className="text-xl font-semibold text-gray-900 mb-2">Boost created successfully!</h2>
              <p className="text-gray-500">Your boost has been created and is now active.</p>
            </div>
          </DialogContent>
        </Dialog>
      );
    }
  
    return (
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent>
          <div className="flex items-center justify-between mb-6">
            <h1 className="text-xl font-semibold text-gray-900">{getStepTitle()}</h1>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500 transition-colors"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
  
          <div className="w-full h-1 bg-gray-50 rounded-full mb-6">
            <div
              className="h-1 bg-[#0D4029] rounded-full transition-all"
              style={{ width: `${(step / 5) * 100}%` }}
            />
          </div>
  
          <div className="flex-1 overflow-y-auto">
            {renderStep()}
          </div>
  
          <div className="mt-8 flex items-center justify-between border-t border-gray-100 pt-4">
            {step > 1 ? (
              <button
                onClick={handleBack}
                className="text-gray-600 hover:text-gray-900 transition-colors"
                disabled={isLoading}
              >
                Back
              </button>
            ) : (
              <div /> 
            )}
            <button
              onClick={step === 5 ? handleSubmit : handleNext}
              disabled={!canProgress() || isLoading}
              className={`px-6 py-2 rounded-lg transition-colors flex items-center space-x-2 ${
                canProgress() && !isLoading
                  ? 'bg-[#0D4029] text-white hover:bg-[#0D4029]/90'
                  : 'bg-gray-50 text-gray-400 cursor-not-allowed'
              }`}
            >
              <span>{step === 5 ? 'Create boost' : 'Continue'}</span>
              {isLoading && <Loader className="w-4 h-4 animate-spin" />}
            </button>
          </div>
        </DialogContent>
      </Dialog>
    );
  };
  
  export default CreateBoostModal;