import React, { useCallback, useState, useEffect } from 'react';
import { Check, ShoppingBag, ChevronLeft, Shield } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllItems } from 'features/cart/cartSelector';
import useFullFetchFeedback from 'functions/apiFetchers/fullPageLoaders';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';

// Load Stripe outside of component to avoid recreating on each render
const stripePromise = loadStripe("pk_live_51QFSOAP1SGiwmmH3YlolMRKcLk2ddVxZZFwvW8wa4yFyk8ebTHk5PvjtVLV0IGVdigGEOJbPXpxavpP6bqrAXWgb00DPC8CDx8");

const CheckoutPage = () => {
  const [step, setStep] = useState('checkout');
  const [cartItems, setCartItems] = useState([]);
  const [confirmationCode, setConfirmationCode] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const allItems = useSelector(selectAllItems);
  const dispatch = useDispatch();
  const fetchFeedback = useFullFetchFeedback();
  const navigate = useNavigate();
  
  // Load cart items - fixed to find items by ID
  useEffect(() => {
    const loadCart = async () => {
      setIsLoading(true);
      try {
        const savedCart = JSON.parse(localStorage.getItem('clavaaCart') || '{}');
        
        // Use find() to look up items by their actual id property
        const items = Object.entries(savedCart)
          .map(([id, quantity]) => {
            const item = allItems.find(item => item.id.toString() === id);
            return item ? { ...item, quantity } : null;
          })
          .filter(Boolean);
        
        setCartItems(items);
      } catch (err) {
        setError("Failed to load cart items");
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };
    loadCart();
  }, [allItems]);

  // Create fetchClientSecret function for Stripe
  const fetchClientSecret = useCallback(() => {
    const product_ids_and_quantity = cartItems.reduce((acc, item) => {
      acc[item.id] = item.quantity;
      return acc;
    }, {});

    return new Promise((resolve, reject) => {
      fetchFeedback({
        endpoint: "merchant/stripe/create/checkout/",
        requestType: "POST",
        data: { product_ids_and_quantity },
        onSuccess: (data) => {
          if (data?.account_secret) {
            resolve(data.account_secret);
          } else {
            reject(new Error("No account_secret found in response"));
          }
        },
        onError: (error) => {
          setError("Failed to initialize checkout. Please try again.");
          reject(error || new Error("API call failed"));
        }
      });
    });
  }, [cartItems, fetchFeedback]);

  // Handle checkout completion
  const handleComplete = useCallback(() => {
    localStorage.setItem('clavaaCart', '{}');
    setCartItems([]);
    setStep('confirmed');
    setConfirmationCode('CLAVAA-' + Date.now().toString().slice(-6));
  }, []);

  // Handle back to dashboard
  const handleBackToDashboard = () => {
    navigate(-1);
  };

  // Empty cart component
  const EmptyCart = () => (
    <div className="min-h-[60vh] flex flex-col items-center justify-center">
      <ShoppingBag className="w-16 h-16 text-gray-300 mb-4" />
      <h3 className="text-xl font-semibold text-gray-800 mb-2">Your cart is empty</h3>
      <p className="text-gray-500 mb-6 text-center max-w-md">
        Looks like you haven't added any POS hardware or marketing materials to your cart yet.
      </p>
      <button
        onClick={handleBackToDashboard}
        className="bg-[#0D4029] text-white px-6 py-3 rounded-xl font-medium hover:bg-[#0D4029]/90 transition-all transform active:scale-[0.98]"
      >
        Browse Products
      </button>
    </div>
  );

  // Confirmation component
  const ConfirmedStep = () => (
    <div className="max-w-md mx-auto py-16 px-4">
      <div className="w-20 h-20 bg-[#0D4029]/10 rounded-full flex items-center justify-center mx-auto mb-8 animate-appear">
        <Check className="w-10 h-10 text-[#0D4029]" />
      </div>
      
      <div className="text-center space-y-3 mb-8">
        <h3 className="text-2xl font-semibold text-gray-900">Order Confirmed!</h3>
        <p className="text-gray-600">
          Your order #{confirmationCode} has been placed successfully
        </p>
        <p className="text-sm text-gray-500">
          A confirmation email has been sent with your order details
        </p>
      </div>

      <button
        onClick={handleBackToDashboard}
        className="w-full bg-[#0D4029] text-white py-4 rounded-xl hover:bg-[#0D4029]/90 transition-all transform active:scale-[0.98] font-medium"
      >
        Back to Dashboard
      </button>
    </div>
  );

  // Loading state
  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-50 py-10">
        <div className="max-w-7xl mx-auto px-6">
          <div className="animate-pulse space-y-8">
            <div className="flex items-center justify-between">
              <div className="h-6 w-40 bg-gray-200 rounded"></div>
              <div className="h-8 w-32 bg-gray-200 rounded"></div>
              <div className="h-6 w-40 bg-gray-200 rounded"></div>
            </div>
            <div className="h-[650px] bg-gray-200 rounded-xl"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen py-10" style={{ backgroundColor: '#f9fafb' }}>
      <div className="max-w-7xl mx-auto px-6">
        {/* Header with back button and title */}
        <div className="flex items-center justify-between mb-8 border-b border-gray-100 pb-5">
          <div className="flex items-center">
            <button 
              onClick={handleBackToDashboard}
              className="flex items-center gap-2 text-gray-600 hover:text-[#0D4029] transition-colors group"
            >
              <ChevronLeft className="w-5 h-5 group-hover:transform group-hover:-translate-x-1 transition-transform" />
              <span className="font-medium">Back to Dashboard</span>
            </button>
          </div>
          <h1 className="text-2xl font-semibold text-gray-900">
            Complete Your Purchase
          </h1>
          <div className="w-32"></div> {/* Empty div for balance */}
        </div>

        {/* Main content */}
        {error && (
          <div className="bg-red-50 text-red-600 p-4 rounded-xl text-sm mb-8 max-w-3xl mx-auto">
            <p>{error}</p>
          </div>
        )}

        {step === 'checkout' ? (
          cartItems.length === 0 ? (
            <EmptyCart />
          ) : (
            <>
              <div className="bg-white rounded-xl shadow-sm overflow-hidden mb-10 w-full">              
                <div id="checkout" className="min-h-[650px] w-full">
                  <EmbeddedCheckoutProvider
                    stripe={stripePromise}
                    options={{ fetchClientSecret }}
                  >
                    <EmbeddedCheckout onComplete={handleComplete} />
                  </EmbeddedCheckoutProvider>
                </div>
              </div>
              
              {/* Enhanced footer */}
              <div className="flex flex-col md:flex-row items-center justify-center gap-6 text-sm text-gray-500 mt-8 mb-12 max-w-3xl mx-auto">
                <div className="flex items-center gap-2">
                  <Shield className="w-4 h-4 text-[#0D4029]" />
                  <span>Secure checkout powered by Stripe</span>
                </div>
                <div className="h-4 w-px bg-gray-300 hidden md:block"></div>
                <span>© {new Date().getFullYear()} Clavaa Loyalty App</span>
                <div className="h-4 w-px bg-gray-300 hidden md:block"></div>
                <span>Need help? <a href="/support" className="text-[#0D4029] hover:underline">Contact support</a></span>
              </div>
            </>
          )
        ) : (
          <ConfirmedStep />
        )}
      </div>
    </div>
  );
};

export default CheckoutPage;