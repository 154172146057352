import React from 'react';
import { X, AlertTriangle, Check, XCircle, Loader } from 'lucide-react';

const Dialog = ({ open, onOpenChange, children }) => {
  if (!open) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div 
        className="absolute inset-0 bg-black bg-opacity-50 transition-opacity"
        onClick={() => onOpenChange(false)}
      />
      <div className="relative">
        {children}
      </div>
    </div>
  );
};

const DialogContent = ({ children }) => {
  return (
    <div className="bg-white rounded-xl shadow-2xl w-[400px] max-w-[95vw] p-6 relative">
      {children}
    </div>
  );
};

const DeleteBoostModal = ({ 
  isOpen, 
  onClose, 
  onConfirm, 
  boostName = "",
  isLoading = false,
  isSuccess = false,
  isError = false
}) => {
  // Render success state
  if (isSuccess) {
    return (
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent>
          <div className="flex flex-col items-center justify-center py-6 text-center">
            <div className="w-12 h-12 bg-green-100 rounded-full flex items-center justify-center mb-4">
              <Check className="w-6 h-6 text-green-600" />
            </div>
            <h2 className="text-xl font-semibold text-gray-900 mb-2">Boost deleted successfully</h2>
            <p className="text-gray-500">The boost has been permanently removed</p>
            <button
              onClick={onClose}
              className="mt-6 px-6 py-2 bg-gray-50 text-gray-600 rounded-lg hover:bg-gray-200 transition-colors"
            >
              Close
            </button>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  // Render error state
  if (isError) {
    return (
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent>
          <div className="flex flex-col items-center justify-center py-6 text-center">
            <div className="w-12 h-12 bg-red-100 rounded-full flex items-center justify-center mb-4">
              <XCircle className="w-6 h-6 text-red-600" />
            </div>
            <h2 className="text-xl font-semibold text-gray-900 mb-2">Unable to delete boost</h2>
            <p className="text-gray-500">An error occurred. Please try again later.</p>
            <button
              onClick={onClose}
              className="mt-6 px-6 py-2 bg-gray-50 text-gray-600 rounded-lg hover:bg-gray-200 transition-colors"
            >
              Close
            </button>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  // Render confirmation/loading state
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <div className="flex items-center justify-between mb-6">
          <h1 className="text-xl font-semibold text-gray-900">Delete Boost</h1>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500 transition-colors"
            disabled={isLoading}
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="flex flex-col items-center justify-center py-4">
          <div className="w-12 h-12 bg-red-100 rounded-full flex items-center justify-center mb-4">
            <AlertTriangle className="w-6 h-6 text-red-600" />
          </div>
          
          <h2 className="text-lg font-semibold text-gray-900 mb-2 text-center">
            Are you sure you want to delete {boostName}?
          </h2>
          
          <p className="text-gray-500 text-center mb-6">
            This action cannot be undone. You'll need to create a new boost if you want to run a similar campaign.
          </p>

          <div className="flex gap-3 w-full">
            <button
              onClick={onClose}
              className="flex-1 px-6 py-2 bg-gray-50 text-gray-600 rounded-lg hover:bg-gray-200 transition-colors"
              disabled={isLoading}
            >
              Cancel
            </button>
            <button
              onClick={onConfirm}
              disabled={isLoading}
              className="flex-1 px-6 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors flex items-center justify-center gap-2"
            >
              {isLoading ? (
                <>
                  <Loader className="w-4 h-4 animate-spin" />
                  <span>Deleting...</span>
                </>
              ) : (
                <span>Delete Boost</span>
              )}
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteBoostModal;