import { createSlice } from '@reduxjs/toolkit';

const initialState = [
  {
    id: 1,
    name: "New Customer Welcome",
    targetGroup: "Never shopped",
    minSpend: 50,
    cashbackAmount: 10,
    duration: "30 days",
    status: "active",
    redemptions: 124,
    totalSpent: 6200,
    startDate: "Mar 1, 2024",
    endDate: "Mar 31, 2024"
  },
  {
    id: 2,
    name: "Win-back Campaign",
    targetGroup: "Inactive 30+ days",
    minSpend: 75,
    cashbackAmount: 15,
    duration: "14 days",
    status: "scheduled",
    redemptions: 20,
    totalSpent: 500,
    startDate: "Apr 1, 2024",
    endDate: "Apr 14, 2024"
  }
];

const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    addCampaign: (state, action) => {
      state.push(action.payload);
    },
    updateCampaign: (state, action) => {
      const { id, updatedData } = action.payload;
      const index = state.findIndex((campaign) => campaign.id === id);
      if (index !== -1) {
        state[index] = { ...state[index], ...updatedData };
      }
    },
    removeCampaign: (state, action) => {
      const id = action.payload;
      return state.filter((campaign) => campaign.id !== id);
    },
    setCampaigns: (state, action) => {
        return action.payload;
  },
  }
});

export const { addCampaign, updateCampaign, removeCampaign,setCampaigns } = campaignsSlice.actions;

export default campaignsSlice.reducer;